// colors

.bg-gradient-theme {
  background: linear-gradient(to bottom, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-top {
  background: linear-gradient(to top, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-left {
  background: linear-gradient(to left, theme-color("primary"), theme-color("secondary"));
}
.bg-gradient-theme-right {
  background: linear-gradient(to right, theme-color("primary"), theme-color("secondary"));
}

/**
 * Framework7 1.6.0
 * Full featured mobile HTML framework for building iOS & Android apps
 * 
 * Google Material Theme
 *
 * http://framework7.io/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: April 10, 2017
 */
/*==========================
Framework7 Layouts Themes
==========================*/
/* === Dark layout === */
.layout-dark .page,
.layout-dark .login-screen-content,
.page.layout-dark,
.layout-dark .panel,
.panel.layout-dark {
  background-color: #222;
  color: rgba(255, 255, 255, 0.87);
}
.layout-dark .content-block-title {
  color: rgba(255, 255, 255, 0.87);
}
.layout-dark .content-block-inner:before {
  background-color: #393939;
}
.layout-dark .content-block-inner:after {
  background-color: #393939;
}
.layout-dark .popover .list-block ul {
  background: none;
}
.layout-dark .card {
  background: #1c1c1c;
}
.layout-dark .card-header:after {
  background-color: #393939;
}
.layout-dark .card-footer {
  color: #bbb;
}
.layout-dark .card-footer:before {
  background-color: #393939;
}
.layout-dark .list-block ul:before,
.list-block.layout-dark ul:before {
  background-color: #393939;
}
.layout-dark .list-block ul:after,
.list-block.layout-dark ul:after {
  background-color: #393939;
}
.layout-dark .list-block.inset ul,
.list-block.layout-dark.inset ul {
  background: #1c1c1c;
}
.layout-dark .list-block.notifications > ul,
.list-block.layout-dark.notifications > ul {
  background: none;
}
.layout-dark li.sorting {
  background-color: rgba(34, 34, 34, 0.8);
}
.layout-dark .swipeout-actions-left a,
.layout-dark .swipeout-actions-right a {
  background-color: #444;
}
.layout-dark .item-inner:after,
.layout-dark .list-block ul ul li:last-child .item-inner:after {
  background-color: #393939;
}
.layout-dark .item-after,
.layout-dark .item-text {
  color: rgba(255, 255, 255, 0.7);
}
.layout-dark .list-block-label {
  color: #bbb;
}
.layout-dark .item-divider,
.layout-dark .list-group-title {
  background: #1a1a1a;
  color: #bbb;
}
.layout-dark .item-divider:before,
.layout-dark .list-group-title:before {
  background-color: #393939;
}
.layout-dark .contacts-block .list-group-title {
  background: none;
}
.layout-dark .autocomplete-dropdown {
  background: #1c1c1c;
}
.layout-dark .autocomplete-dropdown .list-block {
  color: rgba(255, 255, 255, 0.54);
}
.layout-dark .autocomplete-dropdown .list-block b {
  color: rgba(255, 255, 255, 0.87);
}
html:not(.watch-active-state) .layout-dark .item-link:active,
html:not(.watch-active-state) .layout-dark label.label-checkbox:active,
html:not(.watch-active-state) .layout-dark label.label-radio:active,
.layout-dark .item-link.active-state,
.layout-dark label.label-checkbox.active-state,
.layout-dark label.label-radio.active-state {
  background-color: rgba(255, 255, 255, 0.05);
}
.layout-dark .list-block input[type="text"],
.layout-dark .list-block input[type="password"],
.layout-dark .list-block input[type="search"],
.layout-dark .list-block input[type="email"],
.layout-dark .list-block input[type="tel"],
.layout-dark .list-block input[type="url"],
.layout-dark .list-block input[type="date"],
.layout-dark .list-block input[type="datetime-local"],
.layout-dark .list-block input[type="time"],
.layout-dark .list-block input[type="number"],
.layout-dark .list-block select,
.layout-dark .list-block textarea {
  color: #fff;
}
.layout-dark .list-block input[type="text"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="password"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="search"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="email"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="tel"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="url"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="date"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="datetime-local"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="time"]::-webkit-input-placeholder,
.layout-dark .list-block input[type="number"]::-webkit-input-placeholder,
.layout-dark .list-block select::-webkit-input-placeholder,
.layout-dark .list-block textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.35);
}
.layout-dark .label-switch .checkbox {
  background: rgba(255, 255, 255, 0.3);
}
.layout-dark .item-inner:not(.focus-state) .label,
.layout-dark .item-inner:not(.focus-state) .floating-label {
  color: rgba(255, 255, 255, 0.7);
}
.layout-dark .item-inner:not(.focus-state):not(.not-empty-state) .item-input-field:after,
.layout-dark .item-inner:not(.focus-state):not(.not-empty-state) .input-field:after {
  background-color: rgba(255, 255, 255, 0.12);
}
.layout-dark label.label-checkbox i.icon-form-checkbox {
  border-color: rgba(255, 255, 255, 0.7);
}
.layout-dark label.label-radio i.icon-form-radio {
  border-color: rgba(255, 255, 255, 0.7);
}
.layout-dark .item-link.list-button:after,
.layout-dark .list-button:after {
  background-color: #393939;
}
.layout-dark .page-content .ripple-wave {
  background: rgba(255, 255, 255, 0.3);
}
html:not(.watch-active-state) .layout-dark .button:not(.button-fill):active,
.layout-dark .button:not(.button-fill).active-state {
  background: rgba(255, 255, 255, 0.1);
}
.layout-dark .chip {
  background: rgba(255, 255, 255, 0.87);
}
.layout-dark .timeline-item-inner {
  background: #1c1c1c;
}
.layout-dark .timeline-item-divider {
  background: rgba(255, 255, 255, 0.3);
}
.layout-dark .content-block-inner .timeline-item-inner {
  border-color: #393939;
}
.layout-dark .timeline-item-time {
  color: rgba(255, 255, 255, 0.7);
}
.layout-dark .data-table tbody tr.data-table-row-selected {
  background: rgba(255, 255, 255, 0.05);
}
.layout-dark .data-table tbody td:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: auto;
  right: auto;
  height: 1px;
  width: 100%;
  background-color: #393939;
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
html.pixel-ratio-2 .layout-dark .data-table tbody td:before {
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
html.pixel-ratio-3 .layout-dark .data-table tbody td:before {
  -webkit-transform: scaleY(0.33);
  transform: scaleY(0.33);
}
html:not(.ios):not(.android) .layout-dark .data-table tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
}
.layout-dark .data-table thead th:not(.sortable-active),
.layout-dark .data-table thead td:not(.sortable-active) {
  color: rgba(255, 255, 255, 0.5);
}
.layout-dark .data-table th.actions-cell a.link,
.layout-dark .data-table td.actions-cell a.link {
  color: rgba(255, 255, 255, 0.5);
}
.layout-dark .data-table .sortable-cell:not(.numeric-cell):after,
.layout-dark .data-table .sortable-cell.numeric-cell:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D'%23ffffff'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%20width%3D'24'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0%200h24v24H0V0z'%20fill%3D'none'%2F%3E%3Cpath%20d%3D'M20%2012l-1.41-1.41L13%2016.17V4h-2v12.17l-5.58-5.59L4%2012l8%208%208-8z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.layout-dark .data-table .data-table-actions a.link {
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 480px) and (orientation: portrait) {
  .layout-dark .data-table.data-table-collapsible tr:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
    height: 1px;
    width: 100%;
    background-color: #393939;
    display: block;
    z-index: 15;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  html.pixel-ratio-2 .layout-dark .data-table.data-table-collapsible tr:before {
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  html.pixel-ratio-3 .layout-dark .data-table.data-table-collapsible tr:before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
  .layout-dark .data-table.data-table-collapsible tr:hover {
    background-color: inherit;
  }
  .layout-dark .data-table.data-table-collapsible td:not(.checkbox-cell):before {
    color: rgba(255, 255, 255, 0.5);
  }
}
/*==========================
Framework7 Color Themes
==========================*/
.theme-red .button:not(.button-fill) {
  color: #f44336;
}
.theme-red .navbar .button:not(.button-fill),
.theme-red.navbar .button:not(.button-fill),
.theme-red .toolbar .button:not(.button-fill),
.theme-red.toolbar .button:not(.button-fill),
.theme-red .subnavbar .button:not(.button-fill),
.theme-red.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-red .button.button-fill {
  background: #f44336;
  color: #fff !important;
}
html:not(.watch-active-state) .theme-red .button.button-fill:active,
.theme-red .button.button-fill.active-state {
  background: #d32f2f;
}
.progressbar.theme-red,
.theme-red .progressbar {
  background-color: rgba(244, 67, 54, 0.5);
}
.progressbar.theme-red span,
.theme-red .progressbar span {
  background-color: #f44336;
}
.progressbar-infinite.theme-red,
.theme-red .progressbar-infinite {
  background-color: rgba(244, 67, 54, 0.5);
}
.progressbar-infinite.theme-red:after,
.theme-red .progressbar-infinite:after,
.progressbar-infinite.theme-red:before,
.theme-red .progressbar-infinite:before {
  background-color: #f44336;
}
.color-red i.icon,
i.icon.color-red {
  color: #f44336;
}
i.icon-next.color-red,
i.icon-next.theme-red {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23f44336'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-red,
i.icon-prev.theme-red {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23f44336'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-red,
i.icon-back.theme-red {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23f44336'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-red,
i.icon-forward.theme-red {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23f44336'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-red,
i.icon-bars.theme-red {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23f44336'%2F%3E%3C%2Fsvg%3E");
}
.theme-red a,
.theme-red .card a {
  color: #f44336;
}
.theme-red .navbar,
.navbar.theme-red,
.theme-red .toolbar,
.toolbar.theme-red,
.theme-red .subnavbar,
.subnavbar.theme-red,
.theme-red .searchbar,
.searchbar.theme-red {
  background-color: #f44336;
}
.theme-red .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-red input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(244, 67, 54, 0.5);
}
.theme-red .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-red input[type="checkbox"]:checked + .checkbox:after {
  background-color: #f44336;
}
.theme-red label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-red i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-red label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-red label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-red label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-red label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-red label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-red label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-red input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #f44336;
  background-color: #f44336;
}
.theme-red label.label-radio i.icon-form-radio:after,
label.label-radio.theme-red i.icon-form-radio:after {
  background-color: #f44336;
}
.theme-red label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-red input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-red label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-red input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-red label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-red input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-red label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-red input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-red label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-red input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-red label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-red input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #f44336;
}
.theme-red label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-red input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-red label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-red input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-red label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-red input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-red label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-red input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-red label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-red input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-red label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-red input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #f44336;
}
.theme-red .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-red input[type="checkbox"]:checked ~ i {
  border-color: #f44336;
  background-color: #f44336;
}
.theme-red .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-red input[type="radio"]:checked ~ i {
  border-color: #f44336;
}
.theme-red .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-red input[type="radio"]:checked ~ i:after {
  background-color: #f44336;
}
.theme-red .form-checkbox .ripple-wave,
.theme-red .form-radio .ripple-wave {
  background: rgba(244, 67, 54, 0.5);
}
.theme-red .focus-state .label,
.theme-red .focus-state .floating-label {
  color: #f44336;
}
.theme-red .item-input-field.focus-state:after,
.theme-red .input-field.focus-state:after,
.theme-red .item-input-field.not-empty-state:after,
.theme-red .input-field.not-empty-state:after {
  background: #f44336;
}
.theme-red .picker-calendar-day.picker-calendar-day-today span {
  color: #f44336;
}
.theme-red .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #f44336;
  color: #fff;
}
.theme-red .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #f44336;
}
.theme-red .picker-header {
  background-color: #f44336;
}
.theme-red .modal-button {
  color: #f44336;
}
.theme-red .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-red .timeline-item-date {
  background-color: #f44336;
}
.theme-red .timeline-year-title,
.theme-red .timeline-month-title {
  background-color: #f44336;
}
.theme-red .statusbar-overlay {
  background-color: #b71c1c;
}
.theme-red .data-table .data-table-header-selected,
.data-table.theme-red .data-table-header-selected {
  background: rgba(244, 67, 54, 0.1);
}
.theme-red .data-table .data-table-title-selected,
.data-table.theme-red .data-table-title-selected {
  color: #f44336;
}
.swiper-pagination.color-red .swiper-pagination-bullet-active,
.theme-red .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #f44336;
}
.swiper-pagination.color-red .swiper-pagination-progressbar,
.theme-red .swiper-pagination .swiper-pagination-progressbar {
  background-color: #f44336;
}
.swiper-pagination.swiper-pagination-progress.bg-red {
  background-color: rgba(244, 67, 54, 0.25);
}
.swiper-button-next.color-red,
.swiper-container-rtl .swiper-button-prev.color-red,
.theme-red .swiper-button-next,
.theme-red .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23f44336'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-red,
.swiper-container-rtl .swiper-button-next.color-red,
.theme-red .swiper-button-prev,
.theme-red .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23f44336'%2F%3E%3C%2Fsvg%3E");
}
.theme-red .floating-button,
.theme-red .speed-dial-buttons a,
.floating-button.theme-red,
.speed-dial-buttons a.theme-red {
  color: #fff;
  background: #f44336;
}
html:not(.watch-active-state) .theme-red .floating-button:active,
html:not(.watch-active-state) .theme-red .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-red:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-red:active,
.theme-red .floating-button.active-state,
.theme-red .speed-dial-buttons a.active-state,
.floating-button.theme-red.active-state,
.speed-dial-buttons a.theme-red.active-state {
  background: #d32f2f;
}
.theme-red .floating-button i,
.theme-red .speed-dial-buttons a i,
.floating-button.theme-red i,
.speed-dial-buttons a.theme-red i {
  color: inherit;
}
.theme-red .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-red input[type="range"]::-webkit-slider-thumb:before {
  background-color: #f44336;
}
.theme-red .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-red input[type="range"]::-webkit-slider-thumb {
  background-color: #f44336;
}
.theme-red .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-red input[type="range"]::-ms-thumb {
  background-color: #f44336;
}
.theme-red .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-red input[type="range"]::-ms-fill-lower {
  background-color: #f44336;
}
.theme-red .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-red input[type="range"]::-moz-range-thumb {
  background-color: #f44336;
}
.badge.theme-red {
  background-color: #f44336;
  color: #fff;
}
.theme-pink .button:not(.button-fill) {
  color: #e91e63;
}
.theme-pink .navbar .button:not(.button-fill),
.theme-pink.navbar .button:not(.button-fill),
.theme-pink .toolbar .button:not(.button-fill),
.theme-pink.toolbar .button:not(.button-fill),
.theme-pink .subnavbar .button:not(.button-fill),
.theme-pink.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-pink .button.button-fill {
  background: #e91e63;
  color: #fff;
}
html:not(.watch-active-state) .theme-pink .button.button-fill:active,
.theme-pink .button.button-fill.active-state {
  background: #c2185b;
}
.progressbar.theme-pink,
.theme-pink .progressbar {
  background-color: rgba(233, 30, 99, 0.5);
}
.progressbar.theme-pink span,
.theme-pink .progressbar span {
  background-color: #e91e63;
}
.progressbar-infinite.theme-pink,
.theme-pink .progressbar-infinite {
  background-color: rgba(233, 30, 99, 0.5);
}
.progressbar-infinite.theme-pink:after,
.theme-pink .progressbar-infinite:after,
.progressbar-infinite.theme-pink:before,
.theme-pink .progressbar-infinite:before {
  background-color: #e91e63;
}
.color-pink i.icon,
i.icon.color-pink {
  color: #e91e63;
}
i.icon-next.color-pink,
i.icon-next.theme-pink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23e91e63'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-pink,
i.icon-prev.theme-pink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23e91e63'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-pink,
i.icon-back.theme-pink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23e91e63'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-pink,
i.icon-forward.theme-pink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23e91e63'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-pink,
i.icon-bars.theme-pink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23e91e63'%2F%3E%3C%2Fsvg%3E");
}
.theme-pink a,
.theme-pink .card a {
  color: #e91e63;
}
.theme-pink .navbar,
.navbar.theme-pink,
.theme-pink .toolbar,
.toolbar.theme-pink,
.theme-pink .subnavbar,
.subnavbar.theme-pink,
.theme-pink .searchbar,
.searchbar.theme-pink {
  background-color: #e91e63;
}
.theme-pink .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-pink input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(233, 30, 99, 0.5);
}
.theme-pink .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-pink input[type="checkbox"]:checked + .checkbox:after {
  background-color: #e91e63;
}
.theme-pink label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-pink i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-pink label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-pink label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-pink label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-pink label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-pink label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-pink label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-pink input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #e91e63;
  background-color: #e91e63;
}
.theme-pink label.label-radio i.icon-form-radio:after,
label.label-radio.theme-pink i.icon-form-radio:after {
  background-color: #e91e63;
}
.theme-pink label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-pink input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-pink label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-pink input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-pink label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-pink input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-pink label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-pink input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-pink label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-pink input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-pink label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-pink input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #e91e63;
}
.theme-pink label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-pink input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-pink label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-pink input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-pink label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-pink input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-pink label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-pink input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-pink label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-pink input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-pink label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-pink input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #e91e63;
}
.theme-pink .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-pink input[type="checkbox"]:checked ~ i {
  border-color: #e91e63;
  background-color: #e91e63;
}
.theme-pink .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-pink input[type="radio"]:checked ~ i {
  border-color: #e91e63;
}
.theme-pink .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-pink input[type="radio"]:checked ~ i:after {
  background-color: #e91e63;
}
.theme-pink .form-checkbox .ripple-wave,
.theme-pink .form-radio .ripple-wave {
  background: rgba(233, 30, 99, 0.5);
}
.theme-pink .focus-state .label,
.theme-pink .focus-state .floating-label {
  color: #e91e63;
}
.theme-pink .item-input-field.focus-state:after,
.theme-pink .input-field.focus-state:after,
.theme-pink .item-input-field.not-empty-state:after,
.theme-pink .input-field.not-empty-state:after {
  background: #e91e63;
}
.theme-pink .picker-calendar-day.picker-calendar-day-today span {
  color: #e91e63;
}
.theme-pink .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #e91e63;
  color: #fff;
}
.theme-pink .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #e91e63;
}
.theme-pink .picker-header {
  background-color: #e91e63;
}
.theme-pink .modal-button {
  color: #e91e63;
}
.theme-pink .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-pink .timeline-item-date {
  background-color: #e91e63;
}
.theme-pink .timeline-year-title,
.theme-pink .timeline-month-title {
  background-color: #e91e63;
}
.theme-pink .statusbar-overlay {
  background-color: #880e4f;
}
.theme-pink .data-table .data-table-header-selected,
.data-table.theme-pink .data-table-header-selected {
  background: rgba(233, 30, 99, 0.1);
}
.theme-pink .data-table .data-table-title-selected,
.data-table.theme-pink .data-table-title-selected {
  color: #e91e63;
}
.swiper-pagination.color-pink .swiper-pagination-bullet-active,
.theme-pink .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e91e63;
}
.swiper-pagination.color-pink .swiper-pagination-progressbar,
.theme-pink .swiper-pagination .swiper-pagination-progressbar {
  background-color: #e91e63;
}
.swiper-pagination.swiper-pagination-progress.bg-pink {
  background-color: rgba(233, 30, 99, 0.25);
}
.swiper-button-next.color-pink,
.swiper-container-rtl .swiper-button-prev.color-pink,
.theme-pink .swiper-button-next,
.theme-pink .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23e91e63'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-pink,
.swiper-container-rtl .swiper-button-next.color-pink,
.theme-pink .swiper-button-prev,
.theme-pink .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23e91e63'%2F%3E%3C%2Fsvg%3E");
}
.theme-pink .floating-button,
.theme-pink .speed-dial-buttons a,
.floating-button.theme-pink,
.speed-dial-buttons a.theme-pink {
  color: #fff;
  background: #e91e63;
}
html:not(.watch-active-state) .theme-pink .floating-button:active,
html:not(.watch-active-state) .theme-pink .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-pink:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-pink:active,
.theme-pink .floating-button.active-state,
.theme-pink .speed-dial-buttons a.active-state,
.floating-button.theme-pink.active-state,
.speed-dial-buttons a.theme-pink.active-state {
  background: #c2185b;
}
.theme-pink .floating-button i,
.theme-pink .speed-dial-buttons a i,
.floating-button.theme-pink i,
.speed-dial-buttons a.theme-pink i {
  color: inherit;
}
.theme-pink .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-pink input[type="range"]::-webkit-slider-thumb:before {
  background-color: #e91e63;
}
.theme-pink .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-pink input[type="range"]::-webkit-slider-thumb {
  background-color: #e91e63;
}
.theme-pink .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-pink input[type="range"]::-ms-thumb {
  background-color: #e91e63;
}
.theme-pink .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-pink input[type="range"]::-ms-fill-lower {
  background-color: #e91e63;
}
.theme-pink .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-pink input[type="range"]::-moz-range-thumb {
  background-color: #e91e63;
}
.badge.theme-pink {
  background-color: #e91e63;
  color: #fff;
}
.theme-purple .button:not(.button-fill) {
  color: #9c27b0;
}
.theme-purple .navbar .button:not(.button-fill),
.theme-purple.navbar .button:not(.button-fill),
.theme-purple .toolbar .button:not(.button-fill),
.theme-purple.toolbar .button:not(.button-fill),
.theme-purple .subnavbar .button:not(.button-fill),
.theme-purple.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-purple .button.button-fill {
  background: #9c27b0;
  color: #fff;
}
html:not(.watch-active-state) .theme-purple .button.button-fill:active,
.theme-purple .button.button-fill.active-state {
  background: #7b1fa2;
}
.progressbar.theme-purple,
.theme-purple .progressbar {
  background-color: rgba(156, 39, 176, 0.5);
}
.progressbar.theme-purple span,
.theme-purple .progressbar span {
  background-color: #9c27b0;
}
.progressbar-infinite.theme-purple,
.theme-purple .progressbar-infinite {
  background-color: rgba(156, 39, 176, 0.5);
}
.progressbar-infinite.theme-purple:after,
.theme-purple .progressbar-infinite:after,
.progressbar-infinite.theme-purple:before,
.theme-purple .progressbar-infinite:before {
  background-color: #9c27b0;
}
.color-purple i.icon,
i.icon.color-purple {
  color: #9c27b0;
}
i.icon-next.color-purple,
i.icon-next.theme-purple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%239c27b0'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-purple,
i.icon-prev.theme-purple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%239c27b0'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-purple,
i.icon-back.theme-purple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%239c27b0'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-purple,
i.icon-forward.theme-purple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%239c27b0'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-purple,
i.icon-bars.theme-purple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%239c27b0'%2F%3E%3C%2Fsvg%3E");
}
.theme-purple a,
.theme-purple .card a {
  color: #9c27b0;
}
.theme-purple .navbar,
.navbar.theme-purple,
.theme-purple .toolbar,
.toolbar.theme-purple,
.theme-purple .subnavbar,
.subnavbar.theme-purple,
.theme-purple .searchbar,
.searchbar.theme-purple {
  background-color: #9c27b0;
}
.theme-purple .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-purple input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(156, 39, 176, 0.5);
}
.theme-purple .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-purple input[type="checkbox"]:checked + .checkbox:after {
  background-color: #9c27b0;
}
.theme-purple label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-purple i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-purple label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-purple label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-purple label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-purple label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-purple label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-purple label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-purple input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #9c27b0;
  background-color: #9c27b0;
}
.theme-purple label.label-radio i.icon-form-radio:after,
label.label-radio.theme-purple i.icon-form-radio:after {
  background-color: #9c27b0;
}
.theme-purple label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-purple input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-purple label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-purple input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-purple label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-purple input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-purple label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-purple input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-purple label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-purple input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-purple label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-purple input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #9c27b0;
}
.theme-purple label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-purple input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-purple label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-purple input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-purple label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-purple input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-purple label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-purple input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-purple label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-purple input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-purple label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-purple input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #9c27b0;
}
.theme-purple .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-purple input[type="checkbox"]:checked ~ i {
  border-color: #9c27b0;
  background-color: #9c27b0;
}
.theme-purple .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-purple input[type="radio"]:checked ~ i {
  border-color: #9c27b0;
}
.theme-purple .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-purple input[type="radio"]:checked ~ i:after {
  background-color: #9c27b0;
}
.theme-purple .form-checkbox .ripple-wave,
.theme-purple .form-radio .ripple-wave {
  background: rgba(156, 39, 176, 0.5);
}
.theme-purple .focus-state .label,
.theme-purple .focus-state .floating-label {
  color: #9c27b0;
}
.theme-purple .item-input-field.focus-state:after,
.theme-purple .input-field.focus-state:after,
.theme-purple .item-input-field.not-empty-state:after,
.theme-purple .input-field.not-empty-state:after {
  background: #9c27b0;
}
.theme-purple .picker-calendar-day.picker-calendar-day-today span {
  color: #9c27b0;
}
.theme-purple .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #9c27b0;
  color: #fff;
}
.theme-purple .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #9c27b0;
}
.theme-purple .picker-header {
  background-color: #9c27b0;
}
.theme-purple .modal-button {
  color: #9c27b0;
}
.theme-purple .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-purple .timeline-item-date {
  background-color: #9c27b0;
}
.theme-purple .timeline-year-title,
.theme-purple .timeline-month-title {
  background-color: #9c27b0;
}
.theme-purple .statusbar-overlay {
  background-color: #4a148c;
}
.theme-purple .data-table .data-table-header-selected,
.data-table.theme-purple .data-table-header-selected {
  background: rgba(156, 39, 176, 0.1);
}
.theme-purple .data-table .data-table-title-selected,
.data-table.theme-purple .data-table-title-selected {
  color: #9c27b0;
}
.swiper-pagination.color-purple .swiper-pagination-bullet-active,
.theme-purple .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #9c27b0;
}
.swiper-pagination.color-purple .swiper-pagination-progressbar,
.theme-purple .swiper-pagination .swiper-pagination-progressbar {
  background-color: #9c27b0;
}
.swiper-pagination.swiper-pagination-progress.bg-purple {
  background-color: rgba(156, 39, 176, 0.25);
}
.swiper-button-next.color-purple,
.swiper-container-rtl .swiper-button-prev.color-purple,
.theme-purple .swiper-button-next,
.theme-purple .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%239c27b0'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-purple,
.swiper-container-rtl .swiper-button-next.color-purple,
.theme-purple .swiper-button-prev,
.theme-purple .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%239c27b0'%2F%3E%3C%2Fsvg%3E");
}
.theme-purple .floating-button,
.theme-purple .speed-dial-buttons a,
.floating-button.theme-purple,
.speed-dial-buttons a.theme-purple {
  color: #fff;
  background: #9c27b0;
}
html:not(.watch-active-state) .theme-purple .floating-button:active,
html:not(.watch-active-state) .theme-purple .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-purple:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-purple:active,
.theme-purple .floating-button.active-state,
.theme-purple .speed-dial-buttons a.active-state,
.floating-button.theme-purple.active-state,
.speed-dial-buttons a.theme-purple.active-state {
  background: #7b1fa2;
}
.theme-purple .floating-button i,
.theme-purple .speed-dial-buttons a i,
.floating-button.theme-purple i,
.speed-dial-buttons a.theme-purple i {
  color: inherit;
}
.theme-purple .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-purple input[type="range"]::-webkit-slider-thumb:before {
  background-color: #9c27b0;
}
.theme-purple .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-purple input[type="range"]::-webkit-slider-thumb {
  background-color: #9c27b0;
}
.theme-purple .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-purple input[type="range"]::-ms-thumb {
  background-color: #9c27b0;
}
.theme-purple .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-purple input[type="range"]::-ms-fill-lower {
  background-color: #9c27b0;
}
.theme-purple .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-purple input[type="range"]::-moz-range-thumb {
  background-color: #9c27b0;
}
.badge.theme-purple {
  background-color: #9c27b0;
  color: #fff;
}
.theme-deeppurple .button:not(.button-fill) {
  color: #673ab7;
}
.theme-deeppurple .navbar .button:not(.button-fill),
.theme-deeppurple.navbar .button:not(.button-fill),
.theme-deeppurple .toolbar .button:not(.button-fill),
.theme-deeppurple.toolbar .button:not(.button-fill),
.theme-deeppurple .subnavbar .button:not(.button-fill),
.theme-deeppurple.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-deeppurple .button.button-fill {
  background: #673ab7;
  color: #fff;
}
html:not(.watch-active-state) .theme-deeppurple .button.button-fill:active,
.theme-deeppurple .button.button-fill.active-state {
  background: #512da8;
}
.progressbar.theme-deeppurple,
.theme-deeppurple .progressbar {
  background-color: rgba(103, 58, 183, 0.5);
}
.progressbar.theme-deeppurple span,
.theme-deeppurple .progressbar span {
  background-color: #673ab7;
}
.progressbar-infinite.theme-deeppurple,
.theme-deeppurple .progressbar-infinite {
  background-color: rgba(103, 58, 183, 0.5);
}
.progressbar-infinite.theme-deeppurple:after,
.theme-deeppurple .progressbar-infinite:after,
.progressbar-infinite.theme-deeppurple:before,
.theme-deeppurple .progressbar-infinite:before {
  background-color: #673ab7;
}
.color-deeppurple i.icon,
i.icon.color-deeppurple {
  color: #673ab7;
}
i.icon-next.color-deeppurple,
i.icon-next.theme-deeppurple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23673ab7'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-deeppurple,
i.icon-prev.theme-deeppurple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23673ab7'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-deeppurple,
i.icon-back.theme-deeppurple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23673ab7'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-deeppurple,
i.icon-forward.theme-deeppurple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23673ab7'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-deeppurple,
i.icon-bars.theme-deeppurple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23673ab7'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeppurple a,
.theme-deeppurple .card a {
  color: #673ab7;
}
.theme-deeppurple .navbar,
.navbar.theme-deeppurple,
.theme-deeppurple .toolbar,
.toolbar.theme-deeppurple,
.theme-deeppurple .subnavbar,
.subnavbar.theme-deeppurple,
.theme-deeppurple .searchbar,
.searchbar.theme-deeppurple {
  background-color: #673ab7;
}
.theme-deeppurple .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-deeppurple input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(103, 58, 183, 0.5);
}
.theme-deeppurple .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-deeppurple input[type="checkbox"]:checked + .checkbox:after {
  background-color: #673ab7;
}
.theme-deeppurple label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-deeppurple i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeppurple label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-deeppurple label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-deeppurple label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-deeppurple label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-deeppurple label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-deeppurple label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-deeppurple input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #673ab7;
  background-color: #673ab7;
}
.theme-deeppurple label.label-radio i.icon-form-radio:after,
label.label-radio.theme-deeppurple i.icon-form-radio:after {
  background-color: #673ab7;
}
.theme-deeppurple label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-deeppurple label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-deeppurple label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-deeppurple label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-deeppurple label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-deeppurple label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-deeppurple input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #673ab7;
}
.theme-deeppurple label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-deeppurple label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-deeppurple label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-deeppurple label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-deeppurple label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-deeppurple label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-deeppurple input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #673ab7;
}
.theme-deeppurple .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-deeppurple input[type="checkbox"]:checked ~ i {
  border-color: #673ab7;
  background-color: #673ab7;
}
.theme-deeppurple .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-deeppurple input[type="radio"]:checked ~ i {
  border-color: #673ab7;
}
.theme-deeppurple .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-deeppurple input[type="radio"]:checked ~ i:after {
  background-color: #673ab7;
}
.theme-deeppurple .form-checkbox .ripple-wave,
.theme-deeppurple .form-radio .ripple-wave {
  background: rgba(103, 58, 183, 0.5);
}
.theme-deeppurple .focus-state .label,
.theme-deeppurple .focus-state .floating-label {
  color: #673ab7;
}
.theme-deeppurple .item-input-field.focus-state:after,
.theme-deeppurple .input-field.focus-state:after,
.theme-deeppurple .item-input-field.not-empty-state:after,
.theme-deeppurple .input-field.not-empty-state:after {
  background: #673ab7;
}
.theme-deeppurple .picker-calendar-day.picker-calendar-day-today span {
  color: #673ab7;
}
.theme-deeppurple .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #673ab7;
  color: #fff;
}
.theme-deeppurple .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #673ab7;
}
.theme-deeppurple .picker-header {
  background-color: #673ab7;
}
.theme-deeppurple .modal-button {
  color: #673ab7;
}
.theme-deeppurple .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-deeppurple .timeline-item-date {
  background-color: #673ab7;
}
.theme-deeppurple .timeline-year-title,
.theme-deeppurple .timeline-month-title {
  background-color: #673ab7;
}
.theme-deeppurple .statusbar-overlay {
  background-color: #311b92;
}
.theme-deeppurple .data-table .data-table-header-selected,
.data-table.theme-deeppurple .data-table-header-selected {
  background: rgba(103, 58, 183, 0.1);
}
.theme-deeppurple .data-table .data-table-title-selected,
.data-table.theme-deeppurple .data-table-title-selected {
  color: #673ab7;
}
.swiper-pagination.color-deeppurple .swiper-pagination-bullet-active,
.theme-deeppurple .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #673ab7;
}
.swiper-pagination.color-deeppurple .swiper-pagination-progressbar,
.theme-deeppurple .swiper-pagination .swiper-pagination-progressbar {
  background-color: #673ab7;
}
.swiper-pagination.swiper-pagination-progress.bg-deeppurple {
  background-color: rgba(103, 58, 183, 0.25);
}
.swiper-button-next.color-deeppurple,
.swiper-container-rtl .swiper-button-prev.color-deeppurple,
.theme-deeppurple .swiper-button-next,
.theme-deeppurple .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23673ab7'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-deeppurple,
.swiper-container-rtl .swiper-button-next.color-deeppurple,
.theme-deeppurple .swiper-button-prev,
.theme-deeppurple .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23673ab7'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeppurple .floating-button,
.theme-deeppurple .speed-dial-buttons a,
.floating-button.theme-deeppurple,
.speed-dial-buttons a.theme-deeppurple {
  color: #fff;
  background: #673ab7;
}
html:not(.watch-active-state) .theme-deeppurple .floating-button:active,
html:not(.watch-active-state) .theme-deeppurple .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-deeppurple:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-deeppurple:active,
.theme-deeppurple .floating-button.active-state,
.theme-deeppurple .speed-dial-buttons a.active-state,
.floating-button.theme-deeppurple.active-state,
.speed-dial-buttons a.theme-deeppurple.active-state {
  background: #512da8;
}
.theme-deeppurple .floating-button i,
.theme-deeppurple .speed-dial-buttons a i,
.floating-button.theme-deeppurple i,
.speed-dial-buttons a.theme-deeppurple i {
  color: inherit;
}
.theme-deeppurple .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-deeppurple input[type="range"]::-webkit-slider-thumb:before {
  background-color: #673ab7;
}
.theme-deeppurple .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-deeppurple input[type="range"]::-webkit-slider-thumb {
  background-color: #673ab7;
}
.theme-deeppurple .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-deeppurple input[type="range"]::-ms-thumb {
  background-color: #673ab7;
}
.theme-deeppurple .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-deeppurple input[type="range"]::-ms-fill-lower {
  background-color: #673ab7;
}
.theme-deeppurple .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-deeppurple input[type="range"]::-moz-range-thumb {
  background-color: #673ab7;
}
.badge.theme-deeppurple {
  background-color: #673ab7;
  color: #fff;
}
.theme-indigo .button:not(.button-fill) {
  color: #3f51b5;
}
.theme-indigo .navbar .button:not(.button-fill),
.theme-indigo.navbar .button:not(.button-fill),
.theme-indigo .toolbar .button:not(.button-fill),
.theme-indigo.toolbar .button:not(.button-fill),
.theme-indigo .subnavbar .button:not(.button-fill),
.theme-indigo.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-indigo .button.button-fill {
  background: #3f51b5;
  color: #fff;
}
html:not(.watch-active-state) .theme-indigo .button.button-fill:active,
.theme-indigo .button.button-fill.active-state {
  background: #303f9f;
}
.progressbar.theme-indigo,
.theme-indigo .progressbar {
  background-color: rgba(63, 81, 181, 0.5);
}
.progressbar.theme-indigo span,
.theme-indigo .progressbar span {
  background-color: #3f51b5;
}
.progressbar-infinite.theme-indigo,
.theme-indigo .progressbar-infinite {
  background-color: rgba(63, 81, 181, 0.5);
}
.progressbar-infinite.theme-indigo:after,
.theme-indigo .progressbar-infinite:after,
.progressbar-infinite.theme-indigo:before,
.theme-indigo .progressbar-infinite:before {
  background-color: #3f51b5;
}
.color-indigo i.icon,
i.icon.color-indigo {
  color: #3f51b5;
}
i.icon-next.color-indigo,
i.icon-next.theme-indigo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%233f51b5'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-indigo,
i.icon-prev.theme-indigo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%233f51b5'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-indigo,
i.icon-back.theme-indigo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%233f51b5'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-indigo,
i.icon-forward.theme-indigo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%233f51b5'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-indigo,
i.icon-bars.theme-indigo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%233f51b5'%2F%3E%3C%2Fsvg%3E");
}
.theme-indigo a,
.theme-indigo .card a {
  color: #3f51b5;
}
.theme-indigo .navbar,
.navbar.theme-indigo,
.theme-indigo .toolbar,
.toolbar.theme-indigo,
.theme-indigo .subnavbar,
.subnavbar.theme-indigo,
.theme-indigo .searchbar,
.searchbar.theme-indigo {
  background-color: #3f51b5;
}
.theme-indigo .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-indigo input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(63, 81, 181, 0.5);
}
.theme-indigo .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-indigo input[type="checkbox"]:checked + .checkbox:after {
  background-color: #3f51b5;
}
.theme-indigo label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-indigo i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-indigo label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-indigo label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-indigo label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-indigo label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-indigo label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-indigo label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-indigo input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #3f51b5;
  background-color: #3f51b5;
}
.theme-indigo label.label-radio i.icon-form-radio:after,
label.label-radio.theme-indigo i.icon-form-radio:after {
  background-color: #3f51b5;
}
.theme-indigo label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-indigo input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-indigo label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-indigo input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-indigo label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-indigo input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-indigo label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-indigo input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-indigo label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-indigo input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-indigo label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-indigo input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #3f51b5;
}
.theme-indigo label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-indigo label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-indigo label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-indigo label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-indigo label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-indigo label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-indigo input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #3f51b5;
}
.theme-indigo .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-indigo input[type="checkbox"]:checked ~ i {
  border-color: #3f51b5;
  background-color: #3f51b5;
}
.theme-indigo .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-indigo input[type="radio"]:checked ~ i {
  border-color: #3f51b5;
}
.theme-indigo .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-indigo input[type="radio"]:checked ~ i:after {
  background-color: #3f51b5;
}
.theme-indigo .form-checkbox .ripple-wave,
.theme-indigo .form-radio .ripple-wave {
  background: rgba(63, 81, 181, 0.5);
}
.theme-indigo .focus-state .label,
.theme-indigo .focus-state .floating-label {
  color: #3f51b5;
}
.theme-indigo .item-input-field.focus-state:after,
.theme-indigo .input-field.focus-state:after,
.theme-indigo .item-input-field.not-empty-state:after,
.theme-indigo .input-field.not-empty-state:after {
  background: #3f51b5;
}
.theme-indigo .picker-calendar-day.picker-calendar-day-today span {
  color: #3f51b5;
}
.theme-indigo .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #3f51b5;
  color: #fff;
}
.theme-indigo .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #3f51b5;
}
.theme-indigo .picker-header {
  background-color: #3f51b5;
}
.theme-indigo .modal-button {
  color: #3f51b5;
}
.theme-indigo .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-indigo .timeline-item-date {
  background-color: #3f51b5;
}
.theme-indigo .timeline-year-title,
.theme-indigo .timeline-month-title {
  background-color: #3f51b5;
}
.theme-indigo .statusbar-overlay {
  background-color: #1a237e;
}
.theme-indigo .data-table .data-table-header-selected,
.data-table.theme-indigo .data-table-header-selected {
  background: rgba(63, 81, 181, 0.1);
}
.theme-indigo .data-table .data-table-title-selected,
.data-table.theme-indigo .data-table-title-selected {
  color: #3f51b5;
}
.swiper-pagination.color-indigo .swiper-pagination-bullet-active,
.theme-indigo .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3f51b5;
}
.swiper-pagination.color-indigo .swiper-pagination-progressbar,
.theme-indigo .swiper-pagination .swiper-pagination-progressbar {
  background-color: #3f51b5;
}
.swiper-pagination.swiper-pagination-progress.bg-indigo {
  background-color: rgba(63, 81, 181, 0.25);
}
.swiper-button-next.color-indigo,
.swiper-container-rtl .swiper-button-prev.color-indigo,
.theme-indigo .swiper-button-next,
.theme-indigo .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%233f51b5'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-indigo,
.swiper-container-rtl .swiper-button-next.color-indigo,
.theme-indigo .swiper-button-prev,
.theme-indigo .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%233f51b5'%2F%3E%3C%2Fsvg%3E");
}
.theme-indigo .floating-button,
.theme-indigo .speed-dial-buttons a,
.floating-button.theme-indigo,
.speed-dial-buttons a.theme-indigo {
  color: #fff;
  background: #3f51b5;
}
html:not(.watch-active-state) .theme-indigo .floating-button:active,
html:not(.watch-active-state) .theme-indigo .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-indigo:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-indigo:active,
.theme-indigo .floating-button.active-state,
.theme-indigo .speed-dial-buttons a.active-state,
.floating-button.theme-indigo.active-state,
.speed-dial-buttons a.theme-indigo.active-state {
  background: #303f9f;
}
.theme-indigo .floating-button i,
.theme-indigo .speed-dial-buttons a i,
.floating-button.theme-indigo i,
.speed-dial-buttons a.theme-indigo i {
  color: inherit;
}
.theme-indigo .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-indigo input[type="range"]::-webkit-slider-thumb:before {
  background-color: #3f51b5;
}
.theme-indigo .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-indigo input[type="range"]::-webkit-slider-thumb {
  background-color: #3f51b5;
}
.theme-indigo .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-indigo input[type="range"]::-ms-thumb {
  background-color: #3f51b5;
}
.theme-indigo .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-indigo input[type="range"]::-ms-fill-lower {
  background-color: #3f51b5;
}
.theme-indigo .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-indigo input[type="range"]::-moz-range-thumb {
  background-color: #3f51b5;
}
.badge.theme-indigo {
  background-color: #3f51b5;
  color: #fff;
}
.theme-blue .button:not(.button-fill) {
  color: #2196f3;
}
.theme-blue .navbar .button:not(.button-fill),
.theme-blue.navbar .button:not(.button-fill),
.theme-blue .toolbar .button:not(.button-fill),
.theme-blue.toolbar .button:not(.button-fill),
.theme-blue .subnavbar .button:not(.button-fill),
.theme-blue.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-blue .button.button-fill {
  background: #2196f3;
  color: #fff;
}

.theme-sport-blue .button.button-fill {
  background: #3D9DFF;
  color: #fff;
}

.theme-cp98-white .button.button-fill {
  background: #f7f7f7;
  color: #607989;
}

html:not(.watch-active-state) .theme-blue .button.button-fill:active,
.theme-blue .button.button-fill.active-state {
  background: #1976d2;
}
.progressbar.theme-blue,
.theme-blue .progressbar {
  background-color: rgba(33, 150, 243, 0.5);
}
.progressbar.theme-blue span,
.theme-blue .progressbar span {
  background-color: #2196f3;
}
.progressbar-infinite.theme-blue,
.theme-blue .progressbar-infinite {
  background-color: rgba(33, 150, 243, 0.5);
}
.progressbar-infinite.theme-blue:after,
.theme-blue .progressbar-infinite:after,
.progressbar-infinite.theme-blue:before,
.theme-blue .progressbar-infinite:before {
  background-color: #2196f3;
}
.color-blue i.icon,
i.icon.color-blue {
  color: #2196f3;
}
i.icon-next.color-blue,
i.icon-next.theme-blue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%232196f3'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-blue,
i.icon-prev.theme-blue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%232196f3'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-blue,
i.icon-back.theme-blue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%232196f3'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-blue,
i.icon-forward.theme-blue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%232196f3'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-blue,
i.icon-bars.theme-blue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%232196f3'%2F%3E%3C%2Fsvg%3E");
}
.theme-blue a,
.theme-blue .card a {
  color: #2196f3;
}
.theme-blue .navbar,
.navbar.theme-blue,
.theme-blue .toolbar,
.toolbar.theme-blue,
.theme-blue .subnavbar,
.subnavbar.theme-blue,
.theme-blue .searchbar,
.searchbar.theme-blue {
  background-color: #2196f3;
}
.theme-blue .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-blue input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(33, 150, 243, 0.5);
}
.theme-blue .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-blue input[type="checkbox"]:checked + .checkbox:after {
  background-color: #2196f3;
}
.theme-blue label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-blue i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-blue label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-blue label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-blue label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-blue label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-blue label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-blue label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-blue input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #2196f3;
  background-color: #2196f3;
}
.theme-blue label.label-radio i.icon-form-radio:after,
label.label-radio.theme-blue i.icon-form-radio:after {
  background-color: #2196f3;
}
.theme-blue label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-blue input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-blue label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-blue input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-blue label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-blue input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-blue label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-blue input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-blue label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-blue input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-blue label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-blue input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #2196f3;
}
.theme-blue label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-blue input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-blue label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-blue input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-blue label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-blue input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-blue label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-blue input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-blue label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-blue input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-blue label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-blue input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #2196f3;
}
.theme-blue .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-blue input[type="checkbox"]:checked ~ i {
  border-color: #2196f3;
  background-color: #2196f3;
}
.theme-blue .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-blue input[type="radio"]:checked ~ i {
  border-color: #2196f3;
}
.theme-blue .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-blue input[type="radio"]:checked ~ i:after {
  background-color: #2196f3;
}
.theme-blue .form-checkbox .ripple-wave,
.theme-blue .form-radio .ripple-wave {
  background: rgba(33, 150, 243, 0.5);
}
.theme-blue .focus-state .label,
.theme-blue .focus-state .floating-label {
  color: #2196f3;
}
.theme-blue .item-input-field.focus-state:after,
.theme-blue .input-field.focus-state:after,
.theme-blue .item-input-field.not-empty-state:after,
.theme-blue .input-field.not-empty-state:after {
  background: #2196f3;
}
.theme-blue .picker-calendar-day.picker-calendar-day-today span {
  color: #2196f3;
}
.theme-blue .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #2196f3;
  color: #fff;
}
.theme-blue .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #2196f3;
}
.theme-blue .picker-header {
  background-color: #2196f3;
}
.theme-blue .modal-button {
  color: #2196f3;
}
.theme-blue .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-blue .timeline-item-date {
  background-color: #2196f3;
}
.theme-blue .timeline-year-title,
.theme-blue .timeline-month-title {
  background-color: #2196f3;
}
.theme-blue .statusbar-overlay {
  background-color: #0d47a1;
}
.theme-blue .data-table .data-table-header-selected,
.data-table.theme-blue .data-table-header-selected {
  background: rgba(33, 150, 243, 0.1);
}
.theme-blue .data-table .data-table-title-selected,
.data-table.theme-blue .data-table-title-selected {
  color: #2196f3;
}
.swiper-pagination.color-blue .swiper-pagination-bullet-active,
.theme-blue .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2196f3;
}
.swiper-pagination.color-blue .swiper-pagination-progressbar,
.theme-blue .swiper-pagination .swiper-pagination-progressbar {
  background-color: #2196f3;
}
.swiper-pagination.swiper-pagination-progress.bg-blue {
  background-color: rgba(33, 150, 243, 0.25);
}
.swiper-button-next.color-blue,
.swiper-container-rtl .swiper-button-prev.color-blue,
.theme-blue .swiper-button-next,
.theme-blue .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%232196f3'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-blue,
.swiper-container-rtl .swiper-button-next.color-blue,
.theme-blue .swiper-button-prev,
.theme-blue .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%232196f3'%2F%3E%3C%2Fsvg%3E");
}
.theme-blue .floating-button,
.theme-blue .speed-dial-buttons a,
.floating-button.theme-blue,
.speed-dial-buttons a.theme-blue {
  color: #fff;
  background: #2196f3;
}
html:not(.watch-active-state) .theme-blue .floating-button:active,
html:not(.watch-active-state) .theme-blue .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-blue:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-blue:active,
.theme-blue .floating-button.active-state,
.theme-blue .speed-dial-buttons a.active-state,
.floating-button.theme-blue.active-state,
.speed-dial-buttons a.theme-blue.active-state {
  background: #1976d2;
}
.theme-blue .floating-button i,
.theme-blue .speed-dial-buttons a i,
.floating-button.theme-blue i,
.speed-dial-buttons a.theme-blue i {
  color: inherit;
}
.theme-blue .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-blue input[type="range"]::-webkit-slider-thumb:before {
  background-color: #2196f3;
}
.theme-blue .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-blue input[type="range"]::-webkit-slider-thumb {
  background-color: #2196f3;
}
.theme-blue .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-blue input[type="range"]::-ms-thumb {
  background-color: #2196f3;
}
.theme-blue .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-blue input[type="range"]::-ms-fill-lower {
  background-color: #2196f3;
}
.theme-blue .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-blue input[type="range"]::-moz-range-thumb {
  background-color: #2196f3;
}
.badge.theme-blue {
  background-color: #2196f3;
  color: #fff;
}
.theme-lightblue .button:not(.button-fill) {
  color: #03a9f4;
}
.theme-lightblue .navbar .button:not(.button-fill),
.theme-lightblue.navbar .button:not(.button-fill),
.theme-lightblue .toolbar .button:not(.button-fill),
.theme-lightblue.toolbar .button:not(.button-fill),
.theme-lightblue .subnavbar .button:not(.button-fill),
.theme-lightblue.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-lightblue .button.button-fill {
  background: #03a9f4;
  color: #fff;
}
html:not(.watch-active-state) .theme-lightblue .button.button-fill:active,
.theme-lightblue .button.button-fill.active-state {
  background: #0288d1;
}
.progressbar.theme-lightblue,
.theme-lightblue .progressbar {
  background-color: rgba(3, 169, 244, 0.5);
}
.progressbar.theme-lightblue span,
.theme-lightblue .progressbar span {
  background-color: #03a9f4;
}
.progressbar-infinite.theme-lightblue,
.theme-lightblue .progressbar-infinite {
  background-color: rgba(3, 169, 244, 0.5);
}
.progressbar-infinite.theme-lightblue:after,
.theme-lightblue .progressbar-infinite:after,
.progressbar-infinite.theme-lightblue:before,
.theme-lightblue .progressbar-infinite:before {
  background-color: #03a9f4;
}
.color-lightblue i.icon,
i.icon.color-lightblue {
  color: #03a9f4;
}
i.icon-next.color-lightblue,
i.icon-next.theme-lightblue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%2303a9f4'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-lightblue,
i.icon-prev.theme-lightblue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%2303a9f4'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-lightblue,
i.icon-back.theme-lightblue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%2303a9f4'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-lightblue,
i.icon-forward.theme-lightblue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%2303a9f4'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-lightblue,
i.icon-bars.theme-lightblue {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%2303a9f4'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightblue a,
.theme-lightblue .card a {
  color: #03a9f4;
}
.theme-lightblue .navbar,
.navbar.theme-lightblue,
.theme-lightblue .toolbar,
.toolbar.theme-lightblue,
.theme-lightblue .subnavbar,
.subnavbar.theme-lightblue,
.theme-lightblue .searchbar,
.searchbar.theme-lightblue {
  background-color: #03a9f4;
}
.theme-lightblue .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-lightblue input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(3, 169, 244, 0.5);
}
.theme-lightblue .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-lightblue input[type="checkbox"]:checked + .checkbox:after {
  background-color: #03a9f4;
}
.theme-lightblue label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-lightblue i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightblue label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-lightblue label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-lightblue label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lightblue label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lightblue label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-lightblue label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lightblue input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #03a9f4;
  background-color: #03a9f4;
}
.theme-lightblue label.label-radio i.icon-form-radio:after,
label.label-radio.theme-lightblue i.icon-form-radio:after {
  background-color: #03a9f4;
}
.theme-lightblue label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lightblue input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-lightblue label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lightblue input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-lightblue label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lightblue input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-lightblue label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lightblue input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-lightblue label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lightblue input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-lightblue label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lightblue input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #03a9f4;
}
.theme-lightblue label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-lightblue label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-lightblue label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lightblue label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lightblue label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-lightblue label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lightblue input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #03a9f4;
}
.theme-lightblue .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-lightblue input[type="checkbox"]:checked ~ i {
  border-color: #03a9f4;
  background-color: #03a9f4;
}
.theme-lightblue .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-lightblue input[type="radio"]:checked ~ i {
  border-color: #03a9f4;
}
.theme-lightblue .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-lightblue input[type="radio"]:checked ~ i:after {
  background-color: #03a9f4;
}
.theme-lightblue .form-checkbox .ripple-wave,
.theme-lightblue .form-radio .ripple-wave {
  background: rgba(3, 169, 244, 0.5);
}
.theme-lightblue .focus-state .label,
.theme-lightblue .focus-state .floating-label {
  color: #03a9f4;
}
.theme-lightblue .item-input-field.focus-state:after,
.theme-lightblue .input-field.focus-state:after,
.theme-lightblue .item-input-field.not-empty-state:after,
.theme-lightblue .input-field.not-empty-state:after {
  background: #03a9f4;
}
.theme-lightblue .picker-calendar-day.picker-calendar-day-today span {
  color: #03a9f4;
}
.theme-lightblue .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #03a9f4;
  color: #fff;
}
.theme-lightblue .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #03a9f4;
}
.theme-lightblue .picker-header {
  background-color: #03a9f4;
}
.theme-lightblue .modal-button {
  color: #03a9f4;
}
.theme-lightblue .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-lightblue .timeline-item-date {
  background-color: #03a9f4;
}
.theme-lightblue .timeline-year-title,
.theme-lightblue .timeline-month-title {
  background-color: #03a9f4;
}
.theme-lightblue .statusbar-overlay {
  background-color: #01579b;
}
.theme-lightblue .data-table .data-table-header-selected,
.data-table.theme-lightblue .data-table-header-selected {
  background: rgba(3, 169, 244, 0.1);
}
.theme-lightblue .data-table .data-table-title-selected,
.data-table.theme-lightblue .data-table-title-selected {
  color: #03a9f4;
}
.swiper-pagination.color-lightblue .swiper-pagination-bullet-active,
.theme-lightblue .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #03a9f4;
}
.swiper-pagination.color-lightblue .swiper-pagination-progressbar,
.theme-lightblue .swiper-pagination .swiper-pagination-progressbar {
  background-color: #03a9f4;
}
.swiper-pagination.swiper-pagination-progress.bg-lightblue {
  background-color: rgba(3, 169, 244, 0.25);
}
.swiper-button-next.color-lightblue,
.swiper-container-rtl .swiper-button-prev.color-lightblue,
.theme-lightblue .swiper-button-next,
.theme-lightblue .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2303a9f4'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-lightblue,
.swiper-container-rtl .swiper-button-next.color-lightblue,
.theme-lightblue .swiper-button-prev,
.theme-lightblue .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2303a9f4'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightblue .floating-button,
.theme-lightblue .speed-dial-buttons a,
.floating-button.theme-lightblue,
.speed-dial-buttons a.theme-lightblue {
  color: #fff;
  background: #03a9f4;
}
html:not(.watch-active-state) .theme-lightblue .floating-button:active,
html:not(.watch-active-state) .theme-lightblue .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-lightblue:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-lightblue:active,
.theme-lightblue .floating-button.active-state,
.theme-lightblue .speed-dial-buttons a.active-state,
.floating-button.theme-lightblue.active-state,
.speed-dial-buttons a.theme-lightblue.active-state {
  background: #0288d1;
}
.theme-lightblue .floating-button i,
.theme-lightblue .speed-dial-buttons a i,
.floating-button.theme-lightblue i,
.speed-dial-buttons a.theme-lightblue i {
  color: inherit;
}
.theme-lightblue .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-lightblue input[type="range"]::-webkit-slider-thumb:before {
  background-color: #03a9f4;
}
.theme-lightblue .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-lightblue input[type="range"]::-webkit-slider-thumb {
  background-color: #03a9f4;
}
.theme-lightblue .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-lightblue input[type="range"]::-ms-thumb {
  background-color: #03a9f4;
}
.theme-lightblue .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-lightblue input[type="range"]::-ms-fill-lower {
  background-color: #03a9f4;
}
.theme-lightblue .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-lightblue input[type="range"]::-moz-range-thumb {
  background-color: #03a9f4;
}
.badge.theme-lightblue {
  background-color: #03a9f4;
  color: #fff;
}
.theme-cyan .button:not(.button-fill) {
  color: #00bcd4;
}
.theme-cyan .navbar .button:not(.button-fill),
.theme-cyan.navbar .button:not(.button-fill),
.theme-cyan .toolbar .button:not(.button-fill),
.theme-cyan.toolbar .button:not(.button-fill),
.theme-cyan .subnavbar .button:not(.button-fill),
.theme-cyan.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-cyan .button.button-fill {
  background: #00bcd4;
  color: #fff;
}
html:not(.watch-active-state) .theme-cyan .button.button-fill:active,
.theme-cyan .button.button-fill.active-state {
  background: #0097a7;
}
.progressbar.theme-cyan,
.theme-cyan .progressbar {
  background-color: rgba(0, 188, 212, 0.5);
}
.progressbar.theme-cyan span,
.theme-cyan .progressbar span {
  background-color: #00bcd4;
}
.progressbar-infinite.theme-cyan,
.theme-cyan .progressbar-infinite {
  background-color: rgba(0, 188, 212, 0.5);
}
.progressbar-infinite.theme-cyan:after,
.theme-cyan .progressbar-infinite:after,
.progressbar-infinite.theme-cyan:before,
.theme-cyan .progressbar-infinite:before {
  background-color: #00bcd4;
}
.color-cyan i.icon,
i.icon.color-cyan {
  color: #00bcd4;
}
i.icon-next.color-cyan,
i.icon-next.theme-cyan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%2300bcd4'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-cyan,
i.icon-prev.theme-cyan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%2300bcd4'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-cyan,
i.icon-back.theme-cyan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%2300bcd4'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-cyan,
i.icon-forward.theme-cyan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%2300bcd4'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-cyan,
i.icon-bars.theme-cyan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%2300bcd4'%2F%3E%3C%2Fsvg%3E");
}
.theme-cyan a,
.theme-cyan .card a {
  color: #00bcd4;
}
.theme-cyan .navbar,
.navbar.theme-cyan,
.theme-cyan .toolbar,
.toolbar.theme-cyan,
.theme-cyan .subnavbar,
.subnavbar.theme-cyan,
.theme-cyan .searchbar,
.searchbar.theme-cyan {
  background-color: #00bcd4;
}
.theme-cyan .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-cyan input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(0, 188, 212, 0.5);
}
.theme-cyan .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-cyan input[type="checkbox"]:checked + .checkbox:after {
  background-color: #00bcd4;
}
.theme-cyan label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-cyan i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-cyan label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-cyan label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-cyan label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-cyan label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-cyan label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-cyan label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-cyan input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #00bcd4;
  background-color: #00bcd4;
}
.theme-cyan label.label-radio i.icon-form-radio:after,
label.label-radio.theme-cyan i.icon-form-radio:after {
  background-color: #00bcd4;
}
.theme-cyan label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-cyan input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-cyan label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-cyan input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-cyan label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-cyan input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-cyan label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-cyan input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-cyan label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-cyan input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-cyan label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-cyan input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #00bcd4;
}
.theme-cyan label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-cyan label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-cyan label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-cyan label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-cyan label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-cyan label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-cyan input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #00bcd4;
}
.theme-cyan .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-cyan input[type="checkbox"]:checked ~ i {
  border-color: #00bcd4;
  background-color: #00bcd4;
}
.theme-cyan .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-cyan input[type="radio"]:checked ~ i {
  border-color: #00bcd4;
}
.theme-cyan .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-cyan input[type="radio"]:checked ~ i:after {
  background-color: #00bcd4;
}
.theme-cyan .form-checkbox .ripple-wave,
.theme-cyan .form-radio .ripple-wave {
  background: rgba(0, 188, 212, 0.5);
}
.theme-cyan .focus-state .label,
.theme-cyan .focus-state .floating-label {
  color: #00bcd4;
}
.theme-cyan .item-input-field.focus-state:after,
.theme-cyan .input-field.focus-state:after,
.theme-cyan .item-input-field.not-empty-state:after,
.theme-cyan .input-field.not-empty-state:after {
  background: #00bcd4;
}
.theme-cyan .picker-calendar-day.picker-calendar-day-today span {
  color: #00bcd4;
}
.theme-cyan .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #00bcd4;
  color: #fff;
}
.theme-cyan .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #00bcd4;
}
.theme-cyan .picker-header {
  background-color: #00bcd4;
}
.theme-cyan .modal-button {
  color: #00bcd4;
}
.theme-cyan .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-cyan .timeline-item-date {
  background-color: #00bcd4;
}
.theme-cyan .timeline-year-title,
.theme-cyan .timeline-month-title {
  background-color: #00bcd4;
}
.theme-cyan .statusbar-overlay {
  background-color: #006064;
}
.theme-cyan .data-table .data-table-header-selected,
.data-table.theme-cyan .data-table-header-selected {
  background: rgba(0, 188, 212, 0.1);
}
.theme-cyan .data-table .data-table-title-selected,
.data-table.theme-cyan .data-table-title-selected {
  color: #00bcd4;
}
.swiper-pagination.color-cyan .swiper-pagination-bullet-active,
.theme-cyan .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #00bcd4;
}
.swiper-pagination.color-cyan .swiper-pagination-progressbar,
.theme-cyan .swiper-pagination .swiper-pagination-progressbar {
  background-color: #00bcd4;
}
.swiper-pagination.swiper-pagination-progress.bg-cyan {
  background-color: rgba(0, 188, 212, 0.25);
}
.swiper-button-next.color-cyan,
.swiper-container-rtl .swiper-button-prev.color-cyan,
.theme-cyan .swiper-button-next,
.theme-cyan .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2300bcd4'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-cyan,
.swiper-container-rtl .swiper-button-next.color-cyan,
.theme-cyan .swiper-button-prev,
.theme-cyan .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2300bcd4'%2F%3E%3C%2Fsvg%3E");
}
.theme-cyan .floating-button,
.theme-cyan .speed-dial-buttons a,
.floating-button.theme-cyan,
.speed-dial-buttons a.theme-cyan {
  color: #fff;
  background: #00bcd4;
}
html:not(.watch-active-state) .theme-cyan .floating-button:active,
html:not(.watch-active-state) .theme-cyan .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-cyan:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-cyan:active,
.theme-cyan .floating-button.active-state,
.theme-cyan .speed-dial-buttons a.active-state,
.floating-button.theme-cyan.active-state,
.speed-dial-buttons a.theme-cyan.active-state {
  background: #0097a7;
}
.theme-cyan .floating-button i,
.theme-cyan .speed-dial-buttons a i,
.floating-button.theme-cyan i,
.speed-dial-buttons a.theme-cyan i {
  color: inherit;
}
.theme-cyan .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-cyan input[type="range"]::-webkit-slider-thumb:before {
  background-color: #00bcd4;
}
.theme-cyan .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-cyan input[type="range"]::-webkit-slider-thumb {
  background-color: #00bcd4;
}
.theme-cyan .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-cyan input[type="range"]::-ms-thumb {
  background-color: #00bcd4;
}
.theme-cyan .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-cyan input[type="range"]::-ms-fill-lower {
  background-color: #00bcd4;
}
.theme-cyan .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-cyan input[type="range"]::-moz-range-thumb {
  background-color: #00bcd4;
}
.badge.theme-cyan {
  background-color: #00bcd4;
  color: #fff;
}
.theme-teal .button:not(.button-fill) {
  color: #009688;
}
.theme-teal .navbar .button:not(.button-fill),
.theme-teal.navbar .button:not(.button-fill),
.theme-teal .toolbar .button:not(.button-fill),
.theme-teal.toolbar .button:not(.button-fill),
.theme-teal .subnavbar .button:not(.button-fill),
.theme-teal.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-teal .button.button-fill {
  background: #009688;
  color: #fff;
}
html:not(.watch-active-state) .theme-teal .button.button-fill:active,
.theme-teal .button.button-fill.active-state {
  background: #00897b;
}
.progressbar.theme-teal,
.theme-teal .progressbar {
  background-color: rgba(0, 150, 136, 0.5);
}
.progressbar.theme-teal span,
.theme-teal .progressbar span {
  background-color: #009688;
}
.progressbar-infinite.theme-teal,
.theme-teal .progressbar-infinite {
  background-color: rgba(0, 150, 136, 0.5);
}
.progressbar-infinite.theme-teal:after,
.theme-teal .progressbar-infinite:after,
.progressbar-infinite.theme-teal:before,
.theme-teal .progressbar-infinite:before {
  background-color: #009688;
}
.color-teal i.icon,
i.icon.color-teal {
  color: #009688;
}
i.icon-next.color-teal,
i.icon-next.theme-teal {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23009688'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-teal,
i.icon-prev.theme-teal {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23009688'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-teal,
i.icon-back.theme-teal {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23009688'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-teal,
i.icon-forward.theme-teal {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23009688'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-teal,
i.icon-bars.theme-teal {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23009688'%2F%3E%3C%2Fsvg%3E");
}
.theme-teal a,
.theme-teal .card a {
  color: #009688;
}
.theme-teal .navbar,
.navbar.theme-teal,
.theme-teal .toolbar,
.toolbar.theme-teal,
.theme-teal .subnavbar,
.subnavbar.theme-teal,
.theme-teal .searchbar,
.searchbar.theme-teal {
  background-color: #009688;
}
.theme-teal .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-teal input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(0, 150, 136, 0.5);
}
.theme-teal .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-teal input[type="checkbox"]:checked + .checkbox:after {
  background-color: #009688;
}
.theme-teal label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-teal i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-teal label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-teal label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-teal label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-teal label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-teal label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-teal label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-teal input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #009688;
  background-color: #009688;
}
.theme-teal label.label-radio i.icon-form-radio:after,
label.label-radio.theme-teal i.icon-form-radio:after {
  background-color: #009688;
}
.theme-teal label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-teal input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-teal label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-teal input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-teal label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-teal input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-teal label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-teal input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-teal label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-teal input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-teal label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-teal input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #009688;
}
.theme-teal label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-teal input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-teal label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-teal input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-teal label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-teal input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-teal label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-teal input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-teal label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-teal input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-teal label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-teal input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #009688;
}
.theme-teal .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-teal input[type="checkbox"]:checked ~ i {
  border-color: #009688;
  background-color: #009688;
}
.theme-teal .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-teal input[type="radio"]:checked ~ i {
  border-color: #009688;
}
.theme-teal .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-teal input[type="radio"]:checked ~ i:after {
  background-color: #009688;
}
.theme-teal .form-checkbox .ripple-wave,
.theme-teal .form-radio .ripple-wave {
  background: rgba(0, 150, 136, 0.5);
}
.theme-teal .focus-state .label,
.theme-teal .focus-state .floating-label {
  color: #009688;
}
.theme-teal .item-input-field.focus-state:after,
.theme-teal .input-field.focus-state:after,
.theme-teal .item-input-field.not-empty-state:after,
.theme-teal .input-field.not-empty-state:after {
  background: #009688;
}
.theme-teal .picker-calendar-day.picker-calendar-day-today span {
  color: #009688;
}
.theme-teal .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #009688;
  color: #fff;
}
.theme-teal .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #009688;
}
.theme-teal .picker-header {
  background-color: #009688;
}
.theme-teal .modal-button {
  color: #009688;
}
.theme-teal .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-teal .timeline-item-date {
  background-color: #009688;
}
.theme-teal .timeline-year-title,
.theme-teal .timeline-month-title {
  background-color: #009688;
}
.theme-teal .statusbar-overlay {
  background-color: #004d40;
}
.theme-teal .data-table .data-table-header-selected,
.data-table.theme-teal .data-table-header-selected {
  background: rgba(0, 150, 136, 0.1);
}
.theme-teal .data-table .data-table-title-selected,
.data-table.theme-teal .data-table-title-selected {
  color: #009688;
}
.swiper-pagination.color-teal .swiper-pagination-bullet-active,
.theme-teal .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #009688;
}
.swiper-pagination.color-teal .swiper-pagination-progressbar,
.theme-teal .swiper-pagination .swiper-pagination-progressbar {
  background-color: #009688;
}
.swiper-pagination.swiper-pagination-progress.bg-teal {
  background-color: rgba(0, 150, 136, 0.25);
}
.swiper-button-next.color-teal,
.swiper-container-rtl .swiper-button-prev.color-teal,
.theme-teal .swiper-button-next,
.theme-teal .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23009688'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-teal,
.swiper-container-rtl .swiper-button-next.color-teal,
.theme-teal .swiper-button-prev,
.theme-teal .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23009688'%2F%3E%3C%2Fsvg%3E");
}
.theme-teal .floating-button,
.theme-teal .speed-dial-buttons a,
.floating-button.theme-teal,
.speed-dial-buttons a.theme-teal {
  color: #fff;
  background: #009688;
}
html:not(.watch-active-state) .theme-teal .floating-button:active,
html:not(.watch-active-state) .theme-teal .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-teal:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-teal:active,
.theme-teal .floating-button.active-state,
.theme-teal .speed-dial-buttons a.active-state,
.floating-button.theme-teal.active-state,
.speed-dial-buttons a.theme-teal.active-state {
  background: #00897b;
}
.theme-teal .floating-button i,
.theme-teal .speed-dial-buttons a i,
.floating-button.theme-teal i,
.speed-dial-buttons a.theme-teal i {
  color: inherit;
}
.theme-teal .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-teal input[type="range"]::-webkit-slider-thumb:before {
  background-color: #009688;
}
.theme-teal .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-teal input[type="range"]::-webkit-slider-thumb {
  background-color: #009688;
}
.theme-teal .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-teal input[type="range"]::-ms-thumb {
  background-color: #009688;
}
.theme-teal .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-teal input[type="range"]::-ms-fill-lower {
  background-color: #009688;
}
.theme-teal .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-teal input[type="range"]::-moz-range-thumb {
  background-color: #009688;
}
.badge.theme-teal {
  background-color: #009688;
  color: #fff;
}
.theme-green .button:not(.button-fill) {
  color: #4caf50;
}
.theme-green .navbar .button:not(.button-fill),
.theme-green.navbar .button:not(.button-fill),
.theme-green .toolbar .button:not(.button-fill),
.theme-green.toolbar .button:not(.button-fill),
.theme-green .subnavbar .button:not(.button-fill),
.theme-green.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-green .button.button-fill {
  background: #4caf50;
  color: #fff;
}
html:not(.watch-active-state) .theme-green .button.button-fill:active,
.theme-green .button.button-fill.active-state {
  background: #388e3c;
}
.progressbar.theme-green,
.theme-green .progressbar {
  background-color: rgba(76, 175, 80, 0.5);
}
.progressbar.theme-green span,
.theme-green .progressbar span {
  background-color: #4caf50;
}
.progressbar-infinite.theme-green,
.theme-green .progressbar-infinite {
  background-color: rgba(76, 175, 80, 0.5);
}
.progressbar-infinite.theme-green:after,
.theme-green .progressbar-infinite:after,
.progressbar-infinite.theme-green:before,
.theme-green .progressbar-infinite:before {
  background-color: #4caf50;
}
.color-green i.icon,
i.icon.color-green {
  color: #4caf50;
}
i.icon-next.color-green,
i.icon-next.theme-green {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%234caf50'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-green,
i.icon-prev.theme-green {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%234caf50'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-green,
i.icon-back.theme-green {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%234caf50'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-green,
i.icon-forward.theme-green {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%234caf50'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-green,
i.icon-bars.theme-green {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%234caf50'%2F%3E%3C%2Fsvg%3E");
}
.theme-green a,
.theme-green .card a {
  color: #4caf50;
}
.theme-green .navbar,
.navbar.theme-green,
.theme-green .toolbar,
.toolbar.theme-green,
.theme-green .subnavbar,
.subnavbar.theme-green,
.theme-green .searchbar,
.searchbar.theme-green {
  background-color: #4caf50;
}
.theme-green .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-green input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(76, 175, 80, 0.5);
}
.theme-green .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-green input[type="checkbox"]:checked + .checkbox:after {
  background-color: #4caf50;
}
.theme-green label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-green i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-green label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-green label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-green label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-green label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-green label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-green label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-green input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #4caf50;
  background-color: #4caf50;
}
.theme-green label.label-radio i.icon-form-radio:after,
label.label-radio.theme-green i.icon-form-radio:after {
  background-color: #4caf50;
}
.theme-green label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-green input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-green label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-green input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-green label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-green input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-green label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-green input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-green label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-green input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-green label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-green input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #4caf50;
}
.theme-green label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-green input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-green label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-green input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-green label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-green input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-green label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-green input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-green label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-green input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-green label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-green input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #4caf50;
}
.theme-green .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-green input[type="checkbox"]:checked ~ i {
  border-color: #4caf50;
  background-color: #4caf50;
}
.theme-green .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-green input[type="radio"]:checked ~ i {
  border-color: #4caf50;
}
.theme-green .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-green input[type="radio"]:checked ~ i:after {
  background-color: #4caf50;
}
.theme-green .form-checkbox .ripple-wave,
.theme-green .form-radio .ripple-wave {
  background: rgba(76, 175, 80, 0.5);
}
.theme-green .focus-state .label,
.theme-green .focus-state .floating-label {
  color: #4caf50;
}
.theme-green .item-input-field.focus-state:after,
.theme-green .input-field.focus-state:after,
.theme-green .item-input-field.not-empty-state:after,
.theme-green .input-field.not-empty-state:after {
  background: #4caf50;
}
.theme-green .picker-calendar-day.picker-calendar-day-today span {
  color: #4caf50;
}
.theme-green .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #4caf50;
  color: #fff;
}
.theme-green .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #4caf50;
}
.theme-green .picker-header {
  background-color: #4caf50;
}
.theme-green .modal-button {
  color: #4caf50;
}
.theme-green .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-green .timeline-item-date {
  background-color: #4caf50;
}
.theme-green .timeline-year-title,
.theme-green .timeline-month-title {
  background-color: #4caf50;
}
.theme-green .statusbar-overlay {
  background-color: #1b5e20;
}
.theme-green .data-table .data-table-header-selected,
.data-table.theme-green .data-table-header-selected {
  background: rgba(76, 175, 80, 0.1);
}
.theme-green .data-table .data-table-title-selected,
.data-table.theme-green .data-table-title-selected {
  color: #4caf50;
}
.swiper-pagination.color-green .swiper-pagination-bullet-active,
.theme-green .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4caf50;
}
.swiper-pagination.color-green .swiper-pagination-progressbar,
.theme-green .swiper-pagination .swiper-pagination-progressbar {
  background-color: #4caf50;
}
.swiper-pagination.swiper-pagination-progress.bg-green {
  background-color: rgba(76, 175, 80, 0.25);
}
.swiper-button-next.color-green,
.swiper-container-rtl .swiper-button-prev.color-green,
.theme-green .swiper-button-next,
.theme-green .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234caf50'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-green,
.swiper-container-rtl .swiper-button-next.color-green,
.theme-green .swiper-button-prev,
.theme-green .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234caf50'%2F%3E%3C%2Fsvg%3E");
}
.theme-green .floating-button,
.theme-green .speed-dial-buttons a,
.floating-button.theme-green,
.speed-dial-buttons a.theme-green {
  color: #fff;
  background: #4caf50;
}
html:not(.watch-active-state) .theme-green .floating-button:active,
html:not(.watch-active-state) .theme-green .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-green:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-green:active,
.theme-green .floating-button.active-state,
.theme-green .speed-dial-buttons a.active-state,
.floating-button.theme-green.active-state,
.speed-dial-buttons a.theme-green.active-state {
  background: #388e3c;
}
.theme-green .floating-button i,
.theme-green .speed-dial-buttons a i,
.floating-button.theme-green i,
.speed-dial-buttons a.theme-green i {
  color: inherit;
}
.theme-green .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-green input[type="range"]::-webkit-slider-thumb:before {
  background-color: #4caf50;
}
.theme-green .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-green input[type="range"]::-webkit-slider-thumb {
  background-color: #4caf50;
}
.theme-green .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-green input[type="range"]::-ms-thumb {
  background-color: #4caf50;
}
.theme-green .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-green input[type="range"]::-ms-fill-lower {
  background-color: #4caf50;
}
.theme-green .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-green input[type="range"]::-moz-range-thumb {
  background-color: #4caf50;
}
.badge.theme-green {
  background-color: #4caf50;
  color: #fff;
}
.theme-lightgreen .button:not(.button-fill) {
  color: #8bc34a;
}
.theme-lightgreen .navbar .button:not(.button-fill),
.theme-lightgreen.navbar .button:not(.button-fill),
.theme-lightgreen .toolbar .button:not(.button-fill),
.theme-lightgreen.toolbar .button:not(.button-fill),
.theme-lightgreen .subnavbar .button:not(.button-fill),
.theme-lightgreen.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-lightgreen .button.button-fill {
  background: #8bc34a;
  color: #fff;
}
html:not(.watch-active-state) .theme-lightgreen .button.button-fill:active,
.theme-lightgreen .button.button-fill.active-state {
  background: #689f38;
}
.progressbar.theme-lightgreen,
.theme-lightgreen .progressbar {
  background-color: rgba(139, 195, 74, 0.5);
}
.progressbar.theme-lightgreen span,
.theme-lightgreen .progressbar span {
  background-color: #8bc34a;
}
.progressbar-infinite.theme-lightgreen,
.theme-lightgreen .progressbar-infinite {
  background-color: rgba(139, 195, 74, 0.5);
}
.progressbar-infinite.theme-lightgreen:after,
.theme-lightgreen .progressbar-infinite:after,
.progressbar-infinite.theme-lightgreen:before,
.theme-lightgreen .progressbar-infinite:before {
  background-color: #8bc34a;
}
.color-lightgreen i.icon,
i.icon.color-lightgreen {
  color: #8bc34a;
}
i.icon-next.color-lightgreen,
i.icon-next.theme-lightgreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%238bc34a'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-lightgreen,
i.icon-prev.theme-lightgreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%238bc34a'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-lightgreen,
i.icon-back.theme-lightgreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%238bc34a'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-lightgreen,
i.icon-forward.theme-lightgreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%238bc34a'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-lightgreen,
i.icon-bars.theme-lightgreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%238bc34a'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightgreen a,
.theme-lightgreen .card a {
  color: #8bc34a;
}
.theme-lightgreen .navbar,
.navbar.theme-lightgreen,
.theme-lightgreen .toolbar,
.toolbar.theme-lightgreen,
.theme-lightgreen .subnavbar,
.subnavbar.theme-lightgreen,
.theme-lightgreen .searchbar,
.searchbar.theme-lightgreen {
  background-color: #8bc34a;
}
.theme-lightgreen .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-lightgreen input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(139, 195, 74, 0.5);
}
.theme-lightgreen .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-lightgreen input[type="checkbox"]:checked + .checkbox:after {
  background-color: #8bc34a;
}
.theme-lightgreen label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-lightgreen i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightgreen label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-lightgreen label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-lightgreen label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lightgreen label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lightgreen label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-lightgreen label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lightgreen input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #8bc34a;
  background-color: #8bc34a;
}
.theme-lightgreen label.label-radio i.icon-form-radio:after,
label.label-radio.theme-lightgreen i.icon-form-radio:after {
  background-color: #8bc34a;
}
.theme-lightgreen label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-lightgreen label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-lightgreen label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-lightgreen label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-lightgreen label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-lightgreen label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lightgreen input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #8bc34a;
}
.theme-lightgreen label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-lightgreen label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-lightgreen label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lightgreen label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lightgreen label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-lightgreen label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lightgreen input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #8bc34a;
}
.theme-lightgreen .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-lightgreen input[type="checkbox"]:checked ~ i {
  border-color: #8bc34a;
  background-color: #8bc34a;
}
.theme-lightgreen .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-lightgreen input[type="radio"]:checked ~ i {
  border-color: #8bc34a;
}
.theme-lightgreen .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-lightgreen input[type="radio"]:checked ~ i:after {
  background-color: #8bc34a;
}
.theme-lightgreen .form-checkbox .ripple-wave,
.theme-lightgreen .form-radio .ripple-wave {
  background: rgba(139, 195, 74, 0.5);
}
.theme-lightgreen .focus-state .label,
.theme-lightgreen .focus-state .floating-label {
  color: #8bc34a;
}
.theme-lightgreen .item-input-field.focus-state:after,
.theme-lightgreen .input-field.focus-state:after,
.theme-lightgreen .item-input-field.not-empty-state:after,
.theme-lightgreen .input-field.not-empty-state:after {
  background: #8bc34a;
}
.theme-lightgreen .picker-calendar-day.picker-calendar-day-today span {
  color: #8bc34a;
}
.theme-lightgreen .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #8bc34a;
  color: #fff;
}
.theme-lightgreen .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #8bc34a;
}
.theme-lightgreen .picker-header {
  background-color: #8bc34a;
}
.theme-lightgreen .modal-button {
  color: #8bc34a;
}
.theme-lightgreen .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-lightgreen .timeline-item-date {
  background-color: #8bc34a;
}
.theme-lightgreen .timeline-year-title,
.theme-lightgreen .timeline-month-title {
  background-color: #8bc34a;
}
.theme-lightgreen .statusbar-overlay {
  background-color: #33691e;
}
.theme-lightgreen .data-table .data-table-header-selected,
.data-table.theme-lightgreen .data-table-header-selected {
  background: rgba(139, 195, 74, 0.1);
}
.theme-lightgreen .data-table .data-table-title-selected,
.data-table.theme-lightgreen .data-table-title-selected {
  color: #8bc34a;
}
.swiper-pagination.color-lightgreen .swiper-pagination-bullet-active,
.theme-lightgreen .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #8bc34a;
}
.swiper-pagination.color-lightgreen .swiper-pagination-progressbar,
.theme-lightgreen .swiper-pagination .swiper-pagination-progressbar {
  background-color: #8bc34a;
}
.swiper-pagination.swiper-pagination-progress.bg-lightgreen {
  background-color: rgba(139, 195, 74, 0.25);
}
.swiper-button-next.color-lightgreen,
.swiper-container-rtl .swiper-button-prev.color-lightgreen,
.theme-lightgreen .swiper-button-next,
.theme-lightgreen .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%238bc34a'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-lightgreen,
.swiper-container-rtl .swiper-button-next.color-lightgreen,
.theme-lightgreen .swiper-button-prev,
.theme-lightgreen .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%238bc34a'%2F%3E%3C%2Fsvg%3E");
}
.theme-lightgreen .floating-button,
.theme-lightgreen .speed-dial-buttons a,
.floating-button.theme-lightgreen,
.speed-dial-buttons a.theme-lightgreen {
  color: #fff;
  background: #8bc34a;
}
html:not(.watch-active-state) .theme-lightgreen .floating-button:active,
html:not(.watch-active-state) .theme-lightgreen .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-lightgreen:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-lightgreen:active,
.theme-lightgreen .floating-button.active-state,
.theme-lightgreen .speed-dial-buttons a.active-state,
.floating-button.theme-lightgreen.active-state,
.speed-dial-buttons a.theme-lightgreen.active-state {
  background: #689f38;
}
.theme-lightgreen .floating-button i,
.theme-lightgreen .speed-dial-buttons a i,
.floating-button.theme-lightgreen i,
.speed-dial-buttons a.theme-lightgreen i {
  color: inherit;
}
.theme-lightgreen .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-lightgreen input[type="range"]::-webkit-slider-thumb:before {
  background-color: #8bc34a;
}
.theme-lightgreen .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-lightgreen input[type="range"]::-webkit-slider-thumb {
  background-color: #8bc34a;
}
.theme-lightgreen .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-lightgreen input[type="range"]::-ms-thumb {
  background-color: #8bc34a;
}
.theme-lightgreen .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-lightgreen input[type="range"]::-ms-fill-lower {
  background-color: #8bc34a;
}
.theme-lightgreen .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-lightgreen input[type="range"]::-moz-range-thumb {
  background-color: #8bc34a;
}
.badge.theme-lightgreen {
  background-color: #8bc34a;
  color: #fff;
}
.theme-lime .button:not(.button-fill) {
  color: #cddc39;
}
.theme-lime .navbar .button:not(.button-fill),
.theme-lime.navbar .button:not(.button-fill),
.theme-lime .toolbar .button:not(.button-fill),
.theme-lime.toolbar .button:not(.button-fill),
.theme-lime .subnavbar .button:not(.button-fill),
.theme-lime.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-lime .button.button-fill {
  background: #cddc39;
  color: #fff;
}
html:not(.watch-active-state) .theme-lime .button.button-fill:active,
.theme-lime .button.button-fill.active-state {
  background: #afb42b;
}
.progressbar.theme-lime,
.theme-lime .progressbar {
  background-color: rgba(205, 220, 57, 0.5);
}
.progressbar.theme-lime span,
.theme-lime .progressbar span {
  background-color: #cddc39;
}
.progressbar-infinite.theme-lime,
.theme-lime .progressbar-infinite {
  background-color: rgba(205, 220, 57, 0.5);
}
.progressbar-infinite.theme-lime:after,
.theme-lime .progressbar-infinite:after,
.progressbar-infinite.theme-lime:before,
.theme-lime .progressbar-infinite:before {
  background-color: #cddc39;
}
.color-lime i.icon,
i.icon.color-lime {
  color: #cddc39;
}
i.icon-next.color-lime,
i.icon-next.theme-lime {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23cddc39'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-lime,
i.icon-prev.theme-lime {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23cddc39'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-lime,
i.icon-back.theme-lime {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23cddc39'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-lime,
i.icon-forward.theme-lime {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23cddc39'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-lime,
i.icon-bars.theme-lime {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23cddc39'%2F%3E%3C%2Fsvg%3E");
}
.theme-lime a,
.theme-lime .card a {
  color: #cddc39;
}
.theme-lime .navbar,
.navbar.theme-lime,
.theme-lime .toolbar,
.toolbar.theme-lime,
.theme-lime .subnavbar,
.subnavbar.theme-lime,
.theme-lime .searchbar,
.searchbar.theme-lime {
  background-color: #cddc39;
}
.theme-lime .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-lime input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(205, 220, 57, 0.5);
}
.theme-lime .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-lime input[type="checkbox"]:checked + .checkbox:after {
  background-color: #cddc39;
}
.theme-lime label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-lime i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-lime label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-lime label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-lime label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lime label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-lime label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-lime label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-lime input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #cddc39;
  background-color: #cddc39;
}
.theme-lime label.label-radio i.icon-form-radio:after,
label.label-radio.theme-lime i.icon-form-radio:after {
  background-color: #cddc39;
}
.theme-lime label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lime input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-lime label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-lime input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-lime label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lime input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-lime label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-lime input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-lime label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lime input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-lime label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-lime input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #cddc39;
}
.theme-lime label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lime input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-lime label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-lime input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-lime label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lime input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lime label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-lime input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-lime label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lime input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-lime label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-lime input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #cddc39;
}
.theme-lime .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-lime input[type="checkbox"]:checked ~ i {
  border-color: #cddc39;
  background-color: #cddc39;
}
.theme-lime .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-lime input[type="radio"]:checked ~ i {
  border-color: #cddc39;
}
.theme-lime .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-lime input[type="radio"]:checked ~ i:after {
  background-color: #cddc39;
}
.theme-lime .form-checkbox .ripple-wave,
.theme-lime .form-radio .ripple-wave {
  background: rgba(205, 220, 57, 0.5);
}
.theme-lime .focus-state .label,
.theme-lime .focus-state .floating-label {
  color: #cddc39;
}
.theme-lime .item-input-field.focus-state:after,
.theme-lime .input-field.focus-state:after,
.theme-lime .item-input-field.not-empty-state:after,
.theme-lime .input-field.not-empty-state:after {
  background: #cddc39;
}
.theme-lime .picker-calendar-day.picker-calendar-day-today span {
  color: #cddc39;
}
.theme-lime .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #cddc39;
  color: #fff;
}
.theme-lime .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #cddc39;
}
.theme-lime .picker-header {
  background-color: #cddc39;
}
.theme-lime .modal-button {
  color: #cddc39;
}
.theme-lime .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-lime .timeline-item-date {
  background-color: #cddc39;
}
.theme-lime .timeline-year-title,
.theme-lime .timeline-month-title {
  background-color: #cddc39;
}
.theme-lime .statusbar-overlay {
  background-color: #827717;
}
.theme-lime .data-table .data-table-header-selected,
.data-table.theme-lime .data-table-header-selected {
  background: rgba(205, 220, 57, 0.1);
}
.theme-lime .data-table .data-table-title-selected,
.data-table.theme-lime .data-table-title-selected {
  color: #cddc39;
}
.swiper-pagination.color-lime .swiper-pagination-bullet-active,
.theme-lime .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #cddc39;
}
.swiper-pagination.color-lime .swiper-pagination-progressbar,
.theme-lime .swiper-pagination .swiper-pagination-progressbar {
  background-color: #cddc39;
}
.swiper-pagination.swiper-pagination-progress.bg-lime {
  background-color: rgba(205, 220, 57, 0.25);
}
.swiper-button-next.color-lime,
.swiper-container-rtl .swiper-button-prev.color-lime,
.theme-lime .swiper-button-next,
.theme-lime .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23cddc39'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-lime,
.swiper-container-rtl .swiper-button-next.color-lime,
.theme-lime .swiper-button-prev,
.theme-lime .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23cddc39'%2F%3E%3C%2Fsvg%3E");
}
.theme-lime .floating-button,
.theme-lime .speed-dial-buttons a,
.floating-button.theme-lime,
.speed-dial-buttons a.theme-lime {
  color: #fff;
  background: #cddc39;
}
html:not(.watch-active-state) .theme-lime .floating-button:active,
html:not(.watch-active-state) .theme-lime .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-lime:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-lime:active,
.theme-lime .floating-button.active-state,
.theme-lime .speed-dial-buttons a.active-state,
.floating-button.theme-lime.active-state,
.speed-dial-buttons a.theme-lime.active-state {
  background: #afb42b;
}
.theme-lime .floating-button i,
.theme-lime .speed-dial-buttons a i,
.floating-button.theme-lime i,
.speed-dial-buttons a.theme-lime i {
  color: inherit;
}
.theme-lime .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-lime input[type="range"]::-webkit-slider-thumb:before {
  background-color: #cddc39;
}
.theme-lime .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-lime input[type="range"]::-webkit-slider-thumb {
  background-color: #cddc39;
}
.theme-lime .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-lime input[type="range"]::-ms-thumb {
  background-color: #cddc39;
}
.theme-lime .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-lime input[type="range"]::-ms-fill-lower {
  background-color: #cddc39;
}
.theme-lime .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-lime input[type="range"]::-moz-range-thumb {
  background-color: #cddc39;
}
.badge.theme-lime {
  background-color: #cddc39;
  color: #fff;
}
.theme-yellow .button:not(.button-fill) {
  color: #ffeb3b;
}
.theme-yellow .navbar .button:not(.button-fill),
.theme-yellow.navbar .button:not(.button-fill),
.theme-yellow .toolbar .button:not(.button-fill),
.theme-yellow.toolbar .button:not(.button-fill),
.theme-yellow .subnavbar .button:not(.button-fill),
.theme-yellow.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-yellow .button.button-fill {
  background: #ffeb3b;
  color: #fff;
}
html:not(.watch-active-state) .theme-yellow .button.button-fill:active,
.theme-yellow .button.button-fill.active-state {
  background: #fbc02d;
}
.progressbar.theme-yellow,
.theme-yellow .progressbar {
  background-color: rgba(255, 235, 59, 0.5);
}
.progressbar.theme-yellow span,
.theme-yellow .progressbar span {
  background-color: #ffeb3b;
}
.progressbar-infinite.theme-yellow,
.theme-yellow .progressbar-infinite {
  background-color: rgba(255, 235, 59, 0.5);
}
.progressbar-infinite.theme-yellow:after,
.theme-yellow .progressbar-infinite:after,
.progressbar-infinite.theme-yellow:before,
.theme-yellow .progressbar-infinite:before {
  background-color: #ffeb3b;
}
.color-yellow i.icon,
i.icon.color-yellow {
  color: #ffeb3b;
}
i.icon-next.color-yellow,
i.icon-next.theme-yellow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffeb3b'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-yellow,
i.icon-prev.theme-yellow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffeb3b'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-yellow,
i.icon-back.theme-yellow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23ffeb3b'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-yellow,
i.icon-forward.theme-yellow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23ffeb3b'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-yellow,
i.icon-bars.theme-yellow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23ffeb3b'%2F%3E%3C%2Fsvg%3E");
}
.theme-yellow a,
.theme-yellow .card a {
  color: #ffeb3b;
}
.theme-yellow .navbar,
.navbar.theme-yellow,
.theme-yellow .toolbar,
.toolbar.theme-yellow,
.theme-yellow .subnavbar,
.subnavbar.theme-yellow,
.theme-yellow .searchbar,
.searchbar.theme-yellow {
  background-color: #ffeb3b;
}
.theme-yellow .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-yellow input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(255, 235, 59, 0.5);
}
.theme-yellow .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-yellow input[type="checkbox"]:checked + .checkbox:after {
  background-color: #ffeb3b;
}
.theme-yellow label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-yellow i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-yellow label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-yellow label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-yellow label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-yellow label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-yellow label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-yellow label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-yellow input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #ffeb3b;
  background-color: #ffeb3b;
}
.theme-yellow label.label-radio i.icon-form-radio:after,
label.label-radio.theme-yellow i.icon-form-radio:after {
  background-color: #ffeb3b;
}
.theme-yellow label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-yellow input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-yellow label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-yellow input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-yellow label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-yellow input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-yellow label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-yellow input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-yellow label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-yellow input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-yellow label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-yellow input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #ffeb3b;
}
.theme-yellow label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-yellow label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-yellow label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-yellow label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-yellow label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-yellow label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-yellow input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #ffeb3b;
}
.theme-yellow .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-yellow input[type="checkbox"]:checked ~ i {
  border-color: #ffeb3b;
  background-color: #ffeb3b;
}
.theme-yellow .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-yellow input[type="radio"]:checked ~ i {
  border-color: #ffeb3b;
}
.theme-yellow .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-yellow input[type="radio"]:checked ~ i:after {
  background-color: #ffeb3b;
}
.theme-yellow .form-checkbox .ripple-wave,
.theme-yellow .form-radio .ripple-wave {
  background: rgba(255, 235, 59, 0.5);
}
.theme-yellow .focus-state .label,
.theme-yellow .focus-state .floating-label {
  color: #ffeb3b;
}
.theme-yellow .item-input-field.focus-state:after,
.theme-yellow .input-field.focus-state:after,
.theme-yellow .item-input-field.not-empty-state:after,
.theme-yellow .input-field.not-empty-state:after {
  background: #ffeb3b;
}
.theme-yellow .picker-calendar-day.picker-calendar-day-today span {
  color: #ffeb3b;
}
.theme-yellow .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #ffeb3b;
  color: #fff;
}
.theme-yellow .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #ffeb3b;
}
.theme-yellow .picker-header {
  background-color: #ffeb3b;
}
.theme-yellow .modal-button {
  color: #ffeb3b;
}
.theme-yellow .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-yellow .timeline-item-date {
  background-color: #ffeb3b;
}
.theme-yellow .timeline-year-title,
.theme-yellow .timeline-month-title {
  background-color: #ffeb3b;
}
.theme-yellow .statusbar-overlay {
  background-color: #f57f17;
}
.theme-yellow .data-table .data-table-header-selected,
.data-table.theme-yellow .data-table-header-selected {
  background: rgba(255, 235, 59, 0.1);
}
.theme-yellow .data-table .data-table-title-selected,
.data-table.theme-yellow .data-table-title-selected {
  color: #ffeb3b;
}
.swiper-pagination.color-yellow .swiper-pagination-bullet-active,
.theme-yellow .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffeb3b;
}
.swiper-pagination.color-yellow .swiper-pagination-progressbar,
.theme-yellow .swiper-pagination .swiper-pagination-progressbar {
  background-color: #ffeb3b;
}
.swiper-pagination.swiper-pagination-progress.bg-yellow {
  background-color: rgba(255, 235, 59, 0.25);
}
.swiper-button-next.color-yellow,
.swiper-container-rtl .swiper-button-prev.color-yellow,
.theme-yellow .swiper-button-next,
.theme-yellow .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffeb3b'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-yellow,
.swiper-container-rtl .swiper-button-next.color-yellow,
.theme-yellow .swiper-button-prev,
.theme-yellow .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffeb3b'%2F%3E%3C%2Fsvg%3E");
}
.theme-yellow .floating-button,
.theme-yellow .speed-dial-buttons a,
.floating-button.theme-yellow,
.speed-dial-buttons a.theme-yellow {
  color: #fff;
  background: #ffeb3b;
}
html:not(.watch-active-state) .theme-yellow .floating-button:active,
html:not(.watch-active-state) .theme-yellow .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-yellow:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-yellow:active,
.theme-yellow .floating-button.active-state,
.theme-yellow .speed-dial-buttons a.active-state,
.floating-button.theme-yellow.active-state,
.speed-dial-buttons a.theme-yellow.active-state {
  background: #fbc02d;
}
.theme-yellow .floating-button i,
.theme-yellow .speed-dial-buttons a i,
.floating-button.theme-yellow i,
.speed-dial-buttons a.theme-yellow i {
  color: inherit;
}
.theme-yellow .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-yellow input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffeb3b;
}
.theme-yellow .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-yellow input[type="range"]::-webkit-slider-thumb {
  background-color: #ffeb3b;
}
.theme-yellow .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-yellow input[type="range"]::-ms-thumb {
  background-color: #ffeb3b;
}
.theme-yellow .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-yellow input[type="range"]::-ms-fill-lower {
  background-color: #ffeb3b;
}
.theme-yellow .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-yellow input[type="range"]::-moz-range-thumb {
  background-color: #ffeb3b;
}
.badge.theme-yellow {
  background-color: #ffeb3b;
  color: #fff;
}
.theme-amber .button:not(.button-fill) {
  color: #ffc107;
}
.theme-amber .navbar .button:not(.button-fill),
.theme-amber.navbar .button:not(.button-fill),
.theme-amber .toolbar .button:not(.button-fill),
.theme-amber.toolbar .button:not(.button-fill),
.theme-amber .subnavbar .button:not(.button-fill),
.theme-amber.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-amber .button.button-fill {
  background: #ffc107;
  color: #fff;
}
html:not(.watch-active-state) .theme-amber .button.button-fill:active,
.theme-amber .button.button-fill.active-state {
  background: #ffa000;
}
.progressbar.theme-amber,
.theme-amber .progressbar {
  background-color: rgba(255, 193, 7, 0.5);
}
.progressbar.theme-amber span,
.theme-amber .progressbar span {
  background-color: #ffc107;
}
.progressbar-infinite.theme-amber,
.theme-amber .progressbar-infinite {
  background-color: rgba(255, 193, 7, 0.5);
}
.progressbar-infinite.theme-amber:after,
.theme-amber .progressbar-infinite:after,
.progressbar-infinite.theme-amber:before,
.theme-amber .progressbar-infinite:before {
  background-color: #ffc107;
}
.color-amber i.icon,
i.icon.color-amber {
  color: #ffc107;
}
i.icon-next.color-amber,
i.icon-next.theme-amber {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffc107'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-amber,
i.icon-prev.theme-amber {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffc107'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-amber,
i.icon-back.theme-amber {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23ffc107'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-amber,
i.icon-forward.theme-amber {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23ffc107'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-amber,
i.icon-bars.theme-amber {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23ffc107'%2F%3E%3C%2Fsvg%3E");
}
.theme-amber a,
.theme-amber .card a {
  color: #ffc107;
}
.theme-amber .navbar,
.navbar.theme-amber,
.theme-amber .toolbar,
.toolbar.theme-amber,
.theme-amber .subnavbar,
.subnavbar.theme-amber,
.theme-amber .searchbar,
.searchbar.theme-amber {
  background-color: #ffc107;
}
.theme-amber .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-amber input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(255, 193, 7, 0.5);
}
.theme-amber .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-amber input[type="checkbox"]:checked + .checkbox:after {
  background-color: #ffc107;
}
.theme-amber label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-amber i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-amber label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-amber label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-amber label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-amber label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-amber label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-amber label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-amber input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #ffc107;
  background-color: #ffc107;
}
.theme-amber label.label-radio i.icon-form-radio:after,
label.label-radio.theme-amber i.icon-form-radio:after {
  background-color: #ffc107;
}
.theme-amber label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-amber input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-amber label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-amber input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-amber label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-amber input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-amber label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-amber input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-amber label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-amber input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-amber label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-amber input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #ffc107;
}
.theme-amber label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-amber input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-amber label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-amber input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-amber label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-amber input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-amber label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-amber input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-amber label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-amber input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-amber label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-amber input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #ffc107;
}
.theme-amber .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-amber input[type="checkbox"]:checked ~ i {
  border-color: #ffc107;
  background-color: #ffc107;
}
.theme-amber .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-amber input[type="radio"]:checked ~ i {
  border-color: #ffc107;
}
.theme-amber .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-amber input[type="radio"]:checked ~ i:after {
  background-color: #ffc107;
}
.theme-amber .form-checkbox .ripple-wave,
.theme-amber .form-radio .ripple-wave {
  background: rgba(255, 193, 7, 0.5);
}
.theme-amber .focus-state .label,
.theme-amber .focus-state .floating-label {
  color: #ffc107;
}
.theme-amber .item-input-field.focus-state:after,
.theme-amber .input-field.focus-state:after,
.theme-amber .item-input-field.not-empty-state:after,
.theme-amber .input-field.not-empty-state:after {
  background: #ffc107;
}
.theme-amber .picker-calendar-day.picker-calendar-day-today span {
  color: #ffc107;
}
.theme-amber .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #ffc107;
  color: #fff;
}
.theme-amber .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #ffc107;
}
.theme-amber .picker-header {
  background-color: #ffc107;
}
.theme-amber .modal-button {
  color: #ffc107;
}
.theme-amber .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-amber .timeline-item-date {
  background-color: #ffc107;
}
.theme-amber .timeline-year-title,
.theme-amber .timeline-month-title {
  background-color: #ffc107;
}
.theme-amber .statusbar-overlay {
  background-color: #ff6f00;
}
.theme-amber .data-table .data-table-header-selected,
.data-table.theme-amber .data-table-header-selected {
  background: rgba(255, 193, 7, 0.1);
}
.theme-amber .data-table .data-table-title-selected,
.data-table.theme-amber .data-table-title-selected {
  color: #ffc107;
}
.swiper-pagination.color-amber .swiper-pagination-bullet-active,
.theme-amber .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffc107;
}
.swiper-pagination.color-amber .swiper-pagination-progressbar,
.theme-amber .swiper-pagination .swiper-pagination-progressbar {
  background-color: #ffc107;
}
.swiper-pagination.swiper-pagination-progress.bg-amber {
  background-color: rgba(255, 193, 7, 0.25);
}
.swiper-button-next.color-amber,
.swiper-container-rtl .swiper-button-prev.color-amber,
.theme-amber .swiper-button-next,
.theme-amber .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffc107'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-amber,
.swiper-container-rtl .swiper-button-next.color-amber,
.theme-amber .swiper-button-prev,
.theme-amber .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffc107'%2F%3E%3C%2Fsvg%3E");
}
.theme-amber .floating-button,
.theme-amber .speed-dial-buttons a,
.floating-button.theme-amber,
.speed-dial-buttons a.theme-amber {
  color: #fff;
  background: #ffc107;
}
html:not(.watch-active-state) .theme-amber .floating-button:active,
html:not(.watch-active-state) .theme-amber .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-amber:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-amber:active,
.theme-amber .floating-button.active-state,
.theme-amber .speed-dial-buttons a.active-state,
.floating-button.theme-amber.active-state,
.speed-dial-buttons a.theme-amber.active-state {
  background: #ffa000;
}
.theme-amber .floating-button i,
.theme-amber .speed-dial-buttons a i,
.floating-button.theme-amber i,
.speed-dial-buttons a.theme-amber i {
  color: inherit;
}
.theme-amber .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-amber input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffc107;
}
.theme-amber .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-amber input[type="range"]::-webkit-slider-thumb {
  background-color: #ffc107;
}
.theme-amber .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-amber input[type="range"]::-ms-thumb {
  background-color: #ffc107;
}
.theme-amber .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-amber input[type="range"]::-ms-fill-lower {
  background-color: #ffc107;
}
.theme-amber .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-amber input[type="range"]::-moz-range-thumb {
  background-color: #ffc107;
}
.badge.theme-amber {
  background-color: #ffc107;
  color: #fff;
}
.theme-orange .button:not(.button-fill) {
  color: #ff9800;
}
.theme-orange .navbar .button:not(.button-fill),
.theme-orange.navbar .button:not(.button-fill),
.theme-orange .toolbar .button:not(.button-fill),
.theme-orange.toolbar .button:not(.button-fill),
.theme-orange .subnavbar .button:not(.button-fill),
.theme-orange.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-orange .button.button-fill {
  background: #ff9800;
  color: #fff;
}
html:not(.watch-active-state) .theme-orange .button.button-fill:active,
.theme-orange .button.button-fill.active-state {
  background: #f57c00;
}
.progressbar.theme-orange,
.theme-orange .progressbar {
  background-color: rgba(255, 152, 0, 0.5);
}
.progressbar.theme-orange span,
.theme-orange .progressbar span {
  background-color: #ff9800;
}
.progressbar-infinite.theme-orange,
.theme-orange .progressbar-infinite {
  background-color: rgba(255, 152, 0, 0.5);
}
.progressbar-infinite.theme-orange:after,
.theme-orange .progressbar-infinite:after,
.progressbar-infinite.theme-orange:before,
.theme-orange .progressbar-infinite:before {
  background-color: #ff9800;
}
.color-orange i.icon,
i.icon.color-orange {
  color: #ff9800;
}
i.icon-next.color-orange,
i.icon-next.theme-orange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ff9800'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-orange,
i.icon-prev.theme-orange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ff9800'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-orange,
i.icon-back.theme-orange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23ff9800'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-orange,
i.icon-forward.theme-orange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23ff9800'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-orange,
i.icon-bars.theme-orange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23ff9800'%2F%3E%3C%2Fsvg%3E");
}
.theme-orange a,
.theme-orange .card a {
  color: #ff9800;
}
.theme-orange .navbar,
.navbar.theme-orange,
.theme-orange .toolbar,
.toolbar.theme-orange,
.theme-orange .subnavbar,
.subnavbar.theme-orange,
.theme-orange .searchbar,
.searchbar.theme-orange {
  background-color: #ff9800;
}
.theme-orange .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-orange input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(255, 152, 0, 0.5);
}
.theme-orange .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-orange input[type="checkbox"]:checked + .checkbox:after {
  background-color: #ff9800;
}
.theme-orange label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-orange i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-orange label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-orange label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-orange label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-orange label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-orange label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-orange label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-orange input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #ff9800;
  background-color: #ff9800;
}
.theme-orange label.label-radio i.icon-form-radio:after,
label.label-radio.theme-orange i.icon-form-radio:after {
  background-color: #ff9800;
}
.theme-orange label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-orange input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-orange label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-orange input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-orange label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-orange input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-orange label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-orange input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-orange label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-orange input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-orange label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-orange input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #ff9800;
}
.theme-orange label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-orange input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-orange label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-orange input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-orange label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-orange input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-orange label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-orange input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-orange label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-orange input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-orange label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-orange input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #ff9800;
}
.theme-orange .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-orange input[type="checkbox"]:checked ~ i {
  border-color: #ff9800;
  background-color: #ff9800;
}
.theme-orange .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-orange input[type="radio"]:checked ~ i {
  border-color: #ff9800;
}
.theme-orange .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-orange input[type="radio"]:checked ~ i:after {
  background-color: #ff9800;
}
.theme-orange .form-checkbox .ripple-wave,
.theme-orange .form-radio .ripple-wave {
  background: rgba(255, 152, 0, 0.5);
}
.theme-orange .focus-state .label,
.theme-orange .focus-state .floating-label {
  color: #ff9800;
}
.theme-orange .item-input-field.focus-state:after,
.theme-orange .input-field.focus-state:after,
.theme-orange .item-input-field.not-empty-state:after,
.theme-orange .input-field.not-empty-state:after {
  background: #ff9800;
}
.theme-orange .picker-calendar-day.picker-calendar-day-today span {
  color: #ff9800;
}
.theme-orange .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #ff9800;
  color: #fff;
}
.theme-orange .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #ff9800;
}
.theme-orange .picker-header {
  background-color: #ff9800;
}
.theme-orange .modal-button {
  color: #ff9800;
}
.theme-orange .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-orange .timeline-item-date {
  background-color: #ff9800;
}
.theme-orange .timeline-year-title,
.theme-orange .timeline-month-title {
  background-color: #ff9800;
}
.theme-orange .statusbar-overlay {
  background-color: #e65100;
}
.theme-orange .data-table .data-table-header-selected,
.data-table.theme-orange .data-table-header-selected {
  background: rgba(255, 152, 0, 0.1);
}
.theme-orange .data-table .data-table-title-selected,
.data-table.theme-orange .data-table-title-selected {
  color: #ff9800;
}
.swiper-pagination.color-orange .swiper-pagination-bullet-active,
.theme-orange .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ff9800;
}
.swiper-pagination.color-orange .swiper-pagination-progressbar,
.theme-orange .swiper-pagination .swiper-pagination-progressbar {
  background-color: #ff9800;
}
.swiper-pagination.swiper-pagination-progress.bg-orange {
  background-color: rgba(255, 152, 0, 0.25);
}
.swiper-button-next.color-orange,
.swiper-container-rtl .swiper-button-prev.color-orange,
.theme-orange .swiper-button-next,
.theme-orange .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ff9800'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-orange,
.swiper-container-rtl .swiper-button-next.color-orange,
.theme-orange .swiper-button-prev,
.theme-orange .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ff9800'%2F%3E%3C%2Fsvg%3E");
}
.theme-orange .floating-button,
.theme-orange .speed-dial-buttons a,
.floating-button.theme-orange,
.speed-dial-buttons a.theme-orange {
  color: #fff;
  background: #ff9800;
}
html:not(.watch-active-state) .theme-orange .floating-button:active,
html:not(.watch-active-state) .theme-orange .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-orange:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-orange:active,
.theme-orange .floating-button.active-state,
.theme-orange .speed-dial-buttons a.active-state,
.floating-button.theme-orange.active-state,
.speed-dial-buttons a.theme-orange.active-state {
  background: #f57c00;
}
.theme-orange .floating-button i,
.theme-orange .speed-dial-buttons a i,
.floating-button.theme-orange i,
.speed-dial-buttons a.theme-orange i {
  color: inherit;
}
.theme-orange .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-orange input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ff9800;
}
.theme-orange .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-orange input[type="range"]::-webkit-slider-thumb {
  background-color: #ff9800;
}
.theme-orange .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-orange input[type="range"]::-ms-thumb {
  background-color: #ff9800;
}
.theme-orange .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-orange input[type="range"]::-ms-fill-lower {
  background-color: #ff9800;
}
.theme-orange .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-orange input[type="range"]::-moz-range-thumb {
  background-color: #ff9800;
}
.badge.theme-orange {
  background-color: #ff9800;
  color: #fff;
}
.theme-deeporange .button:not(.button-fill) {
  color: #ff5722;
}
.theme-deeporange .navbar .button:not(.button-fill),
.theme-deeporange.navbar .button:not(.button-fill),
.theme-deeporange .toolbar .button:not(.button-fill),
.theme-deeporange.toolbar .button:not(.button-fill),
.theme-deeporange .subnavbar .button:not(.button-fill),
.theme-deeporange.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-deeporange .button.button-fill {
  background: #ff5722;
  color: #fff;
}
html:not(.watch-active-state) .theme-deeporange .button.button-fill:active,
.theme-deeporange .button.button-fill.active-state {
  background: #e64a19;
}
.progressbar.theme-deeporange,
.theme-deeporange .progressbar {
  background-color: rgba(255, 87, 34, 0.5);
}
.progressbar.theme-deeporange span,
.theme-deeporange .progressbar span {
  background-color: #ff5722;
}
.progressbar-infinite.theme-deeporange,
.theme-deeporange .progressbar-infinite {
  background-color: rgba(255, 87, 34, 0.5);
}
.progressbar-infinite.theme-deeporange:after,
.theme-deeporange .progressbar-infinite:after,
.progressbar-infinite.theme-deeporange:before,
.theme-deeporange .progressbar-infinite:before {
  background-color: #ff5722;
}
.color-deeporange i.icon,
i.icon.color-deeporange {
  color: #ff5722;
}
i.icon-next.color-deeporange,
i.icon-next.theme-deeporange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ff5722'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-deeporange,
i.icon-prev.theme-deeporange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ff5722'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-deeporange,
i.icon-back.theme-deeporange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23ff5722'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-deeporange,
i.icon-forward.theme-deeporange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23ff5722'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-deeporange,
i.icon-bars.theme-deeporange {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23ff5722'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeporange a,
.theme-deeporange .card a {
  color: #ff5722;
}
.theme-deeporange .navbar,
.navbar.theme-deeporange,
.theme-deeporange .toolbar,
.toolbar.theme-deeporange,
.theme-deeporange .subnavbar,
.subnavbar.theme-deeporange,
.theme-deeporange .searchbar,
.searchbar.theme-deeporange {
  background-color: #ff5722;
}
.theme-deeporange .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-deeporange input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(255, 87, 34, 0.5);
}
.theme-deeporange .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-deeporange input[type="checkbox"]:checked + .checkbox:after {
  background-color: #ff5722;
}
.theme-deeporange label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-deeporange i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeporange label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-deeporange label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-deeporange label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-deeporange label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-deeporange label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-deeporange label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-deeporange input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #ff5722;
  background-color: #ff5722;
}
.theme-deeporange label.label-radio i.icon-form-radio:after,
label.label-radio.theme-deeporange i.icon-form-radio:after {
  background-color: #ff5722;
}
.theme-deeporange label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-deeporange input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-deeporange label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-deeporange input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-deeporange label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-deeporange input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-deeporange label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-deeporange input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-deeporange label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-deeporange input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-deeporange label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-deeporange input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #ff5722;
}
.theme-deeporange label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-deeporange label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-deeporange label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-deeporange label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-deeporange label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-deeporange label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-deeporange input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #ff5722;
}
.theme-deeporange .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-deeporange input[type="checkbox"]:checked ~ i {
  border-color: #ff5722;
  background-color: #ff5722;
}
.theme-deeporange .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-deeporange input[type="radio"]:checked ~ i {
  border-color: #ff5722;
}
.theme-deeporange .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-deeporange input[type="radio"]:checked ~ i:after {
  background-color: #ff5722;
}
.theme-deeporange .form-checkbox .ripple-wave,
.theme-deeporange .form-radio .ripple-wave {
  background: rgba(255, 87, 34, 0.5);
}
.theme-deeporange .focus-state .label,
.theme-deeporange .focus-state .floating-label {
  color: #ff5722;
}
.theme-deeporange .item-input-field.focus-state:after,
.theme-deeporange .input-field.focus-state:after,
.theme-deeporange .item-input-field.not-empty-state:after,
.theme-deeporange .input-field.not-empty-state:after {
  background: #ff5722;
}
.theme-deeporange .picker-calendar-day.picker-calendar-day-today span {
  color: #ff5722;
}
.theme-deeporange .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #ff5722;
  color: #fff;
}
.theme-deeporange .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #ff5722;
}
.theme-deeporange .picker-header {
  background-color: #ff5722;
}
.theme-deeporange .modal-button {
  color: #ff5722;
}
.theme-deeporange .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-deeporange .timeline-item-date {
  background-color: #ff5722;
}
.theme-deeporange .timeline-year-title,
.theme-deeporange .timeline-month-title {
  background-color: #ff5722;
}
.theme-deeporange .statusbar-overlay {
  background-color: #bf360c;
}
.theme-deeporange .data-table .data-table-header-selected,
.data-table.theme-deeporange .data-table-header-selected {
  background: rgba(255, 87, 34, 0.1);
}
.theme-deeporange .data-table .data-table-title-selected,
.data-table.theme-deeporange .data-table-title-selected {
  color: #ff5722;
}
.swiper-pagination.color-deeporange .swiper-pagination-bullet-active,
.theme-deeporange .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ff5722;
}
.swiper-pagination.color-deeporange .swiper-pagination-progressbar,
.theme-deeporange .swiper-pagination .swiper-pagination-progressbar {
  background-color: #ff5722;
}
.swiper-pagination.swiper-pagination-progress.bg-deeporange {
  background-color: rgba(255, 87, 34, 0.25);
}
.swiper-button-next.color-deeporange,
.swiper-container-rtl .swiper-button-prev.color-deeporange,
.theme-deeporange .swiper-button-next,
.theme-deeporange .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ff5722'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-deeporange,
.swiper-container-rtl .swiper-button-next.color-deeporange,
.theme-deeporange .swiper-button-prev,
.theme-deeporange .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ff5722'%2F%3E%3C%2Fsvg%3E");
}
.theme-deeporange .floating-button,
.theme-deeporange .speed-dial-buttons a,
.floating-button.theme-deeporange,
.speed-dial-buttons a.theme-deeporange {
  color: #fff;
  background: #ff5722;
}
html:not(.watch-active-state) .theme-deeporange .floating-button:active,
html:not(.watch-active-state) .theme-deeporange .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-deeporange:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-deeporange:active,
.theme-deeporange .floating-button.active-state,
.theme-deeporange .speed-dial-buttons a.active-state,
.floating-button.theme-deeporange.active-state,
.speed-dial-buttons a.theme-deeporange.active-state {
  background: #e64a19;
}
.theme-deeporange .floating-button i,
.theme-deeporange .speed-dial-buttons a i,
.floating-button.theme-deeporange i,
.speed-dial-buttons a.theme-deeporange i {
  color: inherit;
}
.theme-deeporange .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-deeporange input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ff5722;
}
.theme-deeporange .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-deeporange input[type="range"]::-webkit-slider-thumb {
  background-color: #ff5722;
}
.theme-deeporange .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-deeporange input[type="range"]::-ms-thumb {
  background-color: #ff5722;
}
.theme-deeporange .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-deeporange input[type="range"]::-ms-fill-lower {
  background-color: #ff5722;
}
.theme-deeporange .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-deeporange input[type="range"]::-moz-range-thumb {
  background-color: #ff5722;
}
.badge.theme-deeporange {
  background-color: #ff5722;
  color: #fff;
}
.theme-brown .button:not(.button-fill) {
  color: #795548;
}
.theme-brown .navbar .button:not(.button-fill),
.theme-brown.navbar .button:not(.button-fill),
.theme-brown .toolbar .button:not(.button-fill),
.theme-brown.toolbar .button:not(.button-fill),
.theme-brown .subnavbar .button:not(.button-fill),
.theme-brown.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-brown .button.button-fill {
  background: #795548;
  color: #fff;
}
html:not(.watch-active-state) .theme-brown .button.button-fill:active,
.theme-brown .button.button-fill.active-state {
  background: #5d4037;
}
.progressbar.theme-brown,
.theme-brown .progressbar {
  background-color: rgba(121, 85, 72, 0.5);
}
.progressbar.theme-brown span,
.theme-brown .progressbar span {
  background-color: #795548;
}
.progressbar-infinite.theme-brown,
.theme-brown .progressbar-infinite {
  background-color: rgba(121, 85, 72, 0.5);
}
.progressbar-infinite.theme-brown:after,
.theme-brown .progressbar-infinite:after,
.progressbar-infinite.theme-brown:before,
.theme-brown .progressbar-infinite:before {
  background-color: #795548;
}
.color-brown i.icon,
i.icon.color-brown {
  color: #795548;
}
i.icon-next.color-brown,
i.icon-next.theme-brown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23795548'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-brown,
i.icon-prev.theme-brown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23795548'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-brown,
i.icon-back.theme-brown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23795548'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-brown,
i.icon-forward.theme-brown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23795548'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-brown,
i.icon-bars.theme-brown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23795548'%2F%3E%3C%2Fsvg%3E");
}
.theme-brown a,
.theme-brown .card a {
  color: #795548;
}
.theme-brown .navbar,
.navbar.theme-brown,
.theme-brown .toolbar,
.toolbar.theme-brown,
.theme-brown .subnavbar,
.subnavbar.theme-brown,
.theme-brown .searchbar,
.searchbar.theme-brown {
  background-color: #795548;
}
.theme-brown .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-brown input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(121, 85, 72, 0.5);
}
.theme-brown .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-brown input[type="checkbox"]:checked + .checkbox:after {
  background-color: #795548;
}
.theme-brown label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-brown i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-brown label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-brown label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-brown label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-brown label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-brown label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-brown label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-brown input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #795548;
  background-color: #795548;
}
.theme-brown label.label-radio i.icon-form-radio:after,
label.label-radio.theme-brown i.icon-form-radio:after {
  background-color: #795548;
}
.theme-brown label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-brown input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-brown label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-brown input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-brown label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-brown input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-brown label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-brown input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-brown label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-brown input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-brown label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-brown input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #795548;
}
.theme-brown label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-brown input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-brown label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-brown input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-brown label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-brown input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-brown label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-brown input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-brown label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-brown input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-brown label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-brown input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #795548;
}
.theme-brown .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-brown input[type="checkbox"]:checked ~ i {
  border-color: #795548;
  background-color: #795548;
}
.theme-brown .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-brown input[type="radio"]:checked ~ i {
  border-color: #795548;
}
.theme-brown .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-brown input[type="radio"]:checked ~ i:after {
  background-color: #795548;
}
.theme-brown .form-checkbox .ripple-wave,
.theme-brown .form-radio .ripple-wave {
  background: rgba(121, 85, 72, 0.5);
}
.theme-brown .focus-state .label,
.theme-brown .focus-state .floating-label {
  color: #795548;
}
.theme-brown .item-input-field.focus-state:after,
.theme-brown .input-field.focus-state:after,
.theme-brown .item-input-field.not-empty-state:after,
.theme-brown .input-field.not-empty-state:after {
  background: #795548;
}
.theme-brown .picker-calendar-day.picker-calendar-day-today span {
  color: #795548;
}
.theme-brown .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #795548;
  color: #fff;
}
.theme-brown .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #795548;
}
.theme-brown .picker-header {
  background-color: #795548;
}
.theme-brown .modal-button {
  color: #795548;
}
.theme-brown .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-brown .timeline-item-date {
  background-color: #795548;
}
.theme-brown .timeline-year-title,
.theme-brown .timeline-month-title {
  background-color: #795548;
}
.theme-brown .statusbar-overlay {
  background-color: #3e2723;
}
.theme-brown .data-table .data-table-header-selected,
.data-table.theme-brown .data-table-header-selected {
  background: rgba(121, 85, 72, 0.1);
}
.theme-brown .data-table .data-table-title-selected,
.data-table.theme-brown .data-table-title-selected {
  color: #795548;
}
.swiper-pagination.color-brown .swiper-pagination-bullet-active,
.theme-brown .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #795548;
}
.swiper-pagination.color-brown .swiper-pagination-progressbar,
.theme-brown .swiper-pagination .swiper-pagination-progressbar {
  background-color: #795548;
}
.swiper-pagination.swiper-pagination-progress.bg-brown {
  background-color: rgba(121, 85, 72, 0.25);
}
.swiper-button-next.color-brown,
.swiper-container-rtl .swiper-button-prev.color-brown,
.theme-brown .swiper-button-next,
.theme-brown .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23795548'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-brown,
.swiper-container-rtl .swiper-button-next.color-brown,
.theme-brown .swiper-button-prev,
.theme-brown .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23795548'%2F%3E%3C%2Fsvg%3E");
}
.theme-brown .floating-button,
.theme-brown .speed-dial-buttons a,
.floating-button.theme-brown,
.speed-dial-buttons a.theme-brown {
  color: #fff;
  background: #795548;
}
html:not(.watch-active-state) .theme-brown .floating-button:active,
html:not(.watch-active-state) .theme-brown .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-brown:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-brown:active,
.theme-brown .floating-button.active-state,
.theme-brown .speed-dial-buttons a.active-state,
.floating-button.theme-brown.active-state,
.speed-dial-buttons a.theme-brown.active-state {
  background: #5d4037;
}
.theme-brown .floating-button i,
.theme-brown .speed-dial-buttons a i,
.floating-button.theme-brown i,
.speed-dial-buttons a.theme-brown i {
  color: inherit;
}
.theme-brown .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-brown input[type="range"]::-webkit-slider-thumb:before {
  background-color: #795548;
}
.theme-brown .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-brown input[type="range"]::-webkit-slider-thumb {
  background-color: #795548;
}
.theme-brown .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-brown input[type="range"]::-ms-thumb {
  background-color: #795548;
}
.theme-brown .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-brown input[type="range"]::-ms-fill-lower {
  background-color: #795548;
}
.theme-brown .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-brown input[type="range"]::-moz-range-thumb {
  background-color: #795548;
}
.badge.theme-brown {
  background-color: #795548;
  color: #fff;
}
.theme-gray .button:not(.button-fill) {
  color: #9e9e9e;
}
.theme-gray .navbar .button:not(.button-fill),
.theme-gray.navbar .button:not(.button-fill),
.theme-gray .toolbar .button:not(.button-fill),
.theme-gray.toolbar .button:not(.button-fill),
.theme-gray .subnavbar .button:not(.button-fill),
.theme-gray.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-gray .button.button-fill {
  background: #9e9e9e;
  color: #fff;
}
html:not(.watch-active-state) .theme-gray .button.button-fill:active,
.theme-gray .button.button-fill.active-state {
  background: #616161;
}
.progressbar.theme-gray,
.theme-gray .progressbar {
  background-color: rgba(158, 158, 158, 0.5);
}
.progressbar.theme-gray span,
.theme-gray .progressbar span {
  background-color: #9e9e9e;
}
.progressbar-infinite.theme-gray,
.theme-gray .progressbar-infinite {
  background-color: rgba(158, 158, 158, 0.5);
}
.progressbar-infinite.theme-gray:after,
.theme-gray .progressbar-infinite:after,
.progressbar-infinite.theme-gray:before,
.theme-gray .progressbar-infinite:before {
  background-color: #9e9e9e;
}
.color-gray i.icon,
i.icon.color-gray {
  color: #9e9e9e;
}
i.icon-next.color-gray,
i.icon-next.theme-gray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%239e9e9e'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-gray,
i.icon-prev.theme-gray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%239e9e9e'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-gray,
i.icon-back.theme-gray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%239e9e9e'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-gray,
i.icon-forward.theme-gray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%239e9e9e'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-gray,
i.icon-bars.theme-gray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%239e9e9e'%2F%3E%3C%2Fsvg%3E");
}
.theme-gray a,
.theme-gray .card a {
  color: #9e9e9e;
}
.theme-gray .navbar,
.navbar.theme-gray,
.theme-gray .toolbar,
.toolbar.theme-gray,
.theme-gray .subnavbar,
.subnavbar.theme-gray,
.theme-gray .searchbar,
.searchbar.theme-gray {
  background-color: #9e9e9e;
}
.theme-gray .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-gray input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(158, 158, 158, 0.5);
}
.theme-gray .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-gray input[type="checkbox"]:checked + .checkbox:after {
  background-color: #9e9e9e;
}
.theme-gray label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-gray i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-gray label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-gray label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-gray label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-gray label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-gray label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-gray label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-gray input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #9e9e9e;
  background-color: #9e9e9e;
}
.theme-gray label.label-radio i.icon-form-radio:after,
label.label-radio.theme-gray i.icon-form-radio:after {
  background-color: #9e9e9e;
}
.theme-gray label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-gray input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-gray label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-gray input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-gray label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-gray input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-gray label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-gray input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-gray label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-gray input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-gray label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-gray input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #9e9e9e;
}
.theme-gray label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-gray input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-gray label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-gray input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-gray label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-gray input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-gray label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-gray input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-gray label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-gray input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-gray label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-gray input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #9e9e9e;
}
.theme-gray .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-gray input[type="checkbox"]:checked ~ i {
  border-color: #9e9e9e;
  background-color: #9e9e9e;
}
.theme-gray .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-gray input[type="radio"]:checked ~ i {
  border-color: #9e9e9e;
}
.theme-gray .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-gray input[type="radio"]:checked ~ i:after {
  background-color: #9e9e9e;
}
.theme-gray .form-checkbox .ripple-wave,
.theme-gray .form-radio .ripple-wave {
  background: rgba(158, 158, 158, 0.5);
}
.theme-gray .focus-state .label,
.theme-gray .focus-state .floating-label {
  color: #9e9e9e;
}
.theme-gray .item-input-field.focus-state:after,
.theme-gray .input-field.focus-state:after,
.theme-gray .item-input-field.not-empty-state:after,
.theme-gray .input-field.not-empty-state:after {
  background: #9e9e9e;
}
.theme-gray .picker-calendar-day.picker-calendar-day-today span {
  color: #9e9e9e;
}
.theme-gray .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #9e9e9e;
  color: #fff;
}
.theme-gray .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #9e9e9e;
}
.theme-gray .picker-header {
  background-color: #9e9e9e;
}
.theme-gray .modal-button {
  color: #9e9e9e;
}
.theme-gray .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-gray .timeline-item-date {
  background-color: #9e9e9e;
}
.theme-gray .timeline-year-title,
.theme-gray .timeline-month-title {
  background-color: #9e9e9e;
}
.theme-gray .statusbar-overlay {
  background-color: #212121;
}
.theme-gray .data-table .data-table-header-selected,
.data-table.theme-gray .data-table-header-selected {
  background: rgba(158, 158, 158, 0.1);
}
.theme-gray .data-table .data-table-title-selected,
.data-table.theme-gray .data-table-title-selected {
  color: #9e9e9e;
}
.swiper-pagination.color-gray .swiper-pagination-bullet-active,
.theme-gray .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #9e9e9e;
}
.swiper-pagination.color-gray .swiper-pagination-progressbar,
.theme-gray .swiper-pagination .swiper-pagination-progressbar {
  background-color: #9e9e9e;
}
.swiper-pagination.swiper-pagination-progress.bg-gray {
  background-color: rgba(158, 158, 158, 0.25);
}
.swiper-button-next.color-gray,
.swiper-container-rtl .swiper-button-prev.color-gray,
.theme-gray .swiper-button-next,
.theme-gray .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%239e9e9e'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-gray,
.swiper-container-rtl .swiper-button-next.color-gray,
.theme-gray .swiper-button-prev,
.theme-gray .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%239e9e9e'%2F%3E%3C%2Fsvg%3E");
}
.theme-gray .floating-button,
.theme-gray .speed-dial-buttons a,
.floating-button.theme-gray,
.speed-dial-buttons a.theme-gray {
  color: #fff;
  background: #9e9e9e;
}
html:not(.watch-active-state) .theme-gray .floating-button:active,
html:not(.watch-active-state) .theme-gray .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-gray:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-gray:active,
.theme-gray .floating-button.active-state,
.theme-gray .speed-dial-buttons a.active-state,
.floating-button.theme-gray.active-state,
.speed-dial-buttons a.theme-gray.active-state {
  background: #616161;
}
.theme-gray .floating-button i,
.theme-gray .speed-dial-buttons a i,
.floating-button.theme-gray i,
.speed-dial-buttons a.theme-gray i {
  color: inherit;
}
.theme-gray .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-gray input[type="range"]::-webkit-slider-thumb:before {
  background-color: #9e9e9e;
}
.theme-gray .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-gray input[type="range"]::-webkit-slider-thumb {
  background-color: #9e9e9e;
}
.theme-gray .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-gray input[type="range"]::-ms-thumb {
  background-color: #9e9e9e;
}
.theme-gray .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-gray input[type="range"]::-ms-fill-lower {
  background-color: #9e9e9e;
}
.theme-gray .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-gray input[type="range"]::-moz-range-thumb {
  background-color: #9e9e9e;
}
.badge.theme-gray {
  background-color: #9e9e9e;
  color: #fff;
}
.theme-bluegray .button:not(.button-fill) {
  color: #607d8b;
}
.theme-bluegray .navbar .button:not(.button-fill),
.theme-bluegray.navbar .button:not(.button-fill),
.theme-bluegray .toolbar .button:not(.button-fill),
.theme-bluegray.toolbar .button:not(.button-fill),
.theme-bluegray .subnavbar .button:not(.button-fill),
.theme-bluegray.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-bluegray .button.button-fill {
  background: #607d8b;
  color: #fff;
}
html:not(.watch-active-state) .theme-bluegray .button.button-fill:active,
.theme-bluegray .button.button-fill.active-state {
  background: #455a64;
}
.progressbar.theme-bluegray,
.theme-bluegray .progressbar {
  background-color: rgba(96, 125, 139, 0.5);
}
.progressbar.theme-bluegray span,
.theme-bluegray .progressbar span {
  background-color: #607d8b;
}
.progressbar-infinite.theme-bluegray,
.theme-bluegray .progressbar-infinite {
  background-color: rgba(96, 125, 139, 0.5);
}
.progressbar-infinite.theme-bluegray:after,
.theme-bluegray .progressbar-infinite:after,
.progressbar-infinite.theme-bluegray:before,
.theme-bluegray .progressbar-infinite:before {
  background-color: #607d8b;
}
.color-bluegray i.icon,
i.icon.color-bluegray {
  color: #607d8b;
}
i.icon-next.color-bluegray,
i.icon-next.theme-bluegray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23607d8b'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-bluegray,
i.icon-prev.theme-bluegray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23607d8b'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-bluegray,
i.icon-back.theme-bluegray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23607d8b'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-bluegray,
i.icon-forward.theme-bluegray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23607d8b'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-bluegray,
i.icon-bars.theme-bluegray {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23607d8b'%2F%3E%3C%2Fsvg%3E");
}
.theme-bluegray a,
.theme-bluegray .card a {
  color: #607d8b;
}
.theme-bluegray .navbar,
.navbar.theme-bluegray,
.theme-bluegray .toolbar,
.toolbar.theme-bluegray,
.theme-bluegray .subnavbar,
.subnavbar.theme-bluegray,
.theme-bluegray .searchbar,
.searchbar.theme-bluegray {
  background-color: #607d8b;
}
.theme-bluegray .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-bluegray input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(96, 125, 139, 0.5);
}
.theme-bluegray .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-bluegray input[type="checkbox"]:checked + .checkbox:after {
  background-color: #607d8b;
}
.theme-bluegray label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-bluegray i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-bluegray label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-bluegray label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-bluegray label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-bluegray label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-bluegray label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-bluegray label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-bluegray input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #607d8b;
  background-color: #607d8b;
}
.theme-bluegray label.label-radio i.icon-form-radio:after,
label.label-radio.theme-bluegray i.icon-form-radio:after {
  background-color: #607d8b;
}
.theme-bluegray label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-bluegray input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-bluegray label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-bluegray input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-bluegray label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-bluegray input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-bluegray label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-bluegray input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-bluegray label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-bluegray input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-bluegray label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-bluegray input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #607d8b;
}
.theme-bluegray label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-bluegray label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-bluegray label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-bluegray label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-bluegray label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-bluegray label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-bluegray input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #607d8b;
}
.theme-bluegray .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-bluegray input[type="checkbox"]:checked ~ i {
  border-color: #607d8b;
  background-color: #607d8b;
}
.theme-bluegray .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-bluegray input[type="radio"]:checked ~ i {
  border-color: #607d8b;
}
.theme-bluegray .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-bluegray input[type="radio"]:checked ~ i:after {
  background-color: #607d8b;
}
.theme-bluegray .form-checkbox .ripple-wave,
.theme-bluegray .form-radio .ripple-wave {
  background: rgba(96, 125, 139, 0.5);
}
.theme-bluegray .focus-state .label,
.theme-bluegray .focus-state .floating-label {
  color: #607d8b;
}
.theme-bluegray .item-input-field.focus-state:after,
.theme-bluegray .input-field.focus-state:after,
.theme-bluegray .item-input-field.not-empty-state:after,
.theme-bluegray .input-field.not-empty-state:after {
  background: #607d8b;
}
.theme-bluegray .picker-calendar-day.picker-calendar-day-today span {
  color: #607d8b;
}
.theme-bluegray .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #607d8b;
  color: #fff;
}
.theme-bluegray .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #607d8b;
}
.theme-bluegray .picker-header {
  background-color: #607d8b;
}
.theme-bluegray .modal-button {
  color: #607d8b;
}
.theme-bluegray .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-bluegray .timeline-item-date {
  background-color: #607d8b;
}
.theme-bluegray .timeline-year-title,
.theme-bluegray .timeline-month-title {
  background-color: #607d8b;
}
.theme-bluegray .statusbar-overlay {
  background-color: #263238;
}
.theme-bluegray .data-table .data-table-header-selected,
.data-table.theme-bluegray .data-table-header-selected {
  background: rgba(96, 125, 139, 0.1);
}
.theme-bluegray .data-table .data-table-title-selected,
.data-table.theme-bluegray .data-table-title-selected {
  color: #607d8b;
}
.swiper-pagination.color-bluegray .swiper-pagination-bullet-active,
.theme-bluegray .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #607d8b;
}
.swiper-pagination.color-bluegray .swiper-pagination-progressbar,
.theme-bluegray .swiper-pagination .swiper-pagination-progressbar {
  background-color: #607d8b;
}
.swiper-pagination.swiper-pagination-progress.bg-bluegray {
  background-color: rgba(96, 125, 139, 0.25);
}
.swiper-button-next.color-bluegray,
.swiper-container-rtl .swiper-button-prev.color-bluegray,
.theme-bluegray .swiper-button-next,
.theme-bluegray .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23607d8b'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-bluegray,
.swiper-container-rtl .swiper-button-next.color-bluegray,
.theme-bluegray .swiper-button-prev,
.theme-bluegray .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23607d8b'%2F%3E%3C%2Fsvg%3E");
}
.theme-bluegray .floating-button,
.theme-bluegray .speed-dial-buttons a,
.floating-button.theme-bluegray,
.speed-dial-buttons a.theme-bluegray {
  color: #fff;
  background: #607d8b;
}
html:not(.watch-active-state) .theme-bluegray .floating-button:active,
html:not(.watch-active-state) .theme-bluegray .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-bluegray:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-bluegray:active,
.theme-bluegray .floating-button.active-state,
.theme-bluegray .speed-dial-buttons a.active-state,
.floating-button.theme-bluegray.active-state,
.speed-dial-buttons a.theme-bluegray.active-state {
  background: #455a64;
}
.theme-bluegray .floating-button i,
.theme-bluegray .speed-dial-buttons a i,
.floating-button.theme-bluegray i,
.speed-dial-buttons a.theme-bluegray i {
  color: inherit;
}
.theme-bluegray .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-bluegray input[type="range"]::-webkit-slider-thumb:before {
  background-color: #607d8b;
}
.theme-bluegray .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-bluegray input[type="range"]::-webkit-slider-thumb {
  background-color: #607d8b;
}
.theme-bluegray .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-bluegray input[type="range"]::-ms-thumb {
  background-color: #607d8b;
}
.theme-bluegray .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-bluegray input[type="range"]::-ms-fill-lower {
  background-color: #607d8b;
}
.theme-bluegray .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-bluegray input[type="range"]::-moz-range-thumb {
  background-color: #607d8b;
}
.badge.theme-bluegray {
  background-color: #607d8b;
  color: #fff;
}
.theme-white .button:not(.button-fill) {
  color: #ffffff;
}
.theme-white .navbar .button:not(.button-fill),
.theme-white.navbar .button:not(.button-fill),
.theme-white .toolbar .button:not(.button-fill),
.theme-white.toolbar .button:not(.button-fill),
.theme-white .subnavbar .button:not(.button-fill),
.theme-white.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-white .button.button-fill {
  background: #ffffff;
  color: #fff;
}
html:not(.watch-active-state) .theme-white .button.button-fill:active,
.theme-white .button.button-fill.active-state {
  background: rgba(0, 0, 0, 0.1);
}
.progressbar.theme-white,
.theme-white .progressbar {
  background-color: rgba(255, 255, 255, 0.5);
}
.progressbar.theme-white span,
.theme-white .progressbar span {
  background-color: #ffffff;
}
.progressbar-infinite.theme-white,
.theme-white .progressbar-infinite {
  background-color: rgba(255, 255, 255, 0.5);
}
.progressbar-infinite.theme-white:after,
.theme-white .progressbar-infinite:after,
.progressbar-infinite.theme-white:before,
.theme-white .progressbar-infinite:before {
  background-color: #ffffff;
}
.color-white i.icon,
i.icon.color-white {
  color: #ffffff;
}
i.icon-next.color-white,
i.icon-next.theme-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-white,
i.icon-prev.theme-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-white,
i.icon-back.theme-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-white,
i.icon-forward.theme-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-white,
i.icon-bars.theme-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.theme-white a,
.theme-white .card a {
  color: #ffffff;
}
.theme-white .navbar,
.navbar.theme-white,
.theme-white .toolbar,
.toolbar.theme-white,
.theme-white .subnavbar,
.subnavbar.theme-white,
.theme-white .searchbar,
.searchbar.theme-white {
  background-color: #ffffff;
}
.theme-white .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-white input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-white .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-white input[type="checkbox"]:checked + .checkbox:after {
  background-color: #ffffff;
}
.theme-white label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-white i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-white label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-white label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-white label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-white label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-white label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-white label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-white input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #ffffff;
  background-color: #ffffff;
}
.theme-white label.label-radio i.icon-form-radio:after,
label.label-radio.theme-white i.icon-form-radio:after {
  background-color: #ffffff;
}
.theme-white label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-white input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-white label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-white input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-white label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-white input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-white label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-white input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-white label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-white input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-white label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-white input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #ffffff;
}
.theme-white label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-white input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-white label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-white input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-white label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-white input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-white label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-white input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-white label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-white input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-white label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-white input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #ffffff;
}
.theme-white .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-white input[type="checkbox"]:checked ~ i {
  border-color: #ffffff;
  background-color: #ffffff;
}
.theme-white .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-white input[type="radio"]:checked ~ i {
  border-color: #ffffff;
}
.theme-white .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-white input[type="radio"]:checked ~ i:after {
  background-color: #ffffff;
}
.theme-white .form-checkbox .ripple-wave,
.theme-white .form-radio .ripple-wave {
  background: rgba(255, 255, 255, 0.5);
}
.theme-white .focus-state .label,
.theme-white .focus-state .floating-label {
  color: #ffffff;
}
.theme-white .item-input-field.focus-state:after,
.theme-white .input-field.focus-state:after,
.theme-white .item-input-field.not-empty-state:after,
.theme-white .input-field.not-empty-state:after {
  background: #ffffff;
}
.theme-white .picker-calendar-day.picker-calendar-day-today span {
  color: #ffffff;
}
.theme-white .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #ffffff;
  color: #fff;
}
.theme-white .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #ffffff;
}
.theme-white .picker-header {
  background-color: #ffffff;
}
.theme-white .modal-button {
  color: #ffffff;
}
.theme-white .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-white .timeline-item-date {
  background-color: #ffffff;
}
.theme-white .timeline-year-title,
.theme-white .timeline-month-title {
  background-color: #ffffff;
}
.theme-white .statusbar-overlay {
  background-color: #ffffff;
}
.theme-white .data-table .data-table-header-selected,
.data-table.theme-white .data-table-header-selected {
  background: rgba(255, 255, 255, 0.1);
}
.theme-white .data-table .data-table-title-selected,
.data-table.theme-white .data-table-title-selected {
  color: #ffffff;
}
.swiper-pagination.color-white .swiper-pagination-bullet-active,
.theme-white .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffffff;
}
.swiper-pagination.color-white .swiper-pagination-progressbar,
.theme-white .swiper-pagination .swiper-pagination-progressbar {
  background-color: #ffffff;
}
.swiper-pagination.swiper-pagination-progress.bg-white {
  background-color: rgba(255, 255, 255, 0.25);
}
.swiper-button-next.color-white,
.swiper-container-rtl .swiper-button-prev.color-white,
.theme-white .swiper-button-next,
.theme-white .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-white,
.swiper-container-rtl .swiper-button-next.color-white,
.theme-white .swiper-button-prev,
.theme-white .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.theme-white .floating-button,
.theme-white .speed-dial-buttons a,
.floating-button.theme-white,
.speed-dial-buttons a.theme-white {
  color: #fff;
  background: #ffffff;
}
html:not(.watch-active-state) .theme-white .floating-button:active,
html:not(.watch-active-state) .theme-white .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-white:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-white:active,
.theme-white .floating-button.active-state,
.theme-white .speed-dial-buttons a.active-state,
.floating-button.theme-white.active-state,
.speed-dial-buttons a.theme-white.active-state {
  background: rgba(0, 0, 0, 0.1);
}
.theme-white .floating-button i,
.theme-white .speed-dial-buttons a i,
.floating-button.theme-white i,
.speed-dial-buttons a.theme-white i {
  color: inherit;
}
.theme-white .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-white input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffffff;
}
.theme-white .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-white input[type="range"]::-webkit-slider-thumb {
  background-color: #ffffff;
}
.theme-white .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-white input[type="range"]::-ms-thumb {
  background-color: #ffffff;
}
.theme-white .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-white input[type="range"]::-ms-fill-lower {
  background-color: #ffffff;
}
.theme-white .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-white input[type="range"]::-moz-range-thumb {
  background-color: #ffffff;
}
.badge.theme-white {
  background-color: #ffffff;
  color: #fff;
}
.theme-black .button:not(.button-fill) {
  color: #000000;
}
.theme-black .navbar .button:not(.button-fill),
.theme-black.navbar .button:not(.button-fill),
.theme-black .toolbar .button:not(.button-fill),
.theme-black.toolbar .button:not(.button-fill),
.theme-black .subnavbar .button:not(.button-fill),
.theme-black.subnavbar .button:not(.button-fill) {
  color: #fff;
}
.theme-black .button.button-fill {
  background: #000000;
  color: #fff;
}
html:not(.watch-active-state) .theme-black .button.button-fill:active,
.theme-black .button.button-fill.active-state {
  background: #333;
}
.progressbar.theme-black,
.theme-black .progressbar {
  background-color: rgba(0, 0, 0, 0.5);
}
.progressbar.theme-black span,
.theme-black .progressbar span {
  background-color: #000000;
}
.progressbar-infinite.theme-black,
.theme-black .progressbar-infinite {
  background-color: rgba(0, 0, 0, 0.5);
}
.progressbar-infinite.theme-black:after,
.theme-black .progressbar-infinite:after,
.progressbar-infinite.theme-black:before,
.theme-black .progressbar-infinite:before {
  background-color: #000000;
}
.color-black i.icon,
i.icon.color-black {
  color: #000000;
}
i.icon-next.color-black,
i.icon-next.theme-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23000000'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M10%206L8.59%207.41%2013.17%2012l-4.58%204.59L10%2018l6-6z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-prev.color-black,
i.icon-prev.theme-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23000000'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M15.41%207.41L14%206l-6%206%206%206%201.41-1.41L10.83%2012z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}
i.icon-back.color-black,
i.icon-back.theme-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M20%2011H7.83l5.59-5.59L12%204l-8%208%208%208%201.41-1.41L7.83%2013H20v-2z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
i.icon-forward.color-black,
i.icon-forward.theme-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M12%204l-1.41%201.41L16.17%2011H4v2h12.17l-5.58%205.59L12%2020l8-8z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
i.icon-bars.color-black,
i.icon-bars.theme-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.theme-black a,
.theme-black .card a {
  color: #000000;
}
.theme-black .navbar,
.navbar.theme-black,
.theme-black .toolbar,
.toolbar.theme-black,
.theme-black .subnavbar,
.subnavbar.theme-black,
.theme-black .searchbar,
.searchbar.theme-black {
  background-color: #000000;
}
.theme-black .label-switch input[type="checkbox"]:checked + .checkbox,
.label-switch.theme-black input[type="checkbox"]:checked + .checkbox {
  background-color: rgba(0, 0, 0, 0.5);
}
.theme-black .label-switch input[type="checkbox"]:checked + .checkbox:after,
.label-switch.theme-black input[type="checkbox"]:checked + .checkbox:after {
  background-color: #000000;
}
.theme-black label.label-checkbox i.icon-form-checkbox:after,
label.label-checkbox.theme-black i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}
.theme-black label.label-checkbox input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="checkbox"]:checked + .item-media i.icon-form-checkbox,
.theme-black label.label-checkbox input[type="radio"]:checked + .item-media i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="radio"]:checked + .item-media i.icon-form-checkbox,
.theme-black label.label-checkbox input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="checkbox"]:checked ~ .item-after i.icon-form-checkbox,
.theme-black label.label-checkbox input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="radio"]:checked ~ .item-after i.icon-form-checkbox,
.theme-black label.label-checkbox input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="checkbox"]:checked ~ .item-inner i.icon-form-checkbox,
.theme-black label.label-checkbox input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox,
label.label-checkbox.theme-black input[type="radio"]:checked ~ .item-inner i.icon-form-checkbox {
  border-color: #000000;
  background-color: #000000;
}
.theme-black label.label-radio i.icon-form-radio:after,
label.label-radio.theme-black i.icon-form-radio:after {
  background-color: #000000;
}
.theme-black label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-black input[type="checkbox"]:checked + .item-media i.icon-form-radio,
.theme-black label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
label.label-radio.theme-black input[type="radio"]:checked + .item-media i.icon-form-radio,
.theme-black label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-black input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
.theme-black label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
label.label-radio.theme-black input[type="radio"]:checked ~ .item-after i.icon-form-radio,
.theme-black label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-black input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
.theme-black label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
label.label-radio.theme-black input[type="radio"]:checked ~ .item-inner i.icon-form-radio {
  border-color: #000000;
}
.theme-black label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-black input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
.theme-black label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
label.label-radio.theme-black input[type="radio"]:checked + .item-media i.icon-form-radio:after,
.theme-black label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-black input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
.theme-black label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
label.label-radio.theme-black input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
.theme-black label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-black input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
.theme-black label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
label.label-radio.theme-black input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after {
  background-color: #000000;
}
.theme-black .form-checkbox input[type="checkbox"]:checked ~ i,
.form-checkbox.theme-black input[type="checkbox"]:checked ~ i {
  border-color: #000000;
  background-color: #000000;
}
.theme-black .form-radio input[type="radio"]:checked ~ i,
.form-radio.theme-black input[type="radio"]:checked ~ i {
  border-color: #000000;
}
.theme-black .form-radio input[type="radio"]:checked ~ i:after,
.form-radio.theme-black input[type="radio"]:checked ~ i:after {
  background-color: #000000;
}
.theme-black .form-checkbox .ripple-wave,
.theme-black .form-radio .ripple-wave {
  background: rgba(0, 0, 0, 0.5);
}
.theme-black .focus-state .label,
.theme-black .focus-state .floating-label {
  color: #000000;
}
.theme-black .item-input-field.focus-state:after,
.theme-black .input-field.focus-state:after,
.theme-black .item-input-field.not-empty-state:after,
.theme-black .input-field.not-empty-state:after {
  background: #000000;
}
.theme-black .picker-calendar-day.picker-calendar-day-today span {
  color: #000000;
}
.theme-black .picker-calendar-day.picker-calendar-day-selected span {
  background-color: #000000;
  color: #fff;
}
.theme-black .picker-calendar-day.picker-calendar-day-has-events span:after {
  background-color: #000000;
}
.theme-black .picker-header {
  background-color: #000000;
}
.theme-black .modal-button {
  color: #000000;
}
.theme-black .timeline-horizontal .timeline-item-date,
.timeline-horizontal.theme-black .timeline-item-date {
  background-color: #000000;
}
.theme-black .timeline-year-title,
.theme-black .timeline-month-title {
  background-color: #000000;
}
.theme-black .statusbar-overlay {
  background-color: #000000;
}
.theme-black .data-table .data-table-header-selected,
.data-table.theme-black .data-table-header-selected {
  background: rgba(0, 0, 0, 0.1);
}
.theme-black .data-table .data-table-title-selected,
.data-table.theme-black .data-table-title-selected {
  color: #000000;
}
.swiper-pagination.color-black .swiper-pagination-bullet-active,
.theme-black .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000000;
}
.swiper-pagination.color-black .swiper-pagination-progressbar,
.theme-black .swiper-pagination .swiper-pagination-progressbar {
  background-color: #000000;
}
.swiper-pagination.swiper-pagination-progress.bg-black {
  background-color: rgba(0, 0, 0, 0.25);
}
.swiper-button-next.color-black,
.swiper-container-rtl .swiper-button-prev.color-black,
.theme-black .swiper-button-next,
.theme-black .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.color-black,
.swiper-container-rtl .swiper-button-next.color-black,
.theme-black .swiper-button-prev,
.theme-black .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.theme-black .floating-button,
.theme-black .speed-dial-buttons a,
.floating-button.theme-black,
.speed-dial-buttons a.theme-black {
  color: #fff;
  background: #000000;
}
html:not(.watch-active-state) .theme-black .floating-button:active,
html:not(.watch-active-state) .theme-black .speed-dial-buttons a:active,
html:not(.watch-active-state) .floating-button.theme-black:active,
html:not(.watch-active-state) .speed-dial-buttons a.theme-black:active,
.theme-black .floating-button.active-state,
.theme-black .speed-dial-buttons a.active-state,
.floating-button.theme-black.active-state,
.speed-dial-buttons a.theme-black.active-state {
  background: #333;
}
.theme-black .floating-button i,
.theme-black .speed-dial-buttons a i,
.floating-button.theme-black i,
.speed-dial-buttons a.theme-black i {
  color: inherit;
}
.theme-black .range-slider input[type="range"]::-webkit-slider-thumb:before,
.range-slider.theme-black input[type="range"]::-webkit-slider-thumb:before {
  background-color: #000000;
}
.theme-black .range-slider input[type="range"]::-webkit-slider-thumb,
.range-slider.theme-black input[type="range"]::-webkit-slider-thumb {
  background-color: #000000;
}
.theme-black .range-slider input[type="range"]::-ms-thumb,
.range-slider.theme-black input[type="range"]::-ms-thumb {
  background-color: #000000;
}
.theme-black .range-slider input[type="range"]::-ms-fill-lower,
.range-slider.theme-black input[type="range"]::-ms-fill-lower {
  background-color: #000000;
}
.theme-black .range-slider input[type="range"]::-moz-range-thumb,
.range-slider.theme-black input[type="range"]::-moz-range-thumb {
  background-color: #000000;
}
.badge.theme-black {
  background-color: #000000;
  color: #fff;
}
/*==========================
Framework7 Color + Bg + Border
==========================*/
.color-red {
  color: #f44336;
}
.list-block .item-link.list-button.color-red,
.list-button.color-red,
.list-block .item-link.color-red,
.tabbar a.active.color-red,
a.color-red {
  color: #f44336;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-red,
.label-switch.color-red input[type="checkbox"]:checked + .checkbox {
  background-color: #f44336;
}
.button.color-red:not(.button-fill),
.color-red.buttons-row .button:not(.button-fill) {
  color: #f44336 !important;
}
.button.button-fill.bg-red,
.button.button-fill.color-red {
  background: #f44336 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-red:active,
html:not(.watch-active-state) .button.button-fill.color-red:active,
.button.button-fill.bg-red.active-state,
.button.button-fill.color-red.active-state {
  background: #d32f2f !important;
}
.progressbar.color-red,
.progressbar.bg-red {
  background-color: rgba(244, 67, 54, 0.5);
}
.progressbar.color-red span,
.progressbar.bg-red span {
  background-color: #f44336;
}
.progressbar-infinite.color-red,
.progressbar-infinite.bg-red {
  background-color: rgba(244, 67, 54, 0.5);
}
.progressbar-infinite.color-red:after,
.progressbar-infinite.bg-red:after,
.progressbar-infinite.color-red:before,
.progressbar-infinite.bg-red:before {
  background-color: #f44336;
}
.color-red i.icon,
i.icon.color-red {
  color: #f44336;
}
.preloader.color-red svg circle,
.preloader.preloader-red svg circle {
  stroke: #f44336;
}
.preloader.color-red .preloader-inner-gap,
.preloader.preloader-red .preloader-inner-gap,
.preloader.color-red .preloader-inner-half-circle,
.preloader.preloader-red .preloader-inner-half-circle {
  border-color: #f44336;
}
.bg-red,
a.bg-red,
.list-block .swipeout-actions-left a.bg-red,
.list-block .swipeout-actions-right a.bg-red {
  background-color: #f44336;  
  background: var(--theme-color) !important;
}
html:not(.watch-active-state) .list-block .bg-red .item-link:active,
html:not(.watch-active-state) .list-block a.bg-red .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-red .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-red .item-link:active,
html:not(.watch-active-state) .list-block.bg-red .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-red .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-red .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-red .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-red:active,
html:not(.watch-active-state) .list-block .item-linka.bg-red:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-red:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-red:active,
.list-block .bg-red .item-link.active-state,
.list-block a.bg-red .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-red .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-red .item-link.active-state,
.list-block.bg-red .item-link.active-state,
.list-blocka.bg-red .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-red .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-red .item-link.active-state,
.list-block .item-link.bg-red.active-state,
.list-block .item-linka.bg-red.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-red.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-red.active-state {
  background: #d32f2f;
}
.border-red {
  border-color: #f44336;
}
.list-block .border-red.item-inner:after,
.list-block ul.border-red:after,
.border-red:after,
.list-block .border-red.item-inner:before,
.list-block ul.border-red:before,
.border-red:before {
  background-color: #f44336;
}
.floating-button.color-red,
.speed-dial-buttons a.color-red,
.floating-button.bg-red,
.speed-dial-buttons a.bg-red {
  color: #fff;
  background: #f44336;
}
html:not(.watch-active-state) .floating-button.color-red:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-red:active,
html:not(.watch-active-state) .floating-button.bg-red:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-red:active,
.floating-button.color-red.active-state,
.speed-dial-buttons a.color-red.active-state,
.floating-button.bg-red.active-state,
.speed-dial-buttons a.bg-red.active-state {
  background: #d32f2f;
}
.floating-button.color-red i,
.speed-dial-buttons a.color-red i,
.floating-button.bg-red i,
.speed-dial-buttons a.bg-red i {
  color: inherit;
}
.ripple-red .ripple-wave {
  background-color: rgba(244, 67, 54, 0.3);
}
.range-slider.color-red input[type="range"]::-webkit-slider-thumb:before {
  background-color: #f44336;
}
.range-slider.color-red input[type="range"]::-webkit-slider-thumb {
  background-color: #f44336;
}
.range-slider.color-red input[type="range"]::-ms-thumb {
  background-color: #f44336;
}
.range-slider.color-red input[type="range"]::-ms-fill-lower {
  background-color: #f44336;
}
.range-slider.color-red input[type="range"]::-moz-range-thumb {
  background-color: #f44336;
}
.badge.color-red {
  background-color: #f44336;
  color: #fff;
}
.color-pink {
  color: #e91e63;
}
.list-block .item-link.list-button.color-pink,
.list-button.color-pink,
.list-block .item-link.color-pink,
.tabbar a.active.color-pink,
a.color-pink {
  color: #e91e63;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-pink,
.label-switch.color-pink input[type="checkbox"]:checked + .checkbox {
  background-color: #e91e63;
}
.button.color-pink:not(.button-fill),
.color-pink.buttons-row .button:not(.button-fill) {
  color: #e91e63 !important;
}
.button.button-fill.bg-pink,
.button.button-fill.color-pink {
  background: #e91e63 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-pink:active,
html:not(.watch-active-state) .button.button-fill.color-pink:active,
.button.button-fill.bg-pink.active-state,
.button.button-fill.color-pink.active-state {
  background: #c2185b !important;
}
.progressbar.color-pink,
.progressbar.bg-pink {
  background-color: rgba(233, 30, 99, 0.5);
}
.progressbar.color-pink span,
.progressbar.bg-pink span {
  background-color: #e91e63;
}
.progressbar-infinite.color-pink,
.progressbar-infinite.bg-pink {
  background-color: rgba(233, 30, 99, 0.5);
}
.progressbar-infinite.color-pink:after,
.progressbar-infinite.bg-pink:after,
.progressbar-infinite.color-pink:before,
.progressbar-infinite.bg-pink:before {
  background-color: #e91e63;
}
.color-pink i.icon,
i.icon.color-pink {
  color: #e91e63;
}
.preloader.color-pink svg circle,
.preloader.preloader-pink svg circle {
  stroke: #e91e63;
}
.preloader.color-pink .preloader-inner-gap,
.preloader.preloader-pink .preloader-inner-gap,
.preloader.color-pink .preloader-inner-half-circle,
.preloader.preloader-pink .preloader-inner-half-circle {
  border-color: #e91e63;
}
.bg-pink,
a.bg-pink,
.list-block .swipeout-actions-left a.bg-pink,
.list-block .swipeout-actions-right a.bg-pink {
  background-color: #e91e63;
  background: #e91e63 !important;
  
}
html:not(.watch-active-state) .list-block .bg-pink .item-link:active,
html:not(.watch-active-state) .list-block a.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block.bg-pink .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-pink .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-pink:active,
html:not(.watch-active-state) .list-block .item-linka.bg-pink:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-pink:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-pink:active,
.list-block .bg-pink .item-link.active-state,
.list-block a.bg-pink .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-pink .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-pink .item-link.active-state,
.list-block.bg-pink .item-link.active-state,
.list-blocka.bg-pink .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-pink .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-pink .item-link.active-state,
.list-block .item-link.bg-pink.active-state,
.list-block .item-linka.bg-pink.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-pink.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-pink.active-state {
  background: #c2185b;
}
.border-pink {
  border-color: #e91e63;
}
.list-block .border-pink.item-inner:after,
.list-block ul.border-pink:after,
.border-pink:after,
.list-block .border-pink.item-inner:before,
.list-block ul.border-pink:before,
.border-pink:before {
  background-color: #e91e63;
}
.floating-button.color-pink,
.speed-dial-buttons a.color-pink,
.floating-button.bg-pink,
.speed-dial-buttons a.bg-pink {
  color: #fff;
  background: #e91e63;
}
html:not(.watch-active-state) .floating-button.color-pink:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-pink:active,
html:not(.watch-active-state) .floating-button.bg-pink:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-pink:active,
.floating-button.color-pink.active-state,
.speed-dial-buttons a.color-pink.active-state,
.floating-button.bg-pink.active-state,
.speed-dial-buttons a.bg-pink.active-state {
  background: #c2185b;
}
.floating-button.color-pink i,
.speed-dial-buttons a.color-pink i,
.floating-button.bg-pink i,
.speed-dial-buttons a.bg-pink i {
  color: inherit;
}
.ripple-pink .ripple-wave {
  background-color: rgba(233, 30, 99, 0.3);
}
.range-slider.color-pink input[type="range"]::-webkit-slider-thumb:before {
  background-color: #e91e63;
}
.range-slider.color-pink input[type="range"]::-webkit-slider-thumb {
  background-color: #e91e63;
}
.range-slider.color-pink input[type="range"]::-ms-thumb {
  background-color: #e91e63;
}
.range-slider.color-pink input[type="range"]::-ms-fill-lower {
  background-color: #e91e63;
}
.range-slider.color-pink input[type="range"]::-moz-range-thumb {
  background-color: #e91e63;
}
.badge.color-pink {
  background-color: #e91e63;
  color: #fff;
}
.color-purple {
  color: #9c27b0;
}
.list-block .item-link.list-button.color-purple,
.list-button.color-purple,
.list-block .item-link.color-purple,
.tabbar a.active.color-purple,
a.color-purple {
  color: #9c27b0;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-purple,
.label-switch.color-purple input[type="checkbox"]:checked + .checkbox {
  background-color: #9c27b0;
}
.button.color-purple:not(.button-fill),
.color-purple.buttons-row .button:not(.button-fill) {
  color: #9c27b0 !important;
}
.button.button-fill.bg-purple,
.button.button-fill.color-purple {
  background: #9c27b0 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-purple:active,
html:not(.watch-active-state) .button.button-fill.color-purple:active,
.button.button-fill.bg-purple.active-state,
.button.button-fill.color-purple.active-state {
  background: #7b1fa2 !important;
}
.progressbar.color-purple,
.progressbar.bg-purple {
  background-color: rgba(156, 39, 176, 0.5);
}
.progressbar.color-purple span,
.progressbar.bg-purple span {
  background-color: #9c27b0;
}
.progressbar-infinite.color-purple,
.progressbar-infinite.bg-purple {
  background-color: rgba(156, 39, 176, 0.5);
}
.progressbar-infinite.color-purple:after,
.progressbar-infinite.bg-purple:after,
.progressbar-infinite.color-purple:before,
.progressbar-infinite.bg-purple:before {
  background-color: #9c27b0;
}
.color-purple i.icon,
i.icon.color-purple {
  color: #9c27b0;
}
.preloader.color-purple svg circle,
.preloader.preloader-purple svg circle {
  stroke: #9c27b0;
}
.preloader.color-purple .preloader-inner-gap,
.preloader.preloader-purple .preloader-inner-gap,
.preloader.color-purple .preloader-inner-half-circle,
.preloader.preloader-purple .preloader-inner-half-circle {
  border-color: #9c27b0;
}
.bg-purple,
a.bg-purple,
.list-block .swipeout-actions-left a.bg-purple,
.list-block .swipeout-actions-right a.bg-purple {
  background-color: #9c27b0;
  background: #9c27b0 !important;
}
html:not(.watch-active-state) .list-block .bg-purple .item-link:active,
html:not(.watch-active-state) .list-block a.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block.bg-purple .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-purple .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-purple:active,
html:not(.watch-active-state) .list-block .item-linka.bg-purple:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-purple:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-purple:active,
.list-block .bg-purple .item-link.active-state,
.list-block a.bg-purple .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-purple .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-purple .item-link.active-state,
.list-block.bg-purple .item-link.active-state,
.list-blocka.bg-purple .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-purple .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-purple .item-link.active-state,
.list-block .item-link.bg-purple.active-state,
.list-block .item-linka.bg-purple.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-purple.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-purple.active-state {
  background: #7b1fa2;
}
.border-purple {
  border-color: #9c27b0;
}
.list-block .border-purple.item-inner:after,
.list-block ul.border-purple:after,
.border-purple:after,
.list-block .border-purple.item-inner:before,
.list-block ul.border-purple:before,
.border-purple:before {
  background-color: #9c27b0;
}
.floating-button.color-purple,
.speed-dial-buttons a.color-purple,
.floating-button.bg-purple,
.speed-dial-buttons a.bg-purple {
  color: #fff;
  background: #9c27b0;
}
html:not(.watch-active-state) .floating-button.color-purple:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-purple:active,
html:not(.watch-active-state) .floating-button.bg-purple:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-purple:active,
.floating-button.color-purple.active-state,
.speed-dial-buttons a.color-purple.active-state,
.floating-button.bg-purple.active-state,
.speed-dial-buttons a.bg-purple.active-state {
  background: #7b1fa2;
}
.floating-button.color-purple i,
.speed-dial-buttons a.color-purple i,
.floating-button.bg-purple i,
.speed-dial-buttons a.bg-purple i {
  color: inherit;
}
.ripple-purple .ripple-wave {
  background-color: rgba(156, 39, 176, 0.3);
}
.range-slider.color-purple input[type="range"]::-webkit-slider-thumb:before {
  background-color: #9c27b0;
}
.range-slider.color-purple input[type="range"]::-webkit-slider-thumb {
  background-color: #9c27b0;
}
.range-slider.color-purple input[type="range"]::-ms-thumb {
  background-color: #9c27b0;
}
.range-slider.color-purple input[type="range"]::-ms-fill-lower {
  background-color: #9c27b0;
}
.range-slider.color-purple input[type="range"]::-moz-range-thumb {
  background-color: #9c27b0;
}
.badge.color-purple {
  background-color: #9c27b0;
  color: #fff;
}
.color-deeppurple {
  color: #673ab7;
}
.list-block .item-link.list-button.color-deeppurple,
.list-button.color-deeppurple,
.list-block .item-link.color-deeppurple,
.tabbar a.active.color-deeppurple,
a.color-deeppurple {
  color: #673ab7;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-deeppurple,
.label-switch.color-deeppurple input[type="checkbox"]:checked + .checkbox {
  background-color: #673ab7;
}
.button.color-deeppurple:not(.button-fill),
.color-deeppurple.buttons-row .button:not(.button-fill) {
  color: #673ab7 !important;
}
.button.button-fill.bg-deeppurple,
.button.button-fill.color-deeppurple {
  background: #673ab7 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-deeppurple:active,
html:not(.watch-active-state) .button.button-fill.color-deeppurple:active,
.button.button-fill.bg-deeppurple.active-state,
.button.button-fill.color-deeppurple.active-state {
  background: #512da8 !important;
}
.progressbar.color-deeppurple,
.progressbar.bg-deeppurple {
  background-color: rgba(103, 58, 183, 0.5);
}
.progressbar.color-deeppurple span,
.progressbar.bg-deeppurple span {
  background-color: #673ab7;
}
.progressbar-infinite.color-deeppurple,
.progressbar-infinite.bg-deeppurple {
  background-color: rgba(103, 58, 183, 0.5);
}
.progressbar-infinite.color-deeppurple:after,
.progressbar-infinite.bg-deeppurple:after,
.progressbar-infinite.color-deeppurple:before,
.progressbar-infinite.bg-deeppurple:before {
  background-color: #673ab7;
}
.color-deeppurple i.icon,
i.icon.color-deeppurple {
  color: #673ab7;
}
.preloader.color-deeppurple svg circle,
.preloader.preloader-deeppurple svg circle {
  stroke: #673ab7;
}
.preloader.color-deeppurple .preloader-inner-gap,
.preloader.preloader-deeppurple .preloader-inner-gap,
.preloader.color-deeppurple .preloader-inner-half-circle,
.preloader.preloader-deeppurple .preloader-inner-half-circle {
  border-color: #673ab7;
}
.bg-deeppurple,
a.bg-deeppurple,
.list-block .swipeout-actions-left a.bg-deeppurple,
.list-block .swipeout-actions-right a.bg-deeppurple {
  background-color: #673ab7;
  background: #673ab7 !important;
}
html:not(.watch-active-state) .list-block .bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block a.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-deeppurple .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-deeppurple:active,
html:not(.watch-active-state) .list-block .item-linka.bg-deeppurple:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-deeppurple:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-deeppurple:active,
.list-block .bg-deeppurple .item-link.active-state,
.list-block a.bg-deeppurple .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-deeppurple .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-deeppurple .item-link.active-state,
.list-block.bg-deeppurple .item-link.active-state,
.list-blocka.bg-deeppurple .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-deeppurple .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-deeppurple .item-link.active-state,
.list-block .item-link.bg-deeppurple.active-state,
.list-block .item-linka.bg-deeppurple.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-deeppurple.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-deeppurple.active-state {
  background: #512da8;
}
.border-deeppurple {
  border-color: #673ab7;
}
.list-block .border-deeppurple.item-inner:after,
.list-block ul.border-deeppurple:after,
.border-deeppurple:after,
.list-block .border-deeppurple.item-inner:before,
.list-block ul.border-deeppurple:before,
.border-deeppurple:before {
  background-color: #673ab7;
}
.floating-button.color-deeppurple,
.speed-dial-buttons a.color-deeppurple,
.floating-button.bg-deeppurple,
.speed-dial-buttons a.bg-deeppurple {
  color: #fff;
  background: #673ab7;
}
html:not(.watch-active-state) .floating-button.color-deeppurple:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-deeppurple:active,
html:not(.watch-active-state) .floating-button.bg-deeppurple:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-deeppurple:active,
.floating-button.color-deeppurple.active-state,
.speed-dial-buttons a.color-deeppurple.active-state,
.floating-button.bg-deeppurple.active-state,
.speed-dial-buttons a.bg-deeppurple.active-state {
  background: #512da8;
}
.floating-button.color-deeppurple i,
.speed-dial-buttons a.color-deeppurple i,
.floating-button.bg-deeppurple i,
.speed-dial-buttons a.bg-deeppurple i {
  color: inherit;
}
.ripple-deeppurple .ripple-wave {
  background-color: rgba(103, 58, 183, 0.3);
}
.range-slider.color-deeppurple input[type="range"]::-webkit-slider-thumb:before {
  background-color: #673ab7;
}
.range-slider.color-deeppurple input[type="range"]::-webkit-slider-thumb {
  background-color: #673ab7;
}
.range-slider.color-deeppurple input[type="range"]::-ms-thumb {
  background-color: #673ab7;
}
.range-slider.color-deeppurple input[type="range"]::-ms-fill-lower {
  background-color: #673ab7;
}
.range-slider.color-deeppurple input[type="range"]::-moz-range-thumb {
  background-color: #673ab7;
}
.badge.color-deeppurple {
  background-color: #673ab7;
  color: #fff;
}
.color-indigo {
  color: #3f51b5;
}
.list-block .item-link.list-button.color-indigo,
.list-button.color-indigo,
.list-block .item-link.color-indigo,
.tabbar a.active.color-indigo,
a.color-indigo {
  color: #3f51b5;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-indigo,
.label-switch.color-indigo input[type="checkbox"]:checked + .checkbox {
  background-color: #3f51b5;
}
.button.color-indigo:not(.button-fill),
.color-indigo.buttons-row .button:not(.button-fill) {
  color: #3f51b5 !important;
}
.button.button-fill.bg-indigo,
.button.button-fill.color-indigo {
  background: #3f51b5 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-indigo:active,
html:not(.watch-active-state) .button.button-fill.color-indigo:active,
.button.button-fill.bg-indigo.active-state,
.button.button-fill.color-indigo.active-state {
  background: #303f9f !important;
}
.progressbar.color-indigo,
.progressbar.bg-indigo {
  background-color: rgba(63, 81, 181, 0.5);
}
.progressbar.color-indigo span,
.progressbar.bg-indigo span {
  background-color: #3f51b5;
}
.progressbar-infinite.color-indigo,
.progressbar-infinite.bg-indigo {
  background-color: rgba(63, 81, 181, 0.5);
}
.progressbar-infinite.color-indigo:after,
.progressbar-infinite.bg-indigo:after,
.progressbar-infinite.color-indigo:before,
.progressbar-infinite.bg-indigo:before {
  background-color: #3f51b5;
}
.color-indigo i.icon,
i.icon.color-indigo {
  color: #3f51b5;
}
.preloader.color-indigo svg circle,
.preloader.preloader-indigo svg circle {
  stroke: #3f51b5;
}
.preloader.color-indigo .preloader-inner-gap,
.preloader.preloader-indigo .preloader-inner-gap,
.preloader.color-indigo .preloader-inner-half-circle,
.preloader.preloader-indigo .preloader-inner-half-circle {
  border-color: #3f51b5;
}
.bg-indigo,
a.bg-indigo,
.list-block .swipeout-actions-left a.bg-indigo,
.list-block .swipeout-actions-right a.bg-indigo {
  background-color: #3f51b5;
  background: #3f51b5 !important;
}
html:not(.watch-active-state) .list-block .bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block a.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-indigo .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-indigo:active,
html:not(.watch-active-state) .list-block .item-linka.bg-indigo:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-indigo:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-indigo:active,
.list-block .bg-indigo .item-link.active-state,
.list-block a.bg-indigo .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-indigo .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-indigo .item-link.active-state,
.list-block.bg-indigo .item-link.active-state,
.list-blocka.bg-indigo .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-indigo .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-indigo .item-link.active-state,
.list-block .item-link.bg-indigo.active-state,
.list-block .item-linka.bg-indigo.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-indigo.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-indigo.active-state {
  background: #303f9f;
}
.border-indigo {
  border-color: #3f51b5;
}
.list-block .border-indigo.item-inner:after,
.list-block ul.border-indigo:after,
.border-indigo:after,
.list-block .border-indigo.item-inner:before,
.list-block ul.border-indigo:before,
.border-indigo:before {
  background-color: #3f51b5;
}
.floating-button.color-indigo,
.speed-dial-buttons a.color-indigo,
.floating-button.bg-indigo,
.speed-dial-buttons a.bg-indigo {
  color: #fff;
  background: #3f51b5;
}
html:not(.watch-active-state) .floating-button.color-indigo:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-indigo:active,
html:not(.watch-active-state) .floating-button.bg-indigo:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-indigo:active,
.floating-button.color-indigo.active-state,
.speed-dial-buttons a.color-indigo.active-state,
.floating-button.bg-indigo.active-state,
.speed-dial-buttons a.bg-indigo.active-state {
  background: #303f9f;
}
.floating-button.color-indigo i,
.speed-dial-buttons a.color-indigo i,
.floating-button.bg-indigo i,
.speed-dial-buttons a.bg-indigo i {
  color: inherit;
}
.ripple-indigo .ripple-wave {
  background-color: rgba(63, 81, 181, 0.3);
}
.range-slider.color-indigo input[type="range"]::-webkit-slider-thumb:before {
  background-color: #3f51b5;
}
.range-slider.color-indigo input[type="range"]::-webkit-slider-thumb {
  background-color: #3f51b5;
}
.range-slider.color-indigo input[type="range"]::-ms-thumb {
  background-color: #3f51b5;
}
.range-slider.color-indigo input[type="range"]::-ms-fill-lower {
  background-color: #3f51b5;
}
.range-slider.color-indigo input[type="range"]::-moz-range-thumb {
  background-color: #3f51b5;
}
.badge.color-indigo {
  background-color: #3f51b5;
  color: #fff;
}
.color-blue {
  color: #2196f3;
}
.list-block .item-link.list-button.color-blue,
.list-button.color-blue,
.list-block .item-link.color-blue,
.tabbar a.active.color-blue,
a.color-blue {
  color: #2196f3;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-blue,
.label-switch.color-blue input[type="checkbox"]:checked + .checkbox {
  background-color: #2196f3;
}
.button.color-blue:not(.button-fill),
.color-blue.buttons-row .button:not(.button-fill) {
  color: #2196f3 !important;
}
.button.button-fill.bg-blue,
.button.button-fill.color-blue {
  background: #2196f3 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-blue:active,
html:not(.watch-active-state) .button.button-fill.color-blue:active,
.button.button-fill.bg-blue.active-state,
.button.button-fill.color-blue.active-state {
  background: #1976d2 !important;
}
.progressbar.color-blue,
.progressbar.bg-blue {
  background-color: rgba(33, 150, 243, 0.5);
}
.progressbar.color-blue span,
.progressbar.bg-blue span {
  background-color: #2196f3;
}
.progressbar-infinite.color-blue,
.progressbar-infinite.bg-blue {
  background-color: rgba(33, 150, 243, 0.5);
}
.progressbar-infinite.color-blue:after,
.progressbar-infinite.bg-blue:after,
.progressbar-infinite.color-blue:before,
.progressbar-infinite.bg-blue:before {
  background-color: #2196f3;
}
.color-blue i.icon,
i.icon.color-blue {
  color: #2196f3;
}
.preloader.color-blue svg circle,
.preloader.preloader-blue svg circle {
  stroke: #2196f3;
}
.preloader.color-blue .preloader-inner-gap,
.preloader.preloader-blue .preloader-inner-gap,
.preloader.color-blue .preloader-inner-half-circle,
.preloader.preloader-blue .preloader-inner-half-circle {
  border-color: #2196f3;
}
.bg-blue,
a.bg-blue,
.list-block .swipeout-actions-left a.bg-blue,
.list-block .swipeout-actions-right a.bg-blue {
  background-color: #2196f3;
  background: #2196f3 !important;
}
html:not(.watch-active-state) .list-block .bg-blue .item-link:active,
html:not(.watch-active-state) .list-block a.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block.bg-blue .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-blue .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-blue:active,
html:not(.watch-active-state) .list-block .item-linka.bg-blue:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-blue:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-blue:active,
.list-block .bg-blue .item-link.active-state,
.list-block a.bg-blue .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-blue .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-blue .item-link.active-state,
.list-block.bg-blue .item-link.active-state,
.list-blocka.bg-blue .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-blue .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-blue .item-link.active-state,
.list-block .item-link.bg-blue.active-state,
.list-block .item-linka.bg-blue.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-blue.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-blue.active-state {
  background: #1976d2;
}
.border-blue {
  border-color: #2196f3;
}
.list-block .border-blue.item-inner:after,
.list-block ul.border-blue:after,
.border-blue:after,
.list-block .border-blue.item-inner:before,
.list-block ul.border-blue:before,
.border-blue:before {
  background-color: #2196f3;
}
.floating-button.color-blue,
.speed-dial-buttons a.color-blue,
.floating-button.bg-blue,
.speed-dial-buttons a.bg-blue {
  color: #fff;
  background: #2196f3;
}
html:not(.watch-active-state) .floating-button.color-blue:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-blue:active,
html:not(.watch-active-state) .floating-button.bg-blue:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-blue:active,
.floating-button.color-blue.active-state,
.speed-dial-buttons a.color-blue.active-state,
.floating-button.bg-blue.active-state,
.speed-dial-buttons a.bg-blue.active-state {
  background: #1976d2;
}
.floating-button.color-blue i,
.speed-dial-buttons a.color-blue i,
.floating-button.bg-blue i,
.speed-dial-buttons a.bg-blue i {
  color: inherit;
}
.ripple-blue .ripple-wave {
  background-color: rgba(33, 150, 243, 0.3);
}
.range-slider.color-blue input[type="range"]::-webkit-slider-thumb:before {
  background-color: #2196f3;
}
.range-slider.color-blue input[type="range"]::-webkit-slider-thumb {
  background-color: #2196f3;
}
.range-slider.color-blue input[type="range"]::-ms-thumb {
  background-color: #2196f3;
}
.range-slider.color-blue input[type="range"]::-ms-fill-lower {
  background-color: #2196f3;
}
.range-slider.color-blue input[type="range"]::-moz-range-thumb {
  background-color: #2196f3;
}
.badge.color-blue {
  background-color: #2196f3;
  color: #fff;
}
.color-lightblue {
  color: #03a9f4;
}
.list-block .item-link.list-button.color-lightblue,
.list-button.color-lightblue,
.list-block .item-link.color-lightblue,
.tabbar a.active.color-lightblue,
a.color-lightblue {
  color: #03a9f4;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-lightblue,
.label-switch.color-lightblue input[type="checkbox"]:checked + .checkbox {
  background-color: #03a9f4;
}
.button.color-lightblue:not(.button-fill),
.color-lightblue.buttons-row .button:not(.button-fill) {
  color: #03a9f4 !important;
}
.button.button-fill.bg-lightblue,
.button.button-fill.color-lightblue {
  background: #03a9f4 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-lightblue:active,
html:not(.watch-active-state) .button.button-fill.color-lightblue:active,
.button.button-fill.bg-lightblue.active-state,
.button.button-fill.color-lightblue.active-state {
  background: #0288d1 !important;
}
.progressbar.color-lightblue,
.progressbar.bg-lightblue {
  background-color: rgba(3, 169, 244, 0.5);
}
.progressbar.color-lightblue span,
.progressbar.bg-lightblue span {
  background-color: #03a9f4;
}
.progressbar-infinite.color-lightblue,
.progressbar-infinite.bg-lightblue {
  background-color: rgba(3, 169, 244, 0.5);
}
.progressbar-infinite.color-lightblue:after,
.progressbar-infinite.bg-lightblue:after,
.progressbar-infinite.color-lightblue:before,
.progressbar-infinite.bg-lightblue:before {
  background-color: #03a9f4;
}
.color-lightblue i.icon,
i.icon.color-lightblue {
  color: #03a9f4;
}
.preloader.color-lightblue svg circle,
.preloader.preloader-lightblue svg circle {
  stroke: #03a9f4;
}
.preloader.color-lightblue .preloader-inner-gap,
.preloader.preloader-lightblue .preloader-inner-gap,
.preloader.color-lightblue .preloader-inner-half-circle,
.preloader.preloader-lightblue .preloader-inner-half-circle {
  border-color: #03a9f4;
}
.bg-lightblue,
a.bg-lightblue,
.list-block .swipeout-actions-left a.bg-lightblue,
.list-block .swipeout-actions-right a.bg-lightblue {
  background-color: #03a9f4;
  background: #03a9f4  !important;
}
html:not(.watch-active-state) .list-block .bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block a.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-lightblue .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-lightblue:active,
html:not(.watch-active-state) .list-block .item-linka.bg-lightblue:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-lightblue:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-lightblue:active,
.list-block .bg-lightblue .item-link.active-state,
.list-block a.bg-lightblue .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-lightblue .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-lightblue .item-link.active-state,
.list-block.bg-lightblue .item-link.active-state,
.list-blocka.bg-lightblue .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-lightblue .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-lightblue .item-link.active-state,
.list-block .item-link.bg-lightblue.active-state,
.list-block .item-linka.bg-lightblue.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-lightblue.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-lightblue.active-state {
  background: #0288d1;
}
.border-lightblue {
  border-color: #03a9f4;
}
.list-block .border-lightblue.item-inner:after,
.list-block ul.border-lightblue:after,
.border-lightblue:after,
.list-block .border-lightblue.item-inner:before,
.list-block ul.border-lightblue:before,
.border-lightblue:before {
  background-color: #03a9f4;
}
.floating-button.color-lightblue,
.speed-dial-buttons a.color-lightblue,
.floating-button.bg-lightblue,
.speed-dial-buttons a.bg-lightblue {
  color: #fff;
  background: #03a9f4;
}
html:not(.watch-active-state) .floating-button.color-lightblue:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-lightblue:active,
html:not(.watch-active-state) .floating-button.bg-lightblue:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-lightblue:active,
.floating-button.color-lightblue.active-state,
.speed-dial-buttons a.color-lightblue.active-state,
.floating-button.bg-lightblue.active-state,
.speed-dial-buttons a.bg-lightblue.active-state {
  background: #0288d1;
}
.floating-button.color-lightblue i,
.speed-dial-buttons a.color-lightblue i,
.floating-button.bg-lightblue i,
.speed-dial-buttons a.bg-lightblue i {
  color: inherit;
}
.ripple-lightblue .ripple-wave {
  background-color: rgba(3, 169, 244, 0.3);
}
.range-slider.color-lightblue input[type="range"]::-webkit-slider-thumb:before {
  background-color: #03a9f4;
}
.range-slider.color-lightblue input[type="range"]::-webkit-slider-thumb {
  background-color: #03a9f4;
}
.range-slider.color-lightblue input[type="range"]::-ms-thumb {
  background-color: #03a9f4;
}
.range-slider.color-lightblue input[type="range"]::-ms-fill-lower {
  background-color: #03a9f4;
}
.range-slider.color-lightblue input[type="range"]::-moz-range-thumb {
  background-color: #03a9f4;
}
.badge.color-lightblue {
  background-color: #03a9f4;
  color: #fff;
}
.color-cyan {
  color: #00bcd4;
}
.list-block .item-link.list-button.color-cyan,
.list-button.color-cyan,
.list-block .item-link.color-cyan,
.tabbar a.active.color-cyan,
a.color-cyan {
  color: #00bcd4;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-cyan,
.label-switch.color-cyan input[type="checkbox"]:checked + .checkbox {
  background-color: #00bcd4;
}
.button.color-cyan:not(.button-fill),
.color-cyan.buttons-row .button:not(.button-fill) {
  color: #00bcd4 !important;
}
.button.button-fill.bg-cyan,
.button.button-fill.color-cyan {
  background: #00bcd4 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-cyan:active,
html:not(.watch-active-state) .button.button-fill.color-cyan:active,
.button.button-fill.bg-cyan.active-state,
.button.button-fill.color-cyan.active-state {
  background: #0097a7 !important;
}
.progressbar.color-cyan,
.progressbar.bg-cyan {
  background-color: rgba(0, 188, 212, 0.5);
}
.progressbar.color-cyan span,
.progressbar.bg-cyan span {
  background-color: #00bcd4;
}
.progressbar-infinite.color-cyan,
.progressbar-infinite.bg-cyan {
  background-color: rgba(0, 188, 212, 0.5);
}
.progressbar-infinite.color-cyan:after,
.progressbar-infinite.bg-cyan:after,
.progressbar-infinite.color-cyan:before,
.progressbar-infinite.bg-cyan:before {
  background-color: #00bcd4;
}
.color-cyan i.icon,
i.icon.color-cyan {
  color: #00bcd4;
}
.preloader.color-cyan svg circle,
.preloader.preloader-cyan svg circle {
  stroke: #00bcd4;
}
.preloader.color-cyan .preloader-inner-gap,
.preloader.preloader-cyan .preloader-inner-gap,
.preloader.color-cyan .preloader-inner-half-circle,
.preloader.preloader-cyan .preloader-inner-half-circle {
  border-color: #00bcd4;
}
.bg-cyan,
a.bg-cyan,
.list-block .swipeout-actions-left a.bg-cyan,
.list-block .swipeout-actions-right a.bg-cyan {
  background-color: #00bcd4;
  background: #00bcd4 !important;
}
html:not(.watch-active-state) .list-block .bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block a.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-cyan .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-cyan:active,
html:not(.watch-active-state) .list-block .item-linka.bg-cyan:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-cyan:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-cyan:active,
.list-block .bg-cyan .item-link.active-state,
.list-block a.bg-cyan .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-cyan .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-cyan .item-link.active-state,
.list-block.bg-cyan .item-link.active-state,
.list-blocka.bg-cyan .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-cyan .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-cyan .item-link.active-state,
.list-block .item-link.bg-cyan.active-state,
.list-block .item-linka.bg-cyan.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-cyan.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-cyan.active-state {
  background: #0097a7;
}
.border-cyan {
  border-color: #00bcd4;
}
.list-block .border-cyan.item-inner:after,
.list-block ul.border-cyan:after,
.border-cyan:after,
.list-block .border-cyan.item-inner:before,
.list-block ul.border-cyan:before,
.border-cyan:before {
  background-color: #00bcd4;
}
.floating-button.color-cyan,
.speed-dial-buttons a.color-cyan,
.floating-button.bg-cyan,
.speed-dial-buttons a.bg-cyan {
  color: #fff;
  background: #00bcd4;
}
html:not(.watch-active-state) .floating-button.color-cyan:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-cyan:active,
html:not(.watch-active-state) .floating-button.bg-cyan:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-cyan:active,
.floating-button.color-cyan.active-state,
.speed-dial-buttons a.color-cyan.active-state,
.floating-button.bg-cyan.active-state,
.speed-dial-buttons a.bg-cyan.active-state {
  background: #0097a7;
}
.floating-button.color-cyan i,
.speed-dial-buttons a.color-cyan i,
.floating-button.bg-cyan i,
.speed-dial-buttons a.bg-cyan i {
  color: inherit;
}
.ripple-cyan .ripple-wave {
  background-color: rgba(0, 188, 212, 0.3);
}
.range-slider.color-cyan input[type="range"]::-webkit-slider-thumb:before {
  background-color: #00bcd4;
}
.range-slider.color-cyan input[type="range"]::-webkit-slider-thumb {
  background-color: #00bcd4;
}
.range-slider.color-cyan input[type="range"]::-ms-thumb {
  background-color: #00bcd4;
}
.range-slider.color-cyan input[type="range"]::-ms-fill-lower {
  background-color: #00bcd4;
}
.range-slider.color-cyan input[type="range"]::-moz-range-thumb {
  background-color: #00bcd4;
}
.badge.color-cyan {
  background-color: #00bcd4;
  color: #fff;
}
.color-teal {
  color: #009688;
}
.list-block .item-link.list-button.color-teal,
.list-button.color-teal,
.list-block .item-link.color-teal,
.tabbar a.active.color-teal,
a.color-teal {
  color: #009688;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-teal,
.label-switch.color-teal input[type="checkbox"]:checked + .checkbox {
  background-color: #009688;
}
.button.color-teal:not(.button-fill),
.color-teal.buttons-row .button:not(.button-fill) {
  color: #009688 !important;
}
.button.button-fill.bg-teal,
.button.button-fill.color-teal {
  background: #009688 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-teal:active,
html:not(.watch-active-state) .button.button-fill.color-teal:active,
.button.button-fill.bg-teal.active-state,
.button.button-fill.color-teal.active-state {
  background: #00897b !important;
}
.progressbar.color-teal,
.progressbar.bg-teal {
  background-color: rgba(0, 150, 136, 0.5);
}
.progressbar.color-teal span,
.progressbar.bg-teal span {
  background-color: #009688;
}
.progressbar-infinite.color-teal,
.progressbar-infinite.bg-teal {
  background-color: rgba(0, 150, 136, 0.5);
}
.progressbar-infinite.color-teal:after,
.progressbar-infinite.bg-teal:after,
.progressbar-infinite.color-teal:before,
.progressbar-infinite.bg-teal:before {
  background-color: #009688;
}
.color-teal i.icon,
i.icon.color-teal {
  color: #009688;
}
.preloader.color-teal svg circle,
.preloader.preloader-teal svg circle {
  stroke: #009688;
}
.preloader.color-teal .preloader-inner-gap,
.preloader.preloader-teal .preloader-inner-gap,
.preloader.color-teal .preloader-inner-half-circle,
.preloader.preloader-teal .preloader-inner-half-circle {
  border-color: #009688;
}
.bg-teal,
a.bg-teal,
.list-block .swipeout-actions-left a.bg-teal,
.list-block .swipeout-actions-right a.bg-teal {
  background-color: #009688;
  background: #009688 !important;
}
html:not(.watch-active-state) .list-block .bg-teal .item-link:active,
html:not(.watch-active-state) .list-block a.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block.bg-teal .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-teal .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-teal:active,
html:not(.watch-active-state) .list-block .item-linka.bg-teal:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-teal:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-teal:active,
.list-block .bg-teal .item-link.active-state,
.list-block a.bg-teal .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-teal .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-teal .item-link.active-state,
.list-block.bg-teal .item-link.active-state,
.list-blocka.bg-teal .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-teal .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-teal .item-link.active-state,
.list-block .item-link.bg-teal.active-state,
.list-block .item-linka.bg-teal.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-teal.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-teal.active-state {
  background: #00897b;
}
.border-teal {
  border-color: #009688;
}
.list-block .border-teal.item-inner:after,
.list-block ul.border-teal:after,
.border-teal:after,
.list-block .border-teal.item-inner:before,
.list-block ul.border-teal:before,
.border-teal:before {
  background-color: #009688;
}
.floating-button.color-teal,
.speed-dial-buttons a.color-teal,
.floating-button.bg-teal,
.speed-dial-buttons a.bg-teal {
  color: #fff;
  background: #009688;
}
html:not(.watch-active-state) .floating-button.color-teal:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-teal:active,
html:not(.watch-active-state) .floating-button.bg-teal:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-teal:active,
.floating-button.color-teal.active-state,
.speed-dial-buttons a.color-teal.active-state,
.floating-button.bg-teal.active-state,
.speed-dial-buttons a.bg-teal.active-state {
  background: #00897b;
}
.floating-button.color-teal i,
.speed-dial-buttons a.color-teal i,
.floating-button.bg-teal i,
.speed-dial-buttons a.bg-teal i {
  color: inherit;
}
.ripple-teal .ripple-wave {
  background-color: rgba(0, 150, 136, 0.3);
}
.range-slider.color-teal input[type="range"]::-webkit-slider-thumb:before {
  background-color: #009688;
}
.range-slider.color-teal input[type="range"]::-webkit-slider-thumb {
  background-color: #009688;
}
.range-slider.color-teal input[type="range"]::-ms-thumb {
  background-color: #009688;
}
.range-slider.color-teal input[type="range"]::-ms-fill-lower {
  background-color: #009688;
}
.range-slider.color-teal input[type="range"]::-moz-range-thumb {
  background-color: #009688;
}
.badge.color-teal {
  background-color: #009688;
  color: #fff;
}
.color-green {
  color: #4caf50;
}
.list-block .item-link.list-button.color-green,
.list-button.color-green,
.list-block .item-link.color-green,
.tabbar a.active.color-green,
a.color-green {
  color: #4caf50;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-green,
.label-switch.color-green input[type="checkbox"]:checked + .checkbox {
  background-color: #4caf50;
}
.button.color-green:not(.button-fill),
.color-green.buttons-row .button:not(.button-fill) {
  color: #4caf50 !important;
}
.button.button-fill.bg-green,
.button.button-fill.color-green {
  background: #4caf50 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-green:active,
html:not(.watch-active-state) .button.button-fill.color-green:active,
.button.button-fill.bg-green.active-state,
.button.button-fill.color-green.active-state {
  background: #388e3c !important;
}
.progressbar.color-green,
.progressbar.bg-green {
  background-color: rgba(76, 175, 80, 0.5);
}
.progressbar.color-green span,
.progressbar.bg-green span {
  background-color: #4caf50;
}
.progressbar-infinite.color-green,
.progressbar-infinite.bg-green {
  background-color: rgba(76, 175, 80, 0.5);
}
.progressbar-infinite.color-green:after,
.progressbar-infinite.bg-green:after,
.progressbar-infinite.color-green:before,
.progressbar-infinite.bg-green:before {
  background-color: #4caf50;
}
.color-green i.icon,
i.icon.color-green {
  color: #4caf50;
}
.preloader.color-green svg circle,
.preloader.preloader-green svg circle {
  stroke: #4caf50;
}
.preloader.color-green .preloader-inner-gap,
.preloader.preloader-green .preloader-inner-gap,
.preloader.color-green .preloader-inner-half-circle,
.preloader.preloader-green .preloader-inner-half-circle {
  border-color: #4caf50;
}
.bg-green,
a.bg-green,
.list-block .swipeout-actions-left a.bg-green,
.list-block .swipeout-actions-right a.bg-green {
  background-color: #4caf50;
  background: #4caf50 !important;
}
html:not(.watch-active-state) .list-block .bg-green .item-link:active,
html:not(.watch-active-state) .list-block a.bg-green .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-green .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-green .item-link:active,
html:not(.watch-active-state) .list-block.bg-green .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-green .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-green .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-green .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-green:active,
html:not(.watch-active-state) .list-block .item-linka.bg-green:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-green:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-green:active,
.list-block .bg-green .item-link.active-state,
.list-block a.bg-green .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-green .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-green .item-link.active-state,
.list-block.bg-green .item-link.active-state,
.list-blocka.bg-green .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-green .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-green .item-link.active-state,
.list-block .item-link.bg-green.active-state,
.list-block .item-linka.bg-green.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-green.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-green.active-state {
  background: #388e3c;
}
.border-green {
  border-color: #4caf50;
}
.list-block .border-green.item-inner:after,
.list-block ul.border-green:after,
.border-green:after,
.list-block .border-green.item-inner:before,
.list-block ul.border-green:before,
.border-green:before {
  background-color: #4caf50;
}
.floating-button.color-green,
.speed-dial-buttons a.color-green,
.floating-button.bg-green,
.speed-dial-buttons a.bg-green {
  color: #fff;
  background: #4caf50;
}
html:not(.watch-active-state) .floating-button.color-green:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-green:active,
html:not(.watch-active-state) .floating-button.bg-green:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-green:active,
.floating-button.color-green.active-state,
.speed-dial-buttons a.color-green.active-state,
.floating-button.bg-green.active-state,
.speed-dial-buttons a.bg-green.active-state {
  background: #388e3c;
}
.floating-button.color-green i,
.speed-dial-buttons a.color-green i,
.floating-button.bg-green i,
.speed-dial-buttons a.bg-green i {
  color: inherit;
}
.ripple-green .ripple-wave {
  background-color: rgba(76, 175, 80, 0.3);
}
.range-slider.color-green input[type="range"]::-webkit-slider-thumb:before {
  background-color: #4caf50;
}
.range-slider.color-green input[type="range"]::-webkit-slider-thumb {
  background-color: #4caf50;
}
.range-slider.color-green input[type="range"]::-ms-thumb {
  background-color: #4caf50;
}
.range-slider.color-green input[type="range"]::-ms-fill-lower {
  background-color: #4caf50;
}
.range-slider.color-green input[type="range"]::-moz-range-thumb {
  background-color: #4caf50;
}
.badge.color-green {
  background-color: #4caf50;
  color: #fff;
}
.color-lightgreen {
  color: #8bc34a;
}
.list-block .item-link.list-button.color-lightgreen,
.list-button.color-lightgreen,
.list-block .item-link.color-lightgreen,
.tabbar a.active.color-lightgreen,
a.color-lightgreen {
  color: #8bc34a;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-lightgreen,
.label-switch.color-lightgreen input[type="checkbox"]:checked + .checkbox {
  background-color: #8bc34a;
}
.button.color-lightgreen:not(.button-fill),
.color-lightgreen.buttons-row .button:not(.button-fill) {
  color: #8bc34a !important;
}
.button.button-fill.bg-lightgreen,
.button.button-fill.color-lightgreen {
  background: #8bc34a !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-lightgreen:active,
html:not(.watch-active-state) .button.button-fill.color-lightgreen:active,
.button.button-fill.bg-lightgreen.active-state,
.button.button-fill.color-lightgreen.active-state {
  background: #689f38 !important;
}
.progressbar.color-lightgreen,
.progressbar.bg-lightgreen {
  background-color: rgba(139, 195, 74, 0.5);
}
.progressbar.color-lightgreen span,
.progressbar.bg-lightgreen span {
  background-color: #8bc34a;
}
.progressbar-infinite.color-lightgreen,
.progressbar-infinite.bg-lightgreen {
  background-color: rgba(139, 195, 74, 0.5);
}
.progressbar-infinite.color-lightgreen:after,
.progressbar-infinite.bg-lightgreen:after,
.progressbar-infinite.color-lightgreen:before,
.progressbar-infinite.bg-lightgreen:before {
  background-color: #8bc34a;
}
.color-lightgreen i.icon,
i.icon.color-lightgreen {
  color: #8bc34a;
}
.preloader.color-lightgreen svg circle,
.preloader.preloader-lightgreen svg circle {
  stroke: #8bc34a;
}
.preloader.color-lightgreen .preloader-inner-gap,
.preloader.preloader-lightgreen .preloader-inner-gap,
.preloader.color-lightgreen .preloader-inner-half-circle,
.preloader.preloader-lightgreen .preloader-inner-half-circle {
  border-color: #8bc34a;
}
.bg-lightgreen,
a.bg-lightgreen,
.list-block .swipeout-actions-left a.bg-lightgreen,
.list-block .swipeout-actions-right a.bg-lightgreen {
  background-color: #8bc34a;
  background: #8bc34a !important;
}
html:not(.watch-active-state) .list-block .bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block a.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-lightgreen .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-lightgreen:active,
html:not(.watch-active-state) .list-block .item-linka.bg-lightgreen:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-lightgreen:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-lightgreen:active,
.list-block .bg-lightgreen .item-link.active-state,
.list-block a.bg-lightgreen .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-lightgreen .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-lightgreen .item-link.active-state,
.list-block.bg-lightgreen .item-link.active-state,
.list-blocka.bg-lightgreen .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-lightgreen .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-lightgreen .item-link.active-state,
.list-block .item-link.bg-lightgreen.active-state,
.list-block .item-linka.bg-lightgreen.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-lightgreen.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-lightgreen.active-state {
  background: #689f38;
}
.border-lightgreen {
  border-color: #8bc34a;
}
.list-block .border-lightgreen.item-inner:after,
.list-block ul.border-lightgreen:after,
.border-lightgreen:after,
.list-block .border-lightgreen.item-inner:before,
.list-block ul.border-lightgreen:before,
.border-lightgreen:before {
  background-color: #8bc34a;
}
.floating-button.color-lightgreen,
.speed-dial-buttons a.color-lightgreen,
.floating-button.bg-lightgreen,
.speed-dial-buttons a.bg-lightgreen {
  color: #fff;
  background: #8bc34a;
}
html:not(.watch-active-state) .floating-button.color-lightgreen:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-lightgreen:active,
html:not(.watch-active-state) .floating-button.bg-lightgreen:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-lightgreen:active,
.floating-button.color-lightgreen.active-state,
.speed-dial-buttons a.color-lightgreen.active-state,
.floating-button.bg-lightgreen.active-state,
.speed-dial-buttons a.bg-lightgreen.active-state {
  background: #689f38;
}
.floating-button.color-lightgreen i,
.speed-dial-buttons a.color-lightgreen i,
.floating-button.bg-lightgreen i,
.speed-dial-buttons a.bg-lightgreen i {
  color: inherit;
}
.ripple-lightgreen .ripple-wave {
  background-color: rgba(139, 195, 74, 0.3);
}
.range-slider.color-lightgreen input[type="range"]::-webkit-slider-thumb:before {
  background-color: #8bc34a;
}
.range-slider.color-lightgreen input[type="range"]::-webkit-slider-thumb {
  background-color: #8bc34a;
}
.range-slider.color-lightgreen input[type="range"]::-ms-thumb {
  background-color: #8bc34a;
}
.range-slider.color-lightgreen input[type="range"]::-ms-fill-lower {
  background-color: #8bc34a;
}
.range-slider.color-lightgreen input[type="range"]::-moz-range-thumb {
  background-color: #8bc34a;
}
.badge.color-lightgreen {
  background-color: #8bc34a;
  color: #fff;
}
.color-lime {
  color: #cddc39;
}
.list-block .item-link.list-button.color-lime,
.list-button.color-lime,
.list-block .item-link.color-lime,
.tabbar a.active.color-lime,
a.color-lime {
  color: #cddc39;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-lime,
.label-switch.color-lime input[type="checkbox"]:checked + .checkbox {
  background-color: #cddc39;
}
.button.color-lime:not(.button-fill),
.color-lime.buttons-row .button:not(.button-fill) {
  color: #cddc39 !important;
}
.button.button-fill.bg-lime,
.button.button-fill.color-lime {
  background: #cddc39 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-lime:active,
html:not(.watch-active-state) .button.button-fill.color-lime:active,
.button.button-fill.bg-lime.active-state,
.button.button-fill.color-lime.active-state {
  background: #afb42b !important;
}
.progressbar.color-lime,
.progressbar.bg-lime {
  background-color: rgba(205, 220, 57, 0.5);
}
.progressbar.color-lime span,
.progressbar.bg-lime span {
  background-color: #cddc39;
}
.progressbar-infinite.color-lime,
.progressbar-infinite.bg-lime {
  background-color: rgba(205, 220, 57, 0.5);
}
.progressbar-infinite.color-lime:after,
.progressbar-infinite.bg-lime:after,
.progressbar-infinite.color-lime:before,
.progressbar-infinite.bg-lime:before {
  background-color: #cddc39;
}
.color-lime i.icon,
i.icon.color-lime {
  color: #cddc39;
}
.preloader.color-lime svg circle,
.preloader.preloader-lime svg circle {
  stroke: #cddc39;
}
.preloader.color-lime .preloader-inner-gap,
.preloader.preloader-lime .preloader-inner-gap,
.preloader.color-lime .preloader-inner-half-circle,
.preloader.preloader-lime .preloader-inner-half-circle {
  border-color: #cddc39;
}
.bg-lime,
a.bg-lime,
.list-block .swipeout-actions-left a.bg-lime,
.list-block .swipeout-actions-right a.bg-lime {
  background-color: #cddc39;
  background: #cddc39 !important;
}
html:not(.watch-active-state) .list-block .bg-lime .item-link:active,
html:not(.watch-active-state) .list-block a.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block.bg-lime .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-lime .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-lime:active,
html:not(.watch-active-state) .list-block .item-linka.bg-lime:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-lime:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-lime:active,
.list-block .bg-lime .item-link.active-state,
.list-block a.bg-lime .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-lime .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-lime .item-link.active-state,
.list-block.bg-lime .item-link.active-state,
.list-blocka.bg-lime .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-lime .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-lime .item-link.active-state,
.list-block .item-link.bg-lime.active-state,
.list-block .item-linka.bg-lime.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-lime.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-lime.active-state {
  background: #afb42b;
}
.border-lime {
  border-color: #cddc39;
}
.list-block .border-lime.item-inner:after,
.list-block ul.border-lime:after,
.border-lime:after,
.list-block .border-lime.item-inner:before,
.list-block ul.border-lime:before,
.border-lime:before {
  background-color: #cddc39;
}
.floating-button.color-lime,
.speed-dial-buttons a.color-lime,
.floating-button.bg-lime,
.speed-dial-buttons a.bg-lime {
  color: #fff;
  background: #cddc39;
}
html:not(.watch-active-state) .floating-button.color-lime:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-lime:active,
html:not(.watch-active-state) .floating-button.bg-lime:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-lime:active,
.floating-button.color-lime.active-state,
.speed-dial-buttons a.color-lime.active-state,
.floating-button.bg-lime.active-state,
.speed-dial-buttons a.bg-lime.active-state {
  background: #afb42b;
}
.floating-button.color-lime i,
.speed-dial-buttons a.color-lime i,
.floating-button.bg-lime i,
.speed-dial-buttons a.bg-lime i {
  color: inherit;
}
.ripple-lime .ripple-wave {
  background-color: rgba(205, 220, 57, 0.3);
}
.range-slider.color-lime input[type="range"]::-webkit-slider-thumb:before {
  background-color: #cddc39;
}
.range-slider.color-lime input[type="range"]::-webkit-slider-thumb {
  background-color: #cddc39;
}
.range-slider.color-lime input[type="range"]::-ms-thumb {
  background-color: #cddc39;
}
.range-slider.color-lime input[type="range"]::-ms-fill-lower {
  background-color: #cddc39;
}
.range-slider.color-lime input[type="range"]::-moz-range-thumb {
  background-color: #cddc39;
}
.badge.color-lime {
  background-color: #cddc39;
  color: #fff;
}
.color-yellow {
  color: #ffeb3b;
}
.list-block .item-link.list-button.color-yellow,
.list-button.color-yellow,
.list-block .item-link.color-yellow,
.tabbar a.active.color-yellow,
a.color-yellow {
  color: #ffeb3b;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-yellow,
.label-switch.color-yellow input[type="checkbox"]:checked + .checkbox {
  background-color: #ffeb3b;
}
.button.color-yellow:not(.button-fill),
.color-yellow.buttons-row .button:not(.button-fill) {
  color: #ffeb3b !important;
}
.button.button-fill.bg-yellow,
.button.button-fill.color-yellow {
  background: #ffeb3b !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-yellow:active,
html:not(.watch-active-state) .button.button-fill.color-yellow:active,
.button.button-fill.bg-yellow.active-state,
.button.button-fill.color-yellow.active-state {
  background: #fbc02d !important;
}
.progressbar.color-yellow,
.progressbar.bg-yellow {
  background-color: rgba(255, 235, 59, 0.5);
}
.progressbar.color-yellow span,
.progressbar.bg-yellow span {
  background-color: #ffeb3b;
}
.progressbar-infinite.color-yellow,
.progressbar-infinite.bg-yellow {
  background-color: rgba(255, 235, 59, 0.5);
}
.progressbar-infinite.color-yellow:after,
.progressbar-infinite.bg-yellow:after,
.progressbar-infinite.color-yellow:before,
.progressbar-infinite.bg-yellow:before {
  background-color: #ffeb3b;
}
.color-yellow i.icon,
i.icon.color-yellow {
  color: #ffeb3b;
}
.preloader.color-yellow svg circle,
.preloader.preloader-yellow svg circle {
  stroke: #ffeb3b;
}
.preloader.color-yellow .preloader-inner-gap,
.preloader.preloader-yellow .preloader-inner-gap,
.preloader.color-yellow .preloader-inner-half-circle,
.preloader.preloader-yellow .preloader-inner-half-circle {
  border-color: #ffeb3b;
}
.bg-yellow,
a.bg-yellow,
.list-block .swipeout-actions-left a.bg-yellow,
.list-block .swipeout-actions-right a.bg-yellow {
  background-color: #ffeb3b;
  background: #ffeb3b !important;
}
html:not(.watch-active-state) .list-block .bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block a.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-yellow .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-yellow:active,
html:not(.watch-active-state) .list-block .item-linka.bg-yellow:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-yellow:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-yellow:active,
.list-block .bg-yellow .item-link.active-state,
.list-block a.bg-yellow .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-yellow .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-yellow .item-link.active-state,
.list-block.bg-yellow .item-link.active-state,
.list-blocka.bg-yellow .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-yellow .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-yellow .item-link.active-state,
.list-block .item-link.bg-yellow.active-state,
.list-block .item-linka.bg-yellow.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-yellow.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-yellow.active-state {
  background: #fbc02d;
}
.border-yellow {
  border-color: #ffeb3b;
}
.list-block .border-yellow.item-inner:after,
.list-block ul.border-yellow:after,
.border-yellow:after,
.list-block .border-yellow.item-inner:before,
.list-block ul.border-yellow:before,
.border-yellow:before {
  background-color: #ffeb3b;
}
.floating-button.color-yellow,
.speed-dial-buttons a.color-yellow,
.floating-button.bg-yellow,
.speed-dial-buttons a.bg-yellow {
  color: #fff;
  background: #ffeb3b;
}
html:not(.watch-active-state) .floating-button.color-yellow:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-yellow:active,
html:not(.watch-active-state) .floating-button.bg-yellow:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-yellow:active,
.floating-button.color-yellow.active-state,
.speed-dial-buttons a.color-yellow.active-state,
.floating-button.bg-yellow.active-state,
.speed-dial-buttons a.bg-yellow.active-state {
  background: #fbc02d;
}
.floating-button.color-yellow i,
.speed-dial-buttons a.color-yellow i,
.floating-button.bg-yellow i,
.speed-dial-buttons a.bg-yellow i {
  color: inherit;
}
.ripple-yellow .ripple-wave {
  background-color: rgba(255, 235, 59, 0.3);
}
.range-slider.color-yellow input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffeb3b;
}
.range-slider.color-yellow input[type="range"]::-webkit-slider-thumb {
  background-color: #ffeb3b;
}
.range-slider.color-yellow input[type="range"]::-ms-thumb {
  background-color: #ffeb3b;
}
.range-slider.color-yellow input[type="range"]::-ms-fill-lower {
  background-color: #ffeb3b;
}
.range-slider.color-yellow input[type="range"]::-moz-range-thumb {
  background-color: #ffeb3b;
}
.badge.color-yellow {
  background-color: #ffeb3b;
  color: #fff;
}
.color-amber {
  color: #ffc107;
}
.list-block .item-link.list-button.color-amber,
.list-button.color-amber,
.list-block .item-link.color-amber,
.tabbar a.active.color-amber,
a.color-amber {
  color: #ffc107;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-amber,
.label-switch.color-amber input[type="checkbox"]:checked + .checkbox {
  background-color: #ffc107;
}
.button.color-amber:not(.button-fill),
.color-amber.buttons-row .button:not(.button-fill) {
  color: #ffc107 !important;
}
.button.button-fill.bg-amber,
.button.button-fill.color-amber {
  background: #ffc107 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-amber:active,
html:not(.watch-active-state) .button.button-fill.color-amber:active,
.button.button-fill.bg-amber.active-state,
.button.button-fill.color-amber.active-state {
  background: #ffa000 !important;
}
.progressbar.color-amber,
.progressbar.bg-amber {
  background-color: rgba(255, 193, 7, 0.5);
}
.progressbar.color-amber span,
.progressbar.bg-amber span {
  background-color: #ffc107;
}
.progressbar-infinite.color-amber,
.progressbar-infinite.bg-amber {
  background-color: rgba(255, 193, 7, 0.5);
}
.progressbar-infinite.color-amber:after,
.progressbar-infinite.bg-amber:after,
.progressbar-infinite.color-amber:before,
.progressbar-infinite.bg-amber:before {
  background-color: #ffc107;
}
.color-amber i.icon,
i.icon.color-amber {
  color: #ffc107;
}
.preloader.color-amber svg circle,
.preloader.preloader-amber svg circle {
  stroke: #ffc107;
}
.preloader.color-amber .preloader-inner-gap,
.preloader.preloader-amber .preloader-inner-gap,
.preloader.color-amber .preloader-inner-half-circle,
.preloader.preloader-amber .preloader-inner-half-circle {
  border-color: #ffc107;
}
.bg-amber,
a.bg-amber,
.list-block .swipeout-actions-left a.bg-amber,
.list-block .swipeout-actions-right a.bg-amber {
  background-color: #ffc107;
  background: #ffc107 !important;
}
html:not(.watch-active-state) .list-block .bg-amber .item-link:active,
html:not(.watch-active-state) .list-block a.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block.bg-amber .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-amber .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-amber:active,
html:not(.watch-active-state) .list-block .item-linka.bg-amber:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-amber:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-amber:active,
.list-block .bg-amber .item-link.active-state,
.list-block a.bg-amber .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-amber .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-amber .item-link.active-state,
.list-block.bg-amber .item-link.active-state,
.list-blocka.bg-amber .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-amber .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-amber .item-link.active-state,
.list-block .item-link.bg-amber.active-state,
.list-block .item-linka.bg-amber.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-amber.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-amber.active-state {
  background: #ffa000;
}
.border-amber {
  border-color: #ffc107;
}
.list-block .border-amber.item-inner:after,
.list-block ul.border-amber:after,
.border-amber:after,
.list-block .border-amber.item-inner:before,
.list-block ul.border-amber:before,
.border-amber:before {
  background-color: #ffc107;
}
.floating-button.color-amber,
.speed-dial-buttons a.color-amber,
.floating-button.bg-amber,
.speed-dial-buttons a.bg-amber {
  color: #fff;
  background: #ffc107;
}
html:not(.watch-active-state) .floating-button.color-amber:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-amber:active,
html:not(.watch-active-state) .floating-button.bg-amber:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-amber:active,
.floating-button.color-amber.active-state,
.speed-dial-buttons a.color-amber.active-state,
.floating-button.bg-amber.active-state,
.speed-dial-buttons a.bg-amber.active-state {
  background: #ffa000;
}
.floating-button.color-amber i,
.speed-dial-buttons a.color-amber i,
.floating-button.bg-amber i,
.speed-dial-buttons a.bg-amber i {
  color: inherit;
}
.ripple-amber .ripple-wave {
  background-color: rgba(255, 193, 7, 0.3);
}
.range-slider.color-amber input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffc107;
}
.range-slider.color-amber input[type="range"]::-webkit-slider-thumb {
  background-color: #ffc107;
}
.range-slider.color-amber input[type="range"]::-ms-thumb {
  background-color: #ffc107;
}
.range-slider.color-amber input[type="range"]::-ms-fill-lower {
  background-color: #ffc107;
}
.range-slider.color-amber input[type="range"]::-moz-range-thumb {
  background-color: #ffc107;
}
.badge.color-amber {
  background-color: #ffc107;
  color: #fff;
}
.color-orange {
  color: #ff9800;
}
.list-block .item-link.list-button.color-orange,
.list-button.color-orange,
.list-block .item-link.color-orange,
.tabbar a.active.color-orange,
a.color-orange {
  color: #ff9800;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-orange,
.label-switch.color-orange input[type="checkbox"]:checked + .checkbox {
  background-color: #ff9800;
}
.button.color-orange:not(.button-fill),
.color-orange.buttons-row .button:not(.button-fill) {
  color: #ff9800 !important;
}
.button.button-fill.bg-orange,
.button.button-fill.color-orange {
  background: #ff9800 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-orange:active,
html:not(.watch-active-state) .button.button-fill.color-orange:active,
.button.button-fill.bg-orange.active-state,
.button.button-fill.color-orange.active-state {
  background: #f57c00 !important;
}
.progressbar.color-orange,
.progressbar.bg-orange {
  background-color: rgba(255, 152, 0, 0.5);
}
.progressbar.color-orange span,
.progressbar.bg-orange span {
  background-color: #ff9800;
}
.progressbar-infinite.color-orange,
.progressbar-infinite.bg-orange {
  background-color: rgba(255, 152, 0, 0.5);
}
.progressbar-infinite.color-orange:after,
.progressbar-infinite.bg-orange:after,
.progressbar-infinite.color-orange:before,
.progressbar-infinite.bg-orange:before {
  background-color: #ff9800;
}
.color-orange i.icon,
i.icon.color-orange {
  color: #ff9800;
}
.preloader.color-orange svg circle,
.preloader.preloader-orange svg circle {
  stroke: #ff9800;
}
.preloader.color-orange .preloader-inner-gap,
.preloader.preloader-orange .preloader-inner-gap,
.preloader.color-orange .preloader-inner-half-circle,
.preloader.preloader-orange .preloader-inner-half-circle {
  border-color: #ff9800;
}
.bg-orange,
a.bg-orange,
.list-block .swipeout-actions-left a.bg-orange,
.list-block .swipeout-actions-right a.bg-orange {
  background-color: #ff9800;
  background: #ff9800 !important;
}
html:not(.watch-active-state) .list-block .bg-orange .item-link:active,
html:not(.watch-active-state) .list-block a.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block.bg-orange .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-orange .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-orange:active,
html:not(.watch-active-state) .list-block .item-linka.bg-orange:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-orange:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-orange:active,
.list-block .bg-orange .item-link.active-state,
.list-block a.bg-orange .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-orange .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-orange .item-link.active-state,
.list-block.bg-orange .item-link.active-state,
.list-blocka.bg-orange .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-orange .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-orange .item-link.active-state,
.list-block .item-link.bg-orange.active-state,
.list-block .item-linka.bg-orange.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-orange.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-orange.active-state {
  background: #f57c00;
}
.border-orange {
  border-color: #ff9800;
}
.list-block .border-orange.item-inner:after,
.list-block ul.border-orange:after,
.border-orange:after,
.list-block .border-orange.item-inner:before,
.list-block ul.border-orange:before,
.border-orange:before {
  background-color: #ff9800;
}
.floating-button.color-orange,
.speed-dial-buttons a.color-orange,
.floating-button.bg-orange,
.speed-dial-buttons a.bg-orange {
  color: #fff;
  background: #ff9800;
}
html:not(.watch-active-state) .floating-button.color-orange:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-orange:active,
html:not(.watch-active-state) .floating-button.bg-orange:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-orange:active,
.floating-button.color-orange.active-state,
.speed-dial-buttons a.color-orange.active-state,
.floating-button.bg-orange.active-state,
.speed-dial-buttons a.bg-orange.active-state {
  background: #f57c00;
}
.floating-button.color-orange i,
.speed-dial-buttons a.color-orange i,
.floating-button.bg-orange i,
.speed-dial-buttons a.bg-orange i {
  color: inherit;
}
.ripple-orange .ripple-wave {
  background-color: rgba(255, 152, 0, 0.3);
}
.range-slider.color-orange input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ff9800;
}
.range-slider.color-orange input[type="range"]::-webkit-slider-thumb {
  background-color: #ff9800;
}
.range-slider.color-orange input[type="range"]::-ms-thumb {
  background-color: #ff9800;
}
.range-slider.color-orange input[type="range"]::-ms-fill-lower {
  background-color: #ff9800;
}
.range-slider.color-orange input[type="range"]::-moz-range-thumb {
  background-color: #ff9800;
}
.badge.color-orange {
  background-color: #ff9800;
  color: #fff;
}
.color-deeporange {
  color: #ff5722;
}
.list-block .item-link.list-button.color-deeporange,
.list-button.color-deeporange,
.list-block .item-link.color-deeporange,
.tabbar a.active.color-deeporange,
a.color-deeporange {
  color: #ff5722;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-deeporange,
.label-switch.color-deeporange input[type="checkbox"]:checked + .checkbox {
  background-color: #ff5722;
}
.button.color-deeporange:not(.button-fill),
.color-deeporange.buttons-row .button:not(.button-fill) {
  color: #ff5722 !important;
}
.button.button-fill.bg-deeporange,
.button.button-fill.color-deeporange {
  background: #ff5722 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-deeporange:active,
html:not(.watch-active-state) .button.button-fill.color-deeporange:active,
.button.button-fill.bg-deeporange.active-state,
.button.button-fill.color-deeporange.active-state {
  background: #e64a19 !important;
}
.progressbar.color-deeporange,
.progressbar.bg-deeporange {
  background-color: rgba(255, 87, 34, 0.5);
}
.progressbar.color-deeporange span,
.progressbar.bg-deeporange span {
  background-color: #ff5722;
}
.progressbar-infinite.color-deeporange,
.progressbar-infinite.bg-deeporange {
  background-color: rgba(255, 87, 34, 0.5);
}
.progressbar-infinite.color-deeporange:after,
.progressbar-infinite.bg-deeporange:after,
.progressbar-infinite.color-deeporange:before,
.progressbar-infinite.bg-deeporange:before {
  background-color: #ff5722;
}
.color-deeporange i.icon,
i.icon.color-deeporange {
  color: #ff5722;
}
.preloader.color-deeporange svg circle,
.preloader.preloader-deeporange svg circle {
  stroke: #ff5722;
}
.preloader.color-deeporange .preloader-inner-gap,
.preloader.preloader-deeporange .preloader-inner-gap,
.preloader.color-deeporange .preloader-inner-half-circle,
.preloader.preloader-deeporange .preloader-inner-half-circle {
  border-color: #ff5722;
}
.bg-deeporange,
a.bg-deeporange,
.list-block .swipeout-actions-left a.bg-deeporange,
.list-block .swipeout-actions-right a.bg-deeporange {
  background-color: #ff5722;
  background: #ff5722 !important;
}
html:not(.watch-active-state) .list-block .bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block a.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-deeporange .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-deeporange:active,
html:not(.watch-active-state) .list-block .item-linka.bg-deeporange:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-deeporange:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-deeporange:active,
.list-block .bg-deeporange .item-link.active-state,
.list-block a.bg-deeporange .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-deeporange .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-deeporange .item-link.active-state,
.list-block.bg-deeporange .item-link.active-state,
.list-blocka.bg-deeporange .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-deeporange .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-deeporange .item-link.active-state,
.list-block .item-link.bg-deeporange.active-state,
.list-block .item-linka.bg-deeporange.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-deeporange.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-deeporange.active-state {
  background: #e64a19;
}
.border-deeporange {
  border-color: #ff5722;
}
.list-block .border-deeporange.item-inner:after,
.list-block ul.border-deeporange:after,
.border-deeporange:after,
.list-block .border-deeporange.item-inner:before,
.list-block ul.border-deeporange:before,
.border-deeporange:before {
  background-color: #ff5722;
}
.floating-button.color-deeporange,
.speed-dial-buttons a.color-deeporange,
.floating-button.bg-deeporange,
.speed-dial-buttons a.bg-deeporange {
  color: #fff;
  background: #ff5722;
}
html:not(.watch-active-state) .floating-button.color-deeporange:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-deeporange:active,
html:not(.watch-active-state) .floating-button.bg-deeporange:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-deeporange:active,
.floating-button.color-deeporange.active-state,
.speed-dial-buttons a.color-deeporange.active-state,
.floating-button.bg-deeporange.active-state,
.speed-dial-buttons a.bg-deeporange.active-state {
  background: #e64a19;
}
.floating-button.color-deeporange i,
.speed-dial-buttons a.color-deeporange i,
.floating-button.bg-deeporange i,
.speed-dial-buttons a.bg-deeporange i {
  color: inherit;
}
.ripple-deeporange .ripple-wave {
  background-color: rgba(255, 87, 34, 0.3);
}
.range-slider.color-deeporange input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ff5722;
}
.range-slider.color-deeporange input[type="range"]::-webkit-slider-thumb {
  background-color: #ff5722;
}
.range-slider.color-deeporange input[type="range"]::-ms-thumb {
  background-color: #ff5722;
}
.range-slider.color-deeporange input[type="range"]::-ms-fill-lower {
  background-color: #ff5722;
}
.range-slider.color-deeporange input[type="range"]::-moz-range-thumb {
  background-color: #ff5722;
}
.badge.color-deeporange {
  background-color: #ff5722;
  color: #fff;
}
.color-brown {
  color: #795548;
}
.list-block .item-link.list-button.color-brown,
.list-button.color-brown,
.list-block .item-link.color-brown,
.tabbar a.active.color-brown,
a.color-brown {
  color: #795548;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-brown,
.label-switch.color-brown input[type="checkbox"]:checked + .checkbox {
  background-color: #795548;
}
.button.color-brown:not(.button-fill),
.color-brown.buttons-row .button:not(.button-fill) {
  color: #795548 !important;
}
.button.button-fill.bg-brown,
.button.button-fill.color-brown {
  background: #795548 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-brown:active,
html:not(.watch-active-state) .button.button-fill.color-brown:active,
.button.button-fill.bg-brown.active-state,
.button.button-fill.color-brown.active-state {
  background: #5d4037 !important;
}
.progressbar.color-brown,
.progressbar.bg-brown {
  background-color: rgba(121, 85, 72, 0.5);
}
.progressbar.color-brown span,
.progressbar.bg-brown span {
  background-color: #795548;
}
.progressbar-infinite.color-brown,
.progressbar-infinite.bg-brown {
  background-color: rgba(121, 85, 72, 0.5);
}
.progressbar-infinite.color-brown:after,
.progressbar-infinite.bg-brown:after,
.progressbar-infinite.color-brown:before,
.progressbar-infinite.bg-brown:before {
  background-color: #795548;
}
.color-brown i.icon,
i.icon.color-brown {
  color: #795548;
}
.preloader.color-brown svg circle,
.preloader.preloader-brown svg circle {
  stroke: #795548;
}
.preloader.color-brown .preloader-inner-gap,
.preloader.preloader-brown .preloader-inner-gap,
.preloader.color-brown .preloader-inner-half-circle,
.preloader.preloader-brown .preloader-inner-half-circle {
  border-color: #795548;
}
.bg-brown,
a.bg-brown,
.list-block .swipeout-actions-left a.bg-brown,
.list-block .swipeout-actions-right a.bg-brown {
  background-color: #795548;
  background: #795548 !important;
}
html:not(.watch-active-state) .list-block .bg-brown .item-link:active,
html:not(.watch-active-state) .list-block a.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block.bg-brown .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-brown .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-brown:active,
html:not(.watch-active-state) .list-block .item-linka.bg-brown:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-brown:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-brown:active,
.list-block .bg-brown .item-link.active-state,
.list-block a.bg-brown .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-brown .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-brown .item-link.active-state,
.list-block.bg-brown .item-link.active-state,
.list-blocka.bg-brown .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-brown .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-brown .item-link.active-state,
.list-block .item-link.bg-brown.active-state,
.list-block .item-linka.bg-brown.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-brown.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-brown.active-state {
  background: #5d4037;
}
.border-brown {
  border-color: #795548;
}
.list-block .border-brown.item-inner:after,
.list-block ul.border-brown:after,
.border-brown:after,
.list-block .border-brown.item-inner:before,
.list-block ul.border-brown:before,
.border-brown:before {
  background-color: #795548;
}
.floating-button.color-brown,
.speed-dial-buttons a.color-brown,
.floating-button.bg-brown,
.speed-dial-buttons a.bg-brown {
  color: #fff;
  background: #795548;
}
html:not(.watch-active-state) .floating-button.color-brown:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-brown:active,
html:not(.watch-active-state) .floating-button.bg-brown:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-brown:active,
.floating-button.color-brown.active-state,
.speed-dial-buttons a.color-brown.active-state,
.floating-button.bg-brown.active-state,
.speed-dial-buttons a.bg-brown.active-state {
  background: #5d4037;
}
.floating-button.color-brown i,
.speed-dial-buttons a.color-brown i,
.floating-button.bg-brown i,
.speed-dial-buttons a.bg-brown i {
  color: inherit;
}
.ripple-brown .ripple-wave {
  background-color: rgba(121, 85, 72, 0.3);
}
.range-slider.color-brown input[type="range"]::-webkit-slider-thumb:before {
  background-color: #795548;
}
.range-slider.color-brown input[type="range"]::-webkit-slider-thumb {
  background-color: #795548;
}
.range-slider.color-brown input[type="range"]::-ms-thumb {
  background-color: #795548;
}
.range-slider.color-brown input[type="range"]::-ms-fill-lower {
  background-color: #795548;
}
.range-slider.color-brown input[type="range"]::-moz-range-thumb {
  background-color: #795548;
}
.badge.color-brown {
  background-color: #795548;
  color: #fff;
}
.color-gray {
  color: #9e9e9e;
}
.list-block .item-link.list-button.color-gray,
.list-button.color-gray,
.list-block .item-link.color-gray,
.tabbar a.active.color-gray,
a.color-gray {
  color: #9e9e9e;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-gray,
.label-switch.color-gray input[type="checkbox"]:checked + .checkbox {
  background-color: #9e9e9e;
}
.button.color-gray:not(.button-fill),
.color-gray.buttons-row .button:not(.button-fill) {
  color: #9e9e9e !important;
}
.button.button-fill.bg-gray,
.button.button-fill.color-gray {
  background: #9e9e9e !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-gray:active,
html:not(.watch-active-state) .button.button-fill.color-gray:active,
.button.button-fill.bg-gray.active-state,
.button.button-fill.color-gray.active-state {
  background: #616161 !important;
}
.progressbar.color-gray,
.progressbar.bg-gray {
  background-color: rgba(158, 158, 158, 0.5);
}
.progressbar.color-gray span,
.progressbar.bg-gray span {
  background-color: #9e9e9e;
}
.progressbar-infinite.color-gray,
.progressbar-infinite.bg-gray {
  background-color: rgba(158, 158, 158, 0.5);
}
.progressbar-infinite.color-gray:after,
.progressbar-infinite.bg-gray:after,
.progressbar-infinite.color-gray:before,
.progressbar-infinite.bg-gray:before {
  background-color: #9e9e9e;
}
.color-gray i.icon,
i.icon.color-gray {
  color: #9e9e9e;
}
.preloader.color-gray svg circle,
.preloader.preloader-gray svg circle {
  stroke: #9e9e9e;
}
.preloader.color-gray .preloader-inner-gap,
.preloader.preloader-gray .preloader-inner-gap,
.preloader.color-gray .preloader-inner-half-circle,
.preloader.preloader-gray .preloader-inner-half-circle {
  border-color: #9e9e9e;
}
.bg-gray,
a.bg-gray,
.list-block .swipeout-actions-left a.bg-gray,
.list-block .swipeout-actions-right a.bg-gray {
  background-color: #9e9e9e;
  background: #9e9e9e !important;
}
html:not(.watch-active-state) .list-block .bg-gray .item-link:active,
html:not(.watch-active-state) .list-block a.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block.bg-gray .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-gray .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-gray:active,
html:not(.watch-active-state) .list-block .item-linka.bg-gray:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-gray:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-gray:active,
.list-block .bg-gray .item-link.active-state,
.list-block a.bg-gray .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-gray .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-gray .item-link.active-state,
.list-block.bg-gray .item-link.active-state,
.list-blocka.bg-gray .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-gray .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-gray .item-link.active-state,
.list-block .item-link.bg-gray.active-state,
.list-block .item-linka.bg-gray.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-gray.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-gray.active-state {
  background: #616161;
}
.border-gray {
  border-color: #9e9e9e;
}
.list-block .border-gray.item-inner:after,
.list-block ul.border-gray:after,
.border-gray:after,
.list-block .border-gray.item-inner:before,
.list-block ul.border-gray:before,
.border-gray:before {
  background-color: #9e9e9e;
}
.floating-button.color-gray,
.speed-dial-buttons a.color-gray,
.floating-button.bg-gray,
.speed-dial-buttons a.bg-gray {
  color: #fff;
  background: #9e9e9e;
}
html:not(.watch-active-state) .floating-button.color-gray:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-gray:active,
html:not(.watch-active-state) .floating-button.bg-gray:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-gray:active,
.floating-button.color-gray.active-state,
.speed-dial-buttons a.color-gray.active-state,
.floating-button.bg-gray.active-state,
.speed-dial-buttons a.bg-gray.active-state {
  background: #616161;
}
.floating-button.color-gray i,
.speed-dial-buttons a.color-gray i,
.floating-button.bg-gray i,
.speed-dial-buttons a.bg-gray i {
  color: inherit;
}
.ripple-gray .ripple-wave {
  background-color: rgba(158, 158, 158, 0.3);
}
.range-slider.color-gray input[type="range"]::-webkit-slider-thumb:before {
  background-color: #9e9e9e;
}
.range-slider.color-gray input[type="range"]::-webkit-slider-thumb {
  background-color: #9e9e9e;
}
.range-slider.color-gray input[type="range"]::-ms-thumb {
  background-color: #9e9e9e;
}
.range-slider.color-gray input[type="range"]::-ms-fill-lower {
  background-color: #9e9e9e;
}
.range-slider.color-gray input[type="range"]::-moz-range-thumb {
  background-color: #9e9e9e;
}
.badge.color-gray {
  background-color: #9e9e9e;
  color: #fff;
}
.color-bluegray {
  color: #607d8b;
}
.list-block .item-link.list-button.color-bluegray,
.list-button.color-bluegray,
.list-block .item-link.color-bluegray,
.tabbar a.active.color-bluegray,
a.color-bluegray {
  color: #607d8b;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-bluegray,
.label-switch.color-bluegray input[type="checkbox"]:checked + .checkbox {
  background-color: #607d8b;
}
.button.color-bluegray:not(.button-fill),
.color-bluegray.buttons-row .button:not(.button-fill) {
  color: #607d8b !important;
}
.button.button-fill.bg-bluegray,
.button.button-fill.color-bluegray {
  background: #607d8b !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-bluegray:active,
html:not(.watch-active-state) .button.button-fill.color-bluegray:active,
.button.button-fill.bg-bluegray.active-state,
.button.button-fill.color-bluegray.active-state {
  background: #455a64 !important;
}
.progressbar.color-bluegray,
.progressbar.bg-bluegray {
  background-color: rgba(96, 125, 139, 0.5);
}
.progressbar.color-bluegray span,
.progressbar.bg-bluegray span {
  background-color: #607d8b;
}
.progressbar-infinite.color-bluegray,
.progressbar-infinite.bg-bluegray {
  background-color: rgba(96, 125, 139, 0.5);
}
.progressbar-infinite.color-bluegray:after,
.progressbar-infinite.bg-bluegray:after,
.progressbar-infinite.color-bluegray:before,
.progressbar-infinite.bg-bluegray:before {
  background-color: #607d8b;
}
.color-bluegray i.icon,
i.icon.color-bluegray {
  color: #607d8b;
}
.preloader.color-bluegray svg circle,
.preloader.preloader-bluegray svg circle {
  stroke: #607d8b;
}
.preloader.color-bluegray .preloader-inner-gap,
.preloader.preloader-bluegray .preloader-inner-gap,
.preloader.color-bluegray .preloader-inner-half-circle,
.preloader.preloader-bluegray .preloader-inner-half-circle {
  border-color: #607d8b;
}
.bg-bluegray,
a.bg-bluegray,
.list-block .swipeout-actions-left a.bg-bluegray,
.list-block .swipeout-actions-right a.bg-bluegray {
  background-color: #607d8b;
  background: #607d8b !important;
}
html:not(.watch-active-state) .list-block .bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block a.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-bluegray .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-bluegray:active,
html:not(.watch-active-state) .list-block .item-linka.bg-bluegray:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-bluegray:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-bluegray:active,
.list-block .bg-bluegray .item-link.active-state,
.list-block a.bg-bluegray .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-bluegray .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-bluegray .item-link.active-state,
.list-block.bg-bluegray .item-link.active-state,
.list-blocka.bg-bluegray .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-bluegray .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-bluegray .item-link.active-state,
.list-block .item-link.bg-bluegray.active-state,
.list-block .item-linka.bg-bluegray.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-bluegray.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-bluegray.active-state {
  background: #455a64;
}
.border-bluegray {
  border-color: #607d8b;
}
.list-block .border-bluegray.item-inner:after,
.list-block ul.border-bluegray:after,
.border-bluegray:after,
.list-block .border-bluegray.item-inner:before,
.list-block ul.border-bluegray:before,
.border-bluegray:before {
  background-color: #607d8b;
}
.floating-button.color-bluegray,
.speed-dial-buttons a.color-bluegray,
.floating-button.bg-bluegray,
.speed-dial-buttons a.bg-bluegray {
  color: #fff;
  background: #607d8b;
}
html:not(.watch-active-state) .floating-button.color-bluegray:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-bluegray:active,
html:not(.watch-active-state) .floating-button.bg-bluegray:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-bluegray:active,
.floating-button.color-bluegray.active-state,
.speed-dial-buttons a.color-bluegray.active-state,
.floating-button.bg-bluegray.active-state,
.speed-dial-buttons a.bg-bluegray.active-state {
  background: #455a64;
}
.floating-button.color-bluegray i,
.speed-dial-buttons a.color-bluegray i,
.floating-button.bg-bluegray i,
.speed-dial-buttons a.bg-bluegray i {
  color: inherit;
}
.ripple-bluegray .ripple-wave {
  background-color: rgba(96, 125, 139, 0.3);
}
.range-slider.color-bluegray input[type="range"]::-webkit-slider-thumb:before {
  background-color: #607d8b;
}
.range-slider.color-bluegray input[type="range"]::-webkit-slider-thumb {
  background-color: #607d8b;
}
.range-slider.color-bluegray input[type="range"]::-ms-thumb {
  background-color: #607d8b;
}
.range-slider.color-bluegray input[type="range"]::-ms-fill-lower {
  background-color: #607d8b;
}
.range-slider.color-bluegray input[type="range"]::-moz-range-thumb {
  background-color: #607d8b;
}
.badge.color-bluegray {
  background-color: #607d8b;
  color: #fff;
}
.color-white {
  color: #ffffff;
}
.list-block .item-link.list-button.color-white,
.list-button.color-white,
.list-block .item-link.color-white,
.tabbar a.active.color-white,
a.color-white {
  color: #ffffff;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-white,
.label-switch.color-white input[type="checkbox"]:checked + .checkbox {
  background-color: #ffffff;
}
.button.color-white:not(.button-fill),
.color-white.buttons-row .button:not(.button-fill) {
  color: #ffffff !important;
}
.button.button-fill.bg-white,
.button.button-fill.color-white {
  background: #ffffff !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-white:active,
html:not(.watch-active-state) .button.button-fill.color-white:active,
.button.button-fill.bg-white.active-state,
.button.button-fill.color-white.active-state {
  background: rgba(0, 0, 0, 0.1) !important;
}
.progressbar.color-white,
.progressbar.bg-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.progressbar.color-white span,
.progressbar.bg-white span {
  background-color: #ffffff;
}
.progressbar-infinite.color-white,
.progressbar-infinite.bg-white {
  background-color: rgba(255, 255, 255, 0.5);
}
.progressbar-infinite.color-white:after,
.progressbar-infinite.bg-white:after,
.progressbar-infinite.color-white:before,
.progressbar-infinite.bg-white:before {
  background-color: #ffffff;
}
.color-white i.icon,
i.icon.color-white {
  color: #ffffff;
}
.preloader.color-white svg circle,
.preloader.preloader-white svg circle {
  stroke: #ffffff;
}
.preloader.color-white .preloader-inner-gap,
.preloader.preloader-white .preloader-inner-gap,
.preloader.color-white .preloader-inner-half-circle,
.preloader.preloader-white .preloader-inner-half-circle {
  border-color: #ffffff;
}
.bg-white,
a.bg-white,
.list-block .swipeout-actions-left a.bg-white,
.list-block .swipeout-actions-right a.bg-white {
  background-color: #ffffff;
}
html:not(.watch-active-state) .list-block .bg-white .item-link:active,
html:not(.watch-active-state) .list-block a.bg-white .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-white .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-white .item-link:active,
html:not(.watch-active-state) .list-block.bg-white .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-white .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-white .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-white .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-white:active,
html:not(.watch-active-state) .list-block .item-linka.bg-white:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-white:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-white:active,
.list-block .bg-white .item-link.active-state,
.list-block a.bg-white .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-white .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-white .item-link.active-state,
.list-block.bg-white .item-link.active-state,
.list-blocka.bg-white .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-white .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-white .item-link.active-state,
.list-block .item-link.bg-white.active-state,
.list-block .item-linka.bg-white.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-white.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-white.active-state {
  background: rgba(0, 0, 0, 0.1);
}
.border-white {
  border-color: #ffffff;
}
.list-block .border-white.item-inner:after,
.list-block ul.border-white:after,
.border-white:after,
.list-block .border-white.item-inner:before,
.list-block ul.border-white:before,
.border-white:before {
  background-color: #ffffff;
}
.floating-button.color-white,
.speed-dial-buttons a.color-white,
.floating-button.bg-white,
.speed-dial-buttons a.bg-white {
  color: #fff;
  background: #ffffff;
}
html:not(.watch-active-state) .floating-button.color-white:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-white:active,
html:not(.watch-active-state) .floating-button.bg-white:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-white:active,
.floating-button.color-white.active-state,
.speed-dial-buttons a.color-white.active-state,
.floating-button.bg-white.active-state,
.speed-dial-buttons a.bg-white.active-state {
  background: rgba(0, 0, 0, 0.1);
}
.floating-button.color-white i,
.speed-dial-buttons a.color-white i,
.floating-button.bg-white i,
.speed-dial-buttons a.bg-white i {
  color: inherit;
}
.ripple-white .ripple-wave {
  background-color: rgba(255, 255, 255, 0.3);
}
.range-slider.color-white input[type="range"]::-webkit-slider-thumb:before {
  background-color: #ffffff;
}
.range-slider.color-white input[type="range"]::-webkit-slider-thumb {
  background-color: #ffffff;
}
.range-slider.color-white input[type="range"]::-ms-thumb {
  background-color: #ffffff;
}
.range-slider.color-white input[type="range"]::-ms-fill-lower {
  background-color: #ffffff;
}
.range-slider.color-white input[type="range"]::-moz-range-thumb {
  background-color: #ffffff;
}
.badge.color-white {
  background-color: #ffffff;
  color: #fff;
}
.color-black {
  color: #000000;
}
.list-block .item-link.list-button.color-black,
.list-button.color-black,
.list-block .item-link.color-black,
.tabbar a.active.color-black,
a.color-black {
  color: #000000;
}
.label-switch input[type="checkbox"]:checked + .checkbox.color-black,
.label-switch.color-black input[type="checkbox"]:checked + .checkbox {
  background-color: #000000;
}
.button.color-black:not(.button-fill),
.color-black.buttons-row .button:not(.button-fill) {
  color: #000000 !important;
}
.button.button-fill.bg-black,
.button.button-fill.color-black {
  background: #000000 !important;
  color: #fff;
}
html:not(.watch-active-state) .button.button-fill.bg-black:active,
html:not(.watch-active-state) .button.button-fill.color-black:active,
.button.button-fill.bg-black.active-state,
.button.button-fill.color-black.active-state {
  background: #333 !important;
}
.progressbar.color-black,
.progressbar.bg-black {
  background-color: rgba(0, 0, 0, 0.5);
}
.progressbar.color-black span,
.progressbar.bg-black span {
  background-color: #000000;
}
.progressbar-infinite.color-black,
.progressbar-infinite.bg-black {
  background-color: rgba(0, 0, 0, 0.5);
}
.progressbar-infinite.color-black:after,
.progressbar-infinite.bg-black:after,
.progressbar-infinite.color-black:before,
.progressbar-infinite.bg-black:before {
  background-color: #000000;
}
.color-black i.icon,
i.icon.color-black {
  color: #000000;
}
.preloader.color-black svg circle,
.preloader.preloader-black svg circle {
  stroke: #000000;
}
.preloader.color-black .preloader-inner-gap,
.preloader.preloader-black .preloader-inner-gap,
.preloader.color-black .preloader-inner-half-circle,
.preloader.preloader-black .preloader-inner-half-circle {
  border-color: #000000;
}
.bg-black,
a.bg-black,
.list-block .swipeout-actions-left a.bg-black,
.list-block .swipeout-actions-right a.bg-black {
  background-color: #000000;
  background: #000000 !important;
}
html:not(.watch-active-state) .list-block .bg-black .item-link:active,
html:not(.watch-active-state) .list-block a.bg-black .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-left a.bg-black .item-link:active,
html:not(.watch-active-state) .list-block .list-block .swipeout-actions-right a.bg-black .item-link:active,
html:not(.watch-active-state) .list-block.bg-black .item-link:active,
html:not(.watch-active-state) .list-blocka.bg-black .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-left a.bg-black .item-link:active,
html:not(.watch-active-state) .list-block.list-block .swipeout-actions-right a.bg-black .item-link:active,
html:not(.watch-active-state) .list-block .item-link.bg-black:active,
html:not(.watch-active-state) .list-block .item-linka.bg-black:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-left a.bg-black:active,
html:not(.watch-active-state) .list-block .item-link.list-block .swipeout-actions-right a.bg-black:active,
.list-block .bg-black .item-link.active-state,
.list-block a.bg-black .item-link.active-state,
.list-block .list-block .swipeout-actions-left a.bg-black .item-link.active-state,
.list-block .list-block .swipeout-actions-right a.bg-black .item-link.active-state,
.list-block.bg-black .item-link.active-state,
.list-blocka.bg-black .item-link.active-state,
.list-block.list-block .swipeout-actions-left a.bg-black .item-link.active-state,
.list-block.list-block .swipeout-actions-right a.bg-black .item-link.active-state,
.list-block .item-link.bg-black.active-state,
.list-block .item-linka.bg-black.active-state,
.list-block .item-link.list-block .swipeout-actions-left a.bg-black.active-state,
.list-block .item-link.list-block .swipeout-actions-right a.bg-black.active-state {
  background: #333;
}
.border-black {
  border-color: #000000;
}
.list-block .border-black.item-inner:after,
.list-block ul.border-black:after,
.border-black:after,
.list-block .border-black.item-inner:before,
.list-block ul.border-black:before,
.border-black:before {
  background-color: #000000;
}
.floating-button.color-black,
.speed-dial-buttons a.color-black,
.floating-button.bg-black,
.speed-dial-buttons a.bg-black {
  color: #fff;
  background: #000000;
}
html:not(.watch-active-state) .floating-button.color-black:active,
html:not(.watch-active-state) .speed-dial-buttons a.color-black:active,
html:not(.watch-active-state) .floating-button.bg-black:active,
html:not(.watch-active-state) .speed-dial-buttons a.bg-black:active,
.floating-button.color-black.active-state,
.speed-dial-buttons a.color-black.active-state,
.floating-button.bg-black.active-state,
.speed-dial-buttons a.bg-black.active-state {
  background: #333;
}
.floating-button.color-black i,
.speed-dial-buttons a.color-black i,
.floating-button.bg-black i,
.speed-dial-buttons a.bg-black i {
  color: inherit;
}
.ripple-black .ripple-wave {
  background-color: rgba(0, 0, 0, 0.3);
}
.range-slider.color-black input[type="range"]::-webkit-slider-thumb:before {
  background-color: #000000;
}
.range-slider.color-black input[type="range"]::-webkit-slider-thumb {
  background-color: #000000;
}
.range-slider.color-black input[type="range"]::-ms-thumb {
  background-color: #000000;
}
.range-slider.color-black input[type="range"]::-ms-fill-lower {
  background-color: #000000;
}
.range-slider.color-black input[type="range"]::-moz-range-thumb {
  background-color: #000000;
}
.badge.color-black {
  background-color: #000000;
  color: #fff;
}

.theme-color{
  color: var(--theme-color) !important;
}
.theme-background-color{
  background-color: var(--theme-color) !important;
  background: var(--theme-color) !important;
}
.theme-border-color{
  color: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}

.stm-trending-up {
  font-size: 22px;
  line-height: 1.3;
  color: #f44336;
}

.stm-trending-down {
  font-size: 22px;
  line-height: 1.3;
  color: #3c763d;
}

.DaluluzituHeader {
  position:relative;
  padding-right:20px;
}

.DaluluzituBody {
  position:relative;
  padding-right:20px;
  padding-top: 21px;
}

.DaYanluzituHeader {
  position:relative;
  padding-right:20px;
}

.DaYanluluzituBody {
  position:relative;
  padding-right:20px;
  padding-top: 21px;
}
.LuZhuluzituHeader {
  position:relative;
  padding-right:16px;
}

.LuZhuluzituBody {
  position:relative;
  padding-right:16px;
  padding-top: 22px;
}

.WuluzituHeader {
  position:relative;
  padding-right:16px;
  width :100%;
  height:0;
}
.WuluzituBody {
  position:relative;
  padding-right:16px;
  padding-top: 21px;
  width :100%;
  height:0;
}

.bg-new-green{
  background: #74BF44!important;
}