@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?13ky47');
  src:  url('../../assets/fonts/icomoon.eot?13ky47#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?13ky47') format('truetype'),
    url('../../assets/fonts/icomoon.woff?13ky47') format('woff'),
    url('../../assets/fonts/icomoon.svg?13ky47#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chips:before {
  content: "\e932";
  color: #bfbfbf;
}
.icon-leaderboard:before {
  content: "\e907";
  color: #7f7f7f;
}
.icon-deposithome:before {
  content: "\e92f";
  color: #fff;
}
.icon-withdrawnhome:before {
  content: "\e930";
  color: #fff;
}
.icon-Speaker:before {
  content: "\e931";
  color: #898989;
}
.icon-cp98Speaker:before {
  content: "\e931";
  color: #7b909d;
}
.icon-dynSpeaker:before {
  content: "\e931";
  color: var(--theme-color);
}
.icon-cp-Speaker:before {
  content: "\e931";
  color: #D9383D;
}
.icon-app:before {
  content: "\e928";
  color: #fff;
}
.icon-cancel:before {
  content: "\e929";
  color: #fff;
}
.icon-free:before {
  content: "\e92a";
  color: #fff;
}
.icon-group:before {
  content: "\e92b";
  color: #f44336;
}
.icon-person:before {
  content: "\e92c";
  color: #f44336;
}
.icon-wallet:before {
  content: "\e92d";
  color: #f44336;
}
.icon-withdraw:before {
  content: "\e92e";
  color: #f44336;
}
.icon-password:before {
  content: "\e91b";
  color: #686868;
}
.icon-user:before {
  content: "\e91c";
  color: #686868;
}
.icon-user-white:before {
  content: "\e91c";
  color: #fff;
}
.icon-random:before {
  content: "\e91d";
  color: #bfbfbf;
}
.icon-basket:before {
  content: "\e91e";
  color: #bfbfbf;
}
.icon-delete:before {
  content: "\e91f";
  color: #bfbfbf;
}
.icon-delete1:before {
  content: "\e920";
  color: var(--theme-color);
}
.icon-edit:before {
  content: "\e921";
  color: var(--theme-color);
}
.icon-share:before {
  content: "\e922";
  color: var(--theme-color);
}
.icon-copy:before {
  content: "\e923";
  color: var(--theme-color);
}
.icon-back:before {
  content: "\e924";
  color: #fff;
}
.icon-reload:before {
  content: "\e925";
  color: #fff;
}
.icon-plus:before {
  content: "\e926";
  color: #fff;
}
.icon-freeplay:before {
  content: "\e927";
  color: #fff;
}
.icon-changlong:before {
  content: "\e90a";
  color: #7f7f7f;
}
.icon-gift:before {
  content: "\e90b";
  color: #898989;
}
.icon-giftactive:before {
  content: "\e90c";
  color: var(--theme-color);
}
.cp-promo .icon-gift:before {
  content: "\e90b";
  color: #BBBBBB;
}
.cp-promo .icon-giftactive:before {
  content: "\e90c";
  color: #D9383D;
}
.theme-cp98-white .icon-gift:before {
  content: "\e90b";
  color: #c5bbce;
}
.theme-cp98-white .icon-giftactive:before {
  content: "\e90c";
  color: #739ee1;
}
.blue-promo .icon-giftactive:before {
  content: "\e90c";
  color: #FC7272;
}
.icon-home:before {
  content: "\e90d";
  color: #898989;
}
.icon-homeactive:before {
  content: "\e90e";
  color: var(--theme-color);;
}
.cp-homepage .icon-home:before {
  content: "\e90d";
  color: #BBBBBB;
}
.cp-homepage .icon-homeactive:before {
  content: "\e90e";
  color: #D9383D;
}
.blue-homepage .icon-homeactive:before {
  content: "\e90e";
  color: #e93330;
}
.theme-cp98-white .icon-home:before {
  content: "\e90d";
  color: #c5bbce;
}
.theme-cp98-white .icon-homeactive:before {
  content: "\e90e";
  color: #739ee1;
}
.icon-left:before {
  content: "\e90f";
  color: #898989;
}
.icon-lottery:before {
  content: "\e910";
  color: #7f7f7f;
}
.icon-lottery.active:before {
  content: "\e910";
  color: #fff;
}
.icon-mine:before {
  content: "\e911";
  color: #898989;
}
.icon-mineactive:before {
  content: "\e912";
  color: var(--theme-color);
}
.theme-cp98-white .icon-mine:before {
  content: "\e911";
  color: #97a7b0;
}
.theme-cp98-white .icon-mineactive:before {
  content: "\e912";
  color: #739ee1;
}
.blue-user .icon-mineactive:before {
  content: "\e912";
  color: #B959EE;
}
.icon-qipai:before {
  content: "\e913";
  color: #7f7f7f;
}
.icon-right:before {
  content: "\e914";
  color: #898989;
}
.icon-shixun:before {
  content: "\e915";
  color: #7f7f7f;
}
.icon-sports:before {
  content: "\e916";
  color: #7f7f7f;
}
.icon-support:before {
  content: "\e917";
  color: #BBBBBB;
}
.cp-customer .icon-supportactive:before {
  content: "\e918";
  color: #D9383D;
}
.cp-customer .icon-support:before {
  content: "\e917";
  color: #898989;
}
.theme-cp98-white .icon-support:before {
  content: "\e917";
  color: #c5bbce;
}
.theme-cp98-white .icon-supportactive:before {
  content: "\e918";
  color: #739ee1;
}
.icon-supportactive:before {
  content: "\e918";
  color: var(--theme-color);
}
.blue-customer .icon-supportactive:before {
  content: "\e918";
  color: #7994FD;
}
.icon-supportactive-header:before {
  content: "\e918";
  color: white;
}
.icon-zoushi:before {
  content: "\e919";
  color: #898989;
}
.icon-zoushiactive:before {
  content: "\e91a";
  color: var(--theme-color);
}
.cp-award .icon-zoushi:before {
  content: "\e919";
  color: #BBBBBB;
}
.cp-award .icon-zoushiactive:before {
  content: "\e91a";
  color: #D9383D;
}
.theme-cp98-white .icon-zoushi:before {
  content: "\e919";
  color: #c5bbce;
}
.theme-cp98-white .icon-zoushiactive:before {
  content: "\e91a";
  color: #739ee1;
}
.blue-award .icon-zoushiactive:before {
  content: "\e91a";
  color: #FFD406;
}
.icon-transfer:before {
  content: "\e933";
  color: #fff;
}
.icon-refresh:before {
  content: "\e934";
  color: #fff;
}

.icon-beadchart:before{
  content: "\e935";
  color: var(--theme-color);
}

.icon-betrecord:before{
  content: "\e936";
  color: var(--theme-color);
}

.icon-bet:before{
  content: "\e937";
  color: var(--theme-color);
}

.icon-chatroom:before{
  content: "\e938";
  color: var(--theme-color);
}

.icon-purserecords:before{
  content: "\e93a";
  color: var(--theme-color);
}

.icon-autobets:before{
  content: "\e939";
  color: #7f7f7f;
}

.icon-AG:before {
  content: "\e900";
  color: #7f7f7f;
}
.icon-COOP:before {
  content: "\e901";
  color: #7f7f7f;
}
.icon-GR2:before {
  content: "\e902";
  color: #7f7f7f;
}
.icon-GRl:before {
  content: "\e903";
  color: #7f7f7f;
}
.icon-HL:before {
  content: "\e904";
  color: #7f7f7f;
}
.icon-HL2:before {
  content: "\e905";
  color: #7f7f7f;
}
.icon-KY:before {
  content: "\e906";
  color: #7f7f7f;
}
.icon-PHB:before {
  content: "\e907";
  color: #7f7f7f;
}
.icon-SB:before {
  content: "\e908";
  color: #7f7f7f;
}
.icon-SD:before {
  content: "\e909";
  color: #7f7f7f;
}

.icon-signin:before{
  content: "\e93b";
  color: #7f7f7f;
}