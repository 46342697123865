@font-face {
  font-family: 'newicomoon';
  src:  url('../../assets/fonts/newicomoon.eot?rbfef8');
  src:  url('../../assets/fonts/newicomoon.eot?rbfef8#iefix') format('embedded-opentype'),
    url('../../assets/fonts/newicomoon.ttf?rbfef8') format('truetype'),
    url('../../assets/fonts/newicomoon.woff?rbfef8') format('woff'),
    url('../../assets/fonts/newicomoon.svg?rbfef8#newicomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="newicon-"], [class*=" newicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'newicomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.newicon-QQC:before {
  content: "\e900"; color: #DB407F;
}
.newicon-QQC-EN:before {
  content: "\e92d"; color: #DB407F;
}
.newicon-Asset-72:before {
  content: "\e902"; color: #D9383D;
}
.newicon-KLS-EN:before {
  content: "\e903"; color: #FF9D00;
}
.newicon-TDB-EN:before {
  content: "\e904"; color: #FBCA35;
}
.newicon-VKL-EN:before {
  content: "\e905"; color: #FE5D5C;
}
.newicon-SFL-EN:before {
  content: "\e906"; color: #2F97AE;
}
.newicon-PWB-EN:before {
  content: "\e907"; color: #9E78FF;
}
.newicon-UKL-EN:before {
  content: "\e908"; color: #4E84F9;
}
.newicon-MGM-EN:before {
  content: "\e909"; color: #0DD15D;
}
.newicon-BSC-EN:before {
  content: "\e90a"; color: #FF4343;
}
.newicon-PKS-EN:before {
  content: "\e90a"; color: #FF4343;
}
.newicon-KL8-EN:before {
  content: "\e913"; color: #FFBA55;
}
.newicon-SSL-EN:before {
  content: "\e914"; color: #6966CD;
}
.newicon-PL3-EN:before {
  content: "\e915"; color: #FFC926;
}
.newicon-LOT-EN:before {
  content: "\e91d"; color: #6F35BB;
}
.newicon-JHC-EN:before {
  content: "\e91e"; color: #F00000;
}
.newicon-LHC-EN:before {
  content: "\e91e"; color: #F00000;
}
.newicon-DDC-EN:before {
  content: "\e91f"; color: #63CCF2;
}
.newicon-LCT-EN:before {
  content: "\e920"; color: #FA4F8E;
}
.newicon-STM-EN:before {
  content: "\e921"; color: #4C2175;
}
.newicon-YSC-EN:before {
  content: "\e922"; color: #E326ED;
}
.newicon-OSD-EN:before {
  content: "\e923"; color: #9A05BF;
}
.newicon-YXX-EN:before {
  content: "\e924"; color: #46B950;
}
.newicon-Asset-92:before {
  content: "\e925"; color: #D9383D;
}
.newicon-KW3-EN:before {
  content: "\e926"; color: #6966CD;
}
.newicon-XX5-EN:before {
  content: "\e927"; color: #19AFD0;
}
.newicon-V6Z-EN:before {
  content: "\e928"; color: #283FFF;
}
.newicon-V6D-EN:before {
  content: "\e928"; color: #283FFF;
}
.newicon-V5Z-EN:before {
  content: "\e929"; color: #283FFF;
}
.newicon-SSC-EN:before {
  content: "\e929"; color: #3BE8B0;
}
.newicon-M4D-EN:before {
  content: "\e92a"; color: #283FFF;
}
.newicon-F3D-EN:before {
  content: "\e92b"; color: #283FFF;
}
.newicon-BSC-EN:before {
  content: "\e92c"; color: #6176F7;
}
.newicon-Asset-4:before {
  content: "\e92e"; color: #30E57B;
}
.newicon-KLS:before {
  content: "\e92f"; color: #FF9D00;
}
.newicon-YSC:before {
  content: "\e930"; color: #E326ED;
}
.newicon-V6Z:before {
  content: "\e931"; color: #283FFF;
}
.newicon-V6D:before {
  content: "\e931"; color: #283FFF;
}
.newicon-V5Z:before {
  content: "\e932"; color: #283FFF;
}
.newicon-M4D:before {
  content: "\e933"; color: #283FFF;
}
.newicon-F3D:before {
  content: "\e934"; color: #283FFF;
}
.newicon-OSD:before {
  content: "\e935"; color: #9A05BF;
}
.newicon-YXX:before {
  content: "\e936"; color: #46B950;
}
.newicon-KL8:before {
  content: "\e937"; color: #FFBA55;
}
.newicon-STM:before {
  content: "\e938"; color: #4C2175;
}
.newicon-SSL:before {
  content: "\e939"; color: #6966CD;
}
.newicon-DDC:before {
  content: "\e93b"; color: #63CCF2;
}
.newicon-Asset-18:before {
  content: "\e93c"; color: #FFC200;
}
.newicon-PL3:before {
  content: "\e93d"; color: #FFC926;
}
.newicon-BSC:before {
  content: "\e93e"; color: #FF4343;
}
.newicon-PKS:before {
  content: "\e93e"; color: #FF4343;
}
.newicon-KW3:before {
  content: "\e93f"; color: #6966CD;
}
.newicon-XX5:before {
  content: "\e940"; color: #19AFD0;
}
.newicon-BSC:before {
  content: "\e941"; color: #6176F7;
}
.newicon-UKL:before {
  content: "\e942"; color: #4E84F9;
}
.newicon-VKL:before {
  content: "\e943"; color: #FE5D5C;
}
.newicon-TDB:before {
  content: "\e944"; color: #FBCA35;
}
.newicon-PWB:before {
  content: "\e945"; color: #9E78FF;
}
.newicon-SFL:before {
  content: "\e946"; color: #2F97AE;
}
.newicon-MGM:before {
  content: "\e947"; color: #0DD15D;
}
.newicon-LCT:before {
  content: "\e948"; color: #FA4F8E;
}
.newicon-SSC:before {
  content: "\e949"; color: #3BE8B0;
}
.newicon-JHC:before {
  content: "\e94a"; color: #F00000;
}
.newicon-LHC:before {
  content: "\e94a"; color: #F00000;
}
.newicon-LOT:before {
  content: "\e94b"; color: #6F35BB;
}
.newicon-uniE661:before {
  content: "\e901"; color: #D9383D;
}
.newicon-x:before {
  content: "\78"; color: #D9383D;
}
.newicon-uniE601:before {
  content: "\e601"; color: #D9383D;
}
.newicon-uniE602:before {
  content: "\e602"; color: #D9383D;
}
.newicon-uniE603:before {
  content: "\e603"; color: #D9383D;
}
.newicon-uniE604:before {
  content: "\e604"; color: #D9383D;
}
.newicon-uniE605:before {
  content: "\e605"; color: #D9383D;
}
.newicon-uniE606:before {
  content: "\e606"; color: #D9383D;
}
.newicon-uniE607:before {
  content: "\e607"; color: #D9383D;
}
.newicon-uniE608:before {
  content: "\e608"; color: #D9383D;
}
.newicon-uniE609:before {
  content: "\e609"; color: #D9383D;
}
.newicon-uniE60A:before {
  content: "\e60a"; color: #D9383D;
}
.newicon-uniE60B:before {
  content: "\e60b"; color: #D9383D;
}
.newicon-uniE60C:before {
  content: "\e60c"; color: #D9383D;
}
.newicon-uniE60D:before {
  content: "\e60d"; color: #D9383D;
}
.newicon-uniE60E:before {
  content: "\e60e"; color: #D9383D;
}
.newicon-uniE60F:before {
  content: "\e60f"; color: #D9383D;
}
.newicon-uniE610:before {
  content: "\e610"; color: #D9383D;
}
.newicon-uniE611:before {
  content: "\e611"; color: #D9383D;
}
.newicon-uniE612:before {
  content: "\e612"; color: #D9383D;
}
.newicon-uniE613:before {
  content: "\e613"; color: #D9383D;
}
.newicon-uniE614:before {
  content: "\e614"; color: #D9383D;
}
.newicon-uniE615:before {
  content: "\e615"; color: #D9383D;
}
.newicon-uniE616:before {
  content: "\e616"; color: #D9383D;
}
.newicon-uniE617:before {
  content: "\e617"; color: #D9383D;
}
.newicon-uniE618:before {
  content: "\e618"; color: #D9383D;
}
.newicon-uniE619:before {
  content: "\e619"; color: #D9383D;
}
.newicon-uniE61A:before {
  content: "\e61a"; color: #D9383D;
}
.newicon-uniE61B:before {
  content: "\e61b"; color: #D9383D;
}
.newicon-uniE61C:before {
  content: "\e61c"; color: #D9383D;
}
.newicon-uniE61D:before {
  content: "\e61d"; color: #D9383D;
}
.newicon-uniE61E:before {
  content: "\e61e"; color: #D9383D;
}
.newicon-uniE61F:before {
  content: "\e61f"; color: #D9383D;
}
.newicon-uniE620:before {
  content: "\e620"; color: #D9383D;
}
.newicon-uniE621:before {
  content: "\e621"; color: #D9383D;
}
.newicon-uniE624:before {
  content: "\e624"; color: #D9383D;
}
.newicon-uniE625:before {
  content: "\e625"; color: #D9383D;
}
.newicon-uniE626:before {
  content: "\e626"; color: #D9383D;
}
.newicon-uniE627:before {
  content: "\e627"; color: #D9383D;
}
.newicon-uniE628:before {
  content: "\e628"; color: #D9383D;
}
.newicon-uniE629:before {
  content: "\e629"; color: #D9383D;
}
.newicon-uniE62A:before {
  content: "\e62a"; color: #D9383D;
}
.newicon-uniE62B:before {
  content: "\e62b"; color: #D9383D;
}
.newicon-uniE62C:before {
  content: "\e62c"; color: #D9383D;
}
.newicon-uniE62D:before {
  content: "\e62d"; color: #D9383D;
}
.newicon-uniE62E:before {
  content: "\e62e"; color: #D9383D;
}
.newicon-uniE62F:before {
  content: "\e62f"; color: #D9383D;
}
.newicon-uniE630:before {
  content: "\e630"; color: #D9383D;
}
.newicon-uniE631:before {
  content: "\e631"; color: #D9383D;
}
.newicon-uniE632:before {
  content: "\e632"; color: #D9383D;
}
.newicon-uniE633:before {
  content: "\e633"; color: #D9383D;
}
.newicon-uniE634:before {
  content: "\e634"; color: #D9383D;
}
.newicon-uniE635:before {
  content: "\e635"; color: #D9383D;
}
.newicon-uniE636:before {
  content: "\e636"; color: #D9383D;
}
.newicon-uniE637:before {
  content: "\e637"; color: #D9383D;
}
.newicon-uniE638:before {
  content: "\e638"; color: #D9383D;
}
.newicon-uniE639:before {
  content: "\e639"; color: #D9383D;
}
.newicon-uniE63A:before {
  content: "\e63a"; color: #D9383D;
}
.newicon-uniE63B:before {
  content: "\e63b"; color: #D9383D;
}
.newicon-uniE63C:before {
  content: "\e63c"; color: #D9383D;
}
.newicon-uniE63D:before {
  content: "\e63d"; color: #D9383D;
}
.newicon-uniE63E:before {
  content: "\e63e"; color: #D9383D;
}
.newicon-uniE63F:before {
  content: "\e63f"; color: #D9383D;
}
.newicon-uniE640:before {
  content: "\e640"; color: #D9383D;
}
.newicon-uniE641:before {
  content: "\e641"; color: #D9383D;
}
.newicon-uniE642:before {
  content: "\e642"; color: #D9383D;
}
.newicon-uniE643:before {
  content: "\e643"; color: #D9383D;
}
.newicon-uniE644:before {
  content: "\e644"; color: #D9383D;
}
.newicon-uniE645:before {
  content: "\e645"; color: #D9383D;
}
.newicon-uniE646:before {
  content: "\e646"; color: #D9383D;
}
.newicon-uniE647:before {
  content: "\e647"; color: #D9383D;
}
.newicon-uniE648:before {
  content: "\e648"; color: #D9383D;
}
.newicon-uniE649:before {
  content: "\e649"; color: #D9383D;
}
.newicon-uniE650:before {
  content: "\e650"; color: #D9383D;
}
.newicon-uniE651:before {
  content: "\e651"; color: #D9383D;
}
.newicon-uniE652:before {
  content: "\e652"; color: #D9383D;
}
.newicon-uniE653:before {
  content: "\e653"; color: #D9383D;
}
.newicon-uniE654:before {
  content: "\e654"; color: #D9383D;
}
.newicon-uniE655:before {
  content: "\e655"; color: #D9383D;
}
.newicon-uniE656:before {
  content: "\e656"; color: #D9383D;
}
.newicon-uniE657:before {
  content: "\e657"; color: #D9383D;
}
.newicon-uniE658:before {
  content: "\e658"; color: #D9383D;
}
.newicon-uniE659:before {
  content: "\e659"; color: #D9383D;
}
.newicon-uniE660:before {
  content: "\e660"; color: #D9383D;
}
.newicon-card-01:before {
  content: "\e90b"; color: #D9383D;
}
.newicon-QQQQ:before {
  content: "\e90c"; color: #D9383D;
}
.newicon-card-01-01:before {
  content: "\e90d"; color: #D9383D;
}
.newicon-benz-01:before {
  content: "\e90e"; color: #D9383D;
}
.newicon-Shark-01-01:before {
  content: "\e90f"; color: #D9383D;
}
.newicon-wangrenBaijiale-01:before {
  content: "\e910"; color: #D9383D;
}
.newicon-WangrenPokeer-01:before {
  content: "\e911"; color: #D9383D;
}
.newicon-benz-01-01:before {
  content: "\e912"; color: #D9383D;
}
.newicon-dancer-01:before {
  content: "\e916"; color: #D9383D;
}
.newicon-kkkaa-01:before {
  content: "\e917"; color: #D9383D;
}
.newicon-mahjong-01:before {
  content: "\e918"; color: #D9383D;
}
.newicon-Pai9-01:before {
  content: "\e919"; color: #D9383D;
}
.newicon-Poker-21-01:before {
  content: "\e91a"; color: #D9383D;
}
.newicon-Poker-THS-01:before {
  content: "\e91b"; color: #D9383D;
}
.newicon-shaizi-01:before {
  content: "\e91c"; color: #D9383D;
}
